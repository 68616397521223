import React from "react";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import BtcChart from "../../charts/btc";
import LtcChart from "../../charts/ltc";
import XrpChart from "../../charts/xrp";
import { connect } from "react-redux";
import { listChartSchemes } from "../../actions/charts";
import AreaChart from "../../charts/area";
import RadialChart from "../../charts/radial";
import Box from "@mui/material/Box";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
// import ReactApexChart from "react-apexcharts";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
//import Spinner from "../../layout/spinner";
import "react-datepicker/dist/react-datepicker.css";
//import DatePicker from 'react-date-picker';
import { Table, Modal, Form, Row, Col, Button, Label, Spinner } from "react-bootstrap";
import { Card, CardContent, Stack, Typography } from "@mui/material";

class Charts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      tab_investment_category: 0,
      tab_portfolio: 0,
      sip_order_frequency_list: [],
      frequency_option_list: [],
      sip_order_frequency_option_list: [],
      sip_installment_numbers_list: [],
      is_sip_calculator: true,

      swp_order_frequency_list: [],

      setPrincipal: '',
      sipPrincipal: '',
      onetimePrincipal: '',
      setYears: 5,
      setInterest: 10,
      setResult: '',
      sip_order: {
        display_text: '',
        scheme_code: '',
        sip_installment_numbers: '',
        sip_transaction_mode: '',
        sip_amount: '',
        sip_date: new Date(),
        is_nach_mandate: false,
        sip_frequency: '',
        lockin_period_flag: '',
        lockin_period: '',
        purchase_cutoff_time: ''
      },
      sip_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      sip_maximum_date: new Date().setDate(new Date().getDate() + 40),
      sip_exclude_date_list: [],
      general_order_frequency_list: [],
      general_order: {
        display_text: '',
        scheme_code: '',
        order_amount: '',
        order_date: '',
        lockin_period_flag: '',
        lockin_period: '',
        purchase_cutoff_time: ''
      },

      selected_order: {},
      selected_sip_order: {},
      is_order_checkout_allowed: false,
      order_checkout_validation_message: '',
      order_checkout_redirect_url: '',
      bank_account_list: [],

      is_kyc_esign_verified: false,
      login_stage: '',
      is_pending_order: false,

      mfapi_scheme_code: '',

      holdings_list: [],
      fund_manager_list: [],

      returns_result: {
        sip: {},
        onetime: {}
      },

      sip_returns_calculation: {},
      lumpsum_returns_calculation: {},

      last_nav_display_text: 0.00,
      last_nav_difference_display_text: 0.00,

      scheme_name: "",
      showmodal: "false",
      _date: new Date(),

      series: [],

      series_bar_height: 80,
      seriespie_sector_length: 0,
      seriespie_sector: [],
      optionspie_sector: {
        chart: {
          id: "basic-bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        },
      },

      options: {
        chart: {
          type: "area",
          height: 300,
          foreColor: "#8C87C2",
          fontFamily: "Rubik, sans-serif",
          stacked: true,
          dropShadow: {
            enabled: true,
            // enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06,
          },
          toolbar: {
            show: true,
          },
        },
        colors: ["#7B6FFF", "#1652F0"],
        stroke: {
          curve: "smooth",
          width: 3,
        },
        dataLabels: {
          enabled: false,
        },

        markers: {
          size: 0,
          strokeColor: "#fff",
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: {
            size: 6,
          },
        },
        xaxis: {
          axisBorder: {
            show: true,
          },
          // axisTicks: {
          //   show: true,
          // },
          type: "datetime",
          tickPlacement: 'between'
        },
        yaxis: {
          labels: {
            offsetX: 10,
            offsetY: 0,
          },
          // tooltip: {
          //   enabled: true,
          // },
          tickPlacement: 'between'
        },
        grid: {
          show: false,
          padding: {
            left: -5,
            right: 5,
          },
        },
        tooltip: {
          x: {
            format: "dd-MMM-yyyy",
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 100, 100],
          },
        },
      },

      selection: "1m",

      /************* */
      seriespie_sector_bar: [],
      options_bar: {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: ['#33b2df', '#8a8888', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
          '#f48024', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7',
          '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7', '#69d2e7'
        ],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#575555']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + '%'
          },
          offsetX: 0,
          // dropShadow: {
          //   enabled: true
          // }
        },
        stroke: {
          // width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        // title: {
        //     text: 'Custom DataLabels',
        //     align: 'center',
        //     floating: true
        // },
        // subtitle: {
        //     text: 'Category Names as DataLabels inside bars',
        //     align: 'center',
        // },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      }
      /************* */
    };
  }

  componentDidMount() {

    window.scrollTo(0, 0)

    let isMobile = (window.matchMedia && window.matchMedia("(max-width: 480px)").matches);

    let received_data = (isMobile && this.props.location.state && this.props.location.state['param_data']) ?
      this.props.location.state['param_data'] : this.props.match.params;

    if (received_data) {
      // received_data = received_data.param_data;
      sessionStorage.setItem('received_data', JSON.stringify(received_data));
      this.setState({ scheme_name: received_data["scheme_name"] });
    }
    else
      received_data = JSON.parse(sessionStorage.getItem('received_data') || JSON.stringify({}));

    this.loadChartList(received_data);

  }

  handleClose = () => this.setState({ showmodal: false });
  handleShow = () => this.setState({ showmodal: true });

  calculate = (e, is_sip) => {

    if (is_sip)
      this.setState({ sipPrincipal: e.target.value });
    else
      this.setState({ onetimePrincipal: e.target.value });

    // this.setState({ setPrincipal: e.target.value });
    this.calculateReturns(e.target.value, is_sip)
  }

  calculateReturns(value, is_sip_calculator) {

    let _investment = parseInt(isNaN(value) ? 0.00 : value);
    let _total_investment = 0.00;
    let _percent = 0.00;
    let _returns = 0.00;
    let _array = [];
    let _sip_returns = {};
    let _onetime_returns = {};

    if (is_sip_calculator) //sip
    {

      Object.keys(this.state.sip_returns_calculation)
        .forEach(key => {

          _sip_returns[key] = this.state.sip_returns_calculation[key];
          
          switch (key) {

            case '1y_percent':
              _total_investment = parseFloat(12 * _investment);
              _sip_returns['1y'] = (_total_investment +
                parseFloat((_sip_returns['1y_percent'] * _total_investment) / 100)).toFixed(2);
              break;

            case '3y_percent':
              _total_investment = parseFloat(36 * _investment);
              _sip_returns['3y'] = (_total_investment +
                parseFloat((_sip_returns['3y_percent'] * _total_investment) / 100)).toFixed(2);
              break;

            case '5y_percent':
              _total_investment = parseFloat(60 * _investment);
              _sip_returns['5y'] = (_total_investment +
                parseFloat((_sip_returns['5y_percent'] * _total_investment) / 100)).toFixed(2);
              break;

            default:
              break;

          }

        });
        
        this.setState({ returns_result: { ...this.state.returns_result, sip: _sip_returns } });

    }
    else //one time
    {

      let _lumpsum_returns = this.state.lumpsum_returns_calculation;

      Object.keys(_lumpsum_returns['absolute'])
        .forEach(key => {

          _percent = _lumpsum_returns['absolute'][key];
          
          _returns = ((parseFloat(_percent) * parseFloat(_investment)) / 100) + parseFloat(_investment);
          
          _onetime_returns[key.split('_')[0]] = _returns;
          _onetime_returns[key] = parseFloat(_percent).toFixed(2);
          _onetime_returns[key + '_annualized'] = _lumpsum_returns['annualized'][key];

        });

      this.setState({ returns_result: { ...this.state.returns_result, onetime: _onetime_returns } });

    }

  };

  handleRadioChange = (value, attribute) => {

    this.setState({ [attribute]: value });
    // this.calculateReturns(this.state.setPrincipal, value);
    this.calculateReturns(value ? this.state.sipPrincipal : this.state.onetimePrincipal, value);

  };

  filterHistoricalData(timeline, difference) {

    this.setState({
      selection: timeline,
    });

    var today = new Date();
    var monthdate = new Date(new Date().setDate(today.getDate() - 90));
    var sixmonthdate = new Date(new Date().setDate(today.getDate() - 180));
    var Yeardate = new Date(new Date().setDate(today.getDate() - 365));


    var filter_date = new Date(
      new Date().setDate(
        today.getDate() - difference
      ));

    let _nav_data_list = this.props.chart_result["nav_data_list"] || [];
    if (_nav_data_list.length > 0) {

      _nav_data_list = _nav_data_list.filter(el => {
        return el[0] >= filter_date.getTime();
      });

      this.setState({
        series: [
          {
            name: "Buy",
            data: _nav_data_list,
          },
        ]
        // options: {
        //   xaxis: {
        //     min: today,
        //     max: filter_date,
        //   },
        // },
      });


    }
    // switch (timeline) {
    //   case "one_month":
    //     this.setState({
    //       options: {
    //         xaxis: {
    //           min: today,
    //           max: monthdate,
    //         },
    //       },
    //     });
    //     break;
    //   case "six_months":
    //     this.setState({
    //       options: {
    //         xaxis: {
    //           min: today,
    //           max: sixmonthdate,
    //         },
    //       },
    //     });
    //     break;
    //   case "one_year":
    //     this.setState({
    //       options: {
    //         xaxis: {
    //           min: today,
    //           max: Yeardate,
    //         },
    //       },
    //     });
    //     break;
    //   case "all":
    //     this.setState({
    //       options: {
    //         xaxis: {
    //           min: today,
    //           max: undefined,
    //         },
    //       },
    //     });
    //     break;
    //   default:
    // }

  }

  loadChartList = (received_data) => {

    if (this.state.sip_minimum_date.getDate() === 29) {
      this.setState({
        isloading: true,
        sip_minimum_date: new Date(new Date(this.state.sip_minimum_date)
          .setDate(new Date(this.state.sip_minimum_date).getDate() + 3))
      });
    }
    else if (this.state.sip_minimum_date.getDate() === 30) {
      this.setState({
        isloading: true,
        sip_minimum_date: new Date(new Date(this.state.sip_minimum_date)
          .setDate(new Date(this.state.sip_minimum_date).getDate() + 2))
      });
    }
    else if (this.state.sip_minimum_date.getDate() === 31) {
      this.setState({
        isloading: true,
        sip_minimum_date: new Date(new Date(this.state.sip_minimum_date)
          .setDate(new Date(this.state.sip_minimum_date).getDate() + 1))
      });
    }
    else
      this.setState({ isloading: true });

    if (received_data['scheme_isin']) {

      this.props
        .listChartSchemes({
          param_isin: received_data["scheme_isin"],
          param_scheme_code: received_data["scheme_code"],
          param_scheme_name: received_data["scheme_name"],
          // param_data: received_data
        })
        .then(async (response) => {

          this.setState({ isloading: false });

          if (this.props.chart_result["code"] === "000") {
            //comment by harish on date
            // console.log(sip_minimum_date);
            // sip_minimum_date = new Date(sip_minimum_date.setDate(sip_minimum_date.getDate() + 
            //     (this.props.chart_result['sip_mandate_payment_day_plus'] || 0)) );

            let sip_exclude_date_list = [];
            this.props.chart_result['sip_exclude_date_list'].forEach(el => {
              sip_exclude_date_list.push(Date.parse(el));
            });

            this.setState({
              sip_order: { ...this.state.sip_order, sip_date: this.state.sip_minimum_date },
              // sip_minimum_date: sip_minimum_date,
              sip_maximum_date: Date.parse(this.props.chart_result['sip_maximum_date']),
              sip_exclude_date_list: sip_exclude_date_list, //this.props.chart_result['sip_exclude_date_list'],
              is_order_checkout_allowed: this.props.chart_result["is_order_checkout_allowed"],
              order_checkout_validation_message: this.props.chart_result["order_checkout_validation_message"],
              order_checkout_redirect_url: this.props.chart_result["order_checkout_redirect_url"],

              is_kyc_esign_verified: this.props.chart_result["is_kyc_esign_verified"],
              is_pending_order: this.props.chart_result["is_pending_order"],
              login_stage: this.props.chart_result["login_stage"],
              mfapi_scheme_code: this.props.chart_result["scheme_code"]
            });


            let _nav_data_list = this.props.chart_result["nav_data_list"] || [];

            if (this.props.chart_result && _nav_data_list) {
              /*
              let _series = this.state.series;
              _series.forEach((el) => {
                if (el["name"] === "Buy") {
                  el["data"] = _nav_data_list;
                }
              });
              */

              let _last_nav_display_text = parseFloat(_nav_data_list[0][1]);
              let _last_nav_difference_display_text = 0.00;

              if (_nav_data_list.length > 1) {
                _last_nav_difference_display_text = (parseFloat(_last_nav_display_text) - parseFloat(_nav_data_list[1][1]));
              }

              this.setState({

                series: [
                  {
                    name: "Buy",
                    data: _nav_data_list,
                  },
                ],
                last_nav_display_text: _last_nav_display_text,
                last_nav_difference_display_text: _last_nav_difference_display_text,

                sip_order_frequency_list: this.props.chart_result['sip_order_frequency'],
                frequency_option_list: this.props.chart_result['sip_order_terms'],
                general_order_frequency_list: this.props.chart_result['general_order_terms'],

                sip_returns_calculation: this.props.chart_result['sip_returns_calculation'],
                lumpsum_returns_calculation: this.props.chart_result['lumpsum_returns_calculation']

              });
            }

            let _seriespie_sector = this.props.chart_result["seriespie_sector"] || [];

            _seriespie_sector = _seriespie_sector.map(el => {
              return parseFloat(el).toFixed(2)
            });

            let _series_bar_height = this.state.series_bar_height;
            if (_seriespie_sector <= 5)
              _series_bar_height = 120;
            else {
              if (_seriespie_sector > 5 && _seriespie_sector <= 10)
                _series_bar_height = 50;
              else if (_seriespie_sector > 10 && _seriespie_sector <= 15)
                _series_bar_height = 40;
              else if (_seriespie_sector > 15 && _seriespie_sector <= 20)
                _series_bar_height = 30;
              else if (_seriespie_sector > 20)
                _series_bar_height = 20;
            }

            this.setState({

              bank_account_list: this.props.chart_result["bank_account_list"] || [],
              holdings_list: this.props.chart_result["holdings_list"] || [],
              fund_manager_list: this.props.chart_result["fund_manager_list"] || [],
              seriespie_sector: [{
                data: this.props.chart_result["seriespie_sector"]
              }],
              seriespie_sector_bar: [{
                data: _seriespie_sector
              }],
              seriespie_sector_length: _seriespie_sector.length,
              series_bar_height: _series_bar_height,
              tab_investment_category: this.props.chart_result['sip_order_terms'].length > 0 ? 0 : 1

            });


            // let _optionpie = this.state.options;
            // _optionpie["xaxis"].categories = this.props.chart_result["optionspie_sector"];
            let _optionsbar = this.state.optionspie_sector;
            let _optionspie_sector = [];
            let _optionspie_sector_bar = [];

            this.props.chart_result["optionspie_sector"].forEach((el, i) => {
              _optionspie_sector.push(el + '(' +
                parseFloat(this.props.chart_result["seriespie_sector"][i]).toFixed(2) + '%)');
              _optionspie_sector_bar.push(el);
            });

            _optionsbar["xaxis"].categories = _optionspie_sector; //this.props.chart_result["optionspie_sector"];

            let __optionsbar = this.state.options_bar;
            __optionsbar["xaxis"].categories = _optionspie_sector_bar //_optionspie_sector

            this.setState({
              optionspie_sector: _optionsbar,
              options_bar: __optionsbar
            });


            // await this.sleep(2000);

            // this.setState({pageIndexAvailability: this.props.result['list_availability'] })
          } else alert(this.props.chart_result["messageText"]);
        })
        .catch((err) => {
          alert(err);
        });

    }

  };

  handleChangeSelect = (selectedOption, attribute, input, category) => {

    /*
    if (input)
      this.setState({ [attribute]: { ...this.state[attribute], [input]: selectedOption } });
    else
      this.setState({ [input]: selectedOption });
    */

    if (input === 'sip_frequency') {

      let _loc = [];
      let _list = this.state.frequency_option_list.filter(el => {

        _loc = el['value' /*'sip_frequency'*/].split('-');
        return _loc[_loc.length - 1] === selectedOption.value;

      });

      if (category === 'sip') {

        this.setState({
          sip_order: {
            ...this.state.sip_order,
            sip_frequency: selectedOption.value,
            display_text: selectedOption.value,
            scheme_code: '',
            scheme_name: '',
            transaction_mode: '',
            lockin_period_flag: '',
            lockin_period: '',
            purchase_cutoff_time: ''
          },
          sip_installment_numbers_list: [],
          sip_order_frequency_option_list: _list
        });

      }

    }
    else if (input === 'scheme_code') {

      if (category === 'sip') {

        let sip_exclude_date_list = [];
        (selectedOption['sip_exclude_date_list'] || []).forEach(el => {
          sip_exclude_date_list.push(Date.parse(el));
        });

        this.setState({
          selected_sip_order: selectedOption,
          sip_order: {
            ...this.state.sip_order,
            // sip_frequency: selectedOption.label.split('-')[0],
            // display_text: selectedOption.label,
            scheme_code: selectedOption.scheme_code || selectedOption.value.split('-')[0],
            scheme_name: selectedOption.scheme_name || '',
            scheme_isin: selectedOption.scheme_isin || '',
            transaction_mode: selectedOption.transaction_mode || '',
            lockin_period_flag: selectedOption.lockin_period_flag || '',
            lockin_period: selectedOption.lockin_period || '',
            purchase_cutoff_time: selectedOption.purchase_cutoff_time || '',
            is_pause_allowed: selectedOption.is_pause_allowed || false,
            sip_exclude_date_list: sip_exclude_date_list,
            pause_text: selectedOption.pause_text || '',
          },
          sip_installment_numbers_list: selectedOption['installment_number_list'],
          sip_exclude_date_list: sip_exclude_date_list
        });

      }
      else {

        this.setState({
          selected_order: selectedOption,
          general_order: {
            ...this.state.general_order,
            display_text: selectedOption.label,
            scheme_code: selectedOption.param_scheme_code || selectedOption.value.split('-')[0],
            scheme_name: selectedOption.param_scheme_name || '',
            scheme_isin: selectedOption.param_isin || '',
            transaction_mode: '',
            lockin_period_flag: selectedOption.param_lockin_period_flag || '',
            lockin_period: selectedOption.param_lockin_period || '',
            purchase_cutoff_time: selectedOption.param_purchase_cutoff_time || ''
          }
        });

      }

    }
    else if (input === 'sip_installment_numbers') {

      if (category === 'sip')
        this.setState({
          sip_order: { ...this.state.sip_order, sip_installment_numbers: selectedOption.value }
        });

    }

  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: 1 });
  };

  startSIP = () => {

    // const { otp } = this.state;
    if (this.state.is_order_checkout_allowed)
      this.props.history.push({
        pathname: '/sipCheckout/',
        state: {
          selected_order: Object.assign(this.state.selected_sip_order, { 'mfapi_scheme_code': this.state.mfapi_scheme_code }),
          sip_order: this.state.sip_order,
          list_bank_account: this.state.bank_account_list,

        }
      });
    else {

      if (this.state.order_checkout_redirect_url.length > 6)
        this.props.history.push({
          pathname: '/' + this.state.order_checkout_redirect_url + '/',
          state: {}
        });
      // 
    }

  };

  sipParameterValidation() {

    let _sip_order = this.state.sip_order;
    return parseFloat(_sip_order['sip_amount']) >= parseFloat(this.state.selected_sip_order['minimum_installment_amount']) &&
      parseFloat(_sip_order['sip_amount']) <= parseFloat(this.state.selected_sip_order['maximum_installment_amount']) &&
      _sip_order['sip_frequency'].length > 2 && _sip_order['sip_installment_numbers'] > 0;

  }

  handleChangeDate(date) {
    this.setState({
      sip_order: { ...this.state.sip_order, sip_date: date },
      // sip_minimum_date: date  
    })
  }

  startOrder() {

    this.props.history.push({
      pathname: '/orderCheckout/', state: {
        selected_order: Object.assign(this.state.selected_order, { 'mfapi_scheme_code': this.state.mfapi_scheme_code }),
        general_order: this.state.general_order,
        list_bank_account: this.state.bank_account_list
      }
    });
  }

  orderParameterValidation() {

    let _general_order = this.state.general_order;

    return !isNaN(_general_order['order_amount']) && parseFloat(_general_order['order_amount']) >= parseFloat(this.state.selected_order['minimum_purchase_amount']) &&
      parseFloat(_general_order['order_amount']) <= parseFloat(this.state.selected_order['maximum_purchase_amount']);

  }

  fnDateOrdinalformat(date, tmp) {
    return [
      (tmp = date.getDate()) +
      ([, 'st', 'nd', 'rd'][/1?.$/.exec(tmp)] || 'th'),
      ['January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ][date.getMonth()],
      date.getFullYear()
    ].join(' ')
  }

  numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }

  render() {

    let return_data = this.state.is_sip_calculator ? this.state.returns_result['sip'] :
      this.state.returns_result['onetime'];

    return (
      <>
        {/* <Header1 /> */}
        {/* <PageTitle /> */}

        <div className="authincation section-padding">
          <div className="container">
            <div class="row" style={{ marginTop: 10 }}>

              <div class="col-xl-8 col-lg-12 col-xxl-8" >
                {/* <div class="card profile_chart transparent" > */}
                {!this.state.is_order_checkout_allowed && !this.state.isloading &&
                  <div >
                    <p style={{ color: 'InfoText', backgroundColor: '#f5857d', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                      {this.state.order_checkout_validation_message}{` `}
                    </p>
                    {!this.state.is_kyc_esign_verified &&
                      <a
                        role="button"
                        style={{ fontSize: 16, color: '#e05d38', textDecoration: 'underline' }}
                        onClick={() => {
                          this.props.history.push({
                            pathname: '/' + this.state.order_checkout_redirect_url + '/',
                            // pathname: '/' + this.state.login_stage + '/',
                            // state: { otp_result: this.props.login_otp_result } 
                          });
                        }}
                      >
                        Click here
                      </a>
                    }
                    {!this.state.is_kyc_esign_verified &&
                      <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                        {`  `} to complete the process
                      </span>
                    }
                  </div>
                }
                <div class="card-body">

                  <h4 class="card-title">{this.state.scheme_name}</h4>
                  <div class="row">
                    <div class="col">
                      <p>NAV : <span style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                        &#8377;{this.state.last_nav_display_text}
                      </span>
                        (<span style={{ fontFamily: 'sans-serif', color: this.state.last_nav_difference_display_text < 0 ? 'red' : '#03b45e' }}>
                          {this.state.last_nav_difference_display_text.toFixed(2)}
                        </span>)

                        {this.state.last_nav_difference_display_text > 0 &&
                          <span>&#8593;</span>
                        }
                        {this.state.last_nav_difference_display_text < 0 &&
                          <span>&#8595;</span>
                        }
                      </p>
                    </div>

                  </div>

                  <div id="chart-timeline">
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="area"
                      height={350}
                    />

                  </div>

                  <div class="row">
                    <div class="card profile_chart">
                      <div class="card-header">
                        <h4 class="card-title">RETURNS CALCULATOR</h4>
                      </div>
                      <div>
                        <div className="card-body">

                          <form action="#">

                            <div class="row">
                              <div class="col-2">
                                <Form.Check
                                  type="radio"
                                  label="Montly SIP"
                                  name="sip"
                                  id="true"
                                  value={true}
                                  checked={
                                    this.state.is_sip_calculator === true
                                  }
                                  onChange={(e) =>
                                    this.handleRadioChange(
                                      true,
                                      "is_sip_calculator"
                                    )
                                  }
                                />
                              </div>
                              <div class="col-2">
                                <Form.Check
                                  type="radio"
                                  label="One Time"
                                  name="sip"
                                  id="false"
                                  value={false}
                                  checked={
                                    this.state.is_sip_calculator === false
                                  }
                                  onChange={(e) =>
                                    this.handleRadioChange(
                                      false,
                                      "is_sip_calculator"
                                    )
                                  }
                                />
                              </div>

                              <div class="col-4">
                                <span style={{ fontFamily: 'sans-serif' }} > Enter Investment Amount &#8377; </span>
                              </div>
                              <div class="col-4" style={{ marginTop: -10, marginLeft: -40 }}>
                                <div className="form-group">
                                  {this.state.is_sip_calculator &&
                                    <input style={{ width: 200 }} type="text" className="form-control font-weight-bold"
                                      value={this.state.sipPrincipal}
                                      maxLength="6"
                                      // inputChange = {event => {
                                      //   if (event.target.key === 'Enter') {
                                      //     event.preventDefault();
                                      //   }
                                      // }}
                                      onChange={event => {
                                        const targetValue = event.target.value; //.replace(/\s/g, '');
                                        if (targetValue.length === 0 || targetValue.match(/^[0-9]+$/)) {
                                          this.calculate(event, true);
                                        }
                                      }
                                      } />
                                  }
                                  {!this.state.is_sip_calculator &&
                                    <input type="text" className="form-control font-weight-bold"
                                      value={this.state.onetimePrincipal}
                                      maxLength="6"
                                      onKeyPress={event => {
                                        if (event.target.key === 'Enter') {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={event => {
                                        const targetValue = event.target.value; //.replace(/\s/g, '');
                                        if (targetValue.length === 0 || targetValue.match(/^[0-9]+$/)) {
                                          this.calculate(event, false);
                                        }
                                      }
                                      } />
                                  }


                                </div>

                              </div>
                            </div>

                            <Table>
                              <thead>
                                <tr>
                                  <th>
                                    Tenure
                                  </th>
                                  <th style={{ fontFamily: 'sans-serif' }}>
                                    Current Value(&#8377;)
                                  </th>
                                  <th >
                                    Absolute(%)
                                  </th>
                                  {!this.state.is_sip_calculator &&
                                    <th>
                                      Annualized(%)
                                    </th>
                                  }
                                </tr>
                              </thead>

                              <tbody>

                                <tr>
                                  <td>
                                    <span>
                                      1 Year
                                    </span>
                                  </td>
                                  <td >
                                    {return_data["1y"] && !isNaN(return_data["1y"]) ?
                                      <span style={{ fontFamily: 'sans-serif', color: '#03b45e' }}>
                                        &#8377;{this.numberWithCommas(parseFloat(return_data["1y"]).toFixed(2))}
                                      </span> : <span>{'-'}</span>
                                    }
                                  </td>
                                  <td >
                                    {return_data["1y"] && !isNaN(return_data["1y"]) ?
                                      <span style={{ color: (parseFloat(return_data["1y_percent"]) < 0) ? 'red' : '#03b45e' }}>
                                        {return_data['1y_percent']}%
                                      </span> : <span>{'-'}</span>
                                    }
                                  </td>
                                  {!this.state.is_sip_calculator &&
                                    <td >
                                      {return_data["1y"] && !isNaN(return_data["1y"]) ?
                                        <span style={{ color: (parseFloat(return_data["1y_percent_annualized"]) < 0) ? 'red' : '#03b45e' }}>
                                          {return_data['1y_percent_annualized']}%
                                        </span> : <span>{'-'}</span>
                                      }
                                    </td>
                                  }
                                </tr>

                                <tr>
                                  <td>
                                    <span>
                                    3 Years
                                    </span>
                                  </td>
                                  <td >
                                    {return_data["3y"] && !isNaN(return_data["3y"]) ?
                                      <span style={{ fontFamily: 'sans-serif', color: '#03b45e' }}>
                                        &#8377;{this.numberWithCommas(parseFloat(return_data["3y"]).toFixed(2))}
                                      </span> : <span>{'-'}</span>
                                    }
                                  </td>
                                  <td >
                                    {return_data["3y"] && !isNaN(return_data["3y"]) ?
                                      <span style={{ color: (parseFloat(return_data["3y_percent"]) < 0) ? 'red' : '#03b45e' }}>
                                        {return_data['3y_percent']}%
                                      </span> : <span>{'-'}</span>
                                    }
                                  </td>
                                  {!this.state.is_sip_calculator &&
                                    <td >
                                      {return_data["3y"] && !isNaN(return_data["3y"]) ?
                                        <span style={{ color: (parseFloat(return_data["3y_percent_annualized"]) < 0) ? 'red' : '#03b45e' }}>
                                          {return_data['3y_percent_annualized']}%
                                        </span> : <span>{'-'}</span>
                                      }
                                    </td>
                                  }
                                </tr>

                                <tr>
                                  <td>
                                    <span>
                                      5 Years
                                    </span>
                                  </td>
                                  <td >
                                    {return_data["5y"] && !isNaN(return_data["5y"]) ?
                                      <span style={{ fontFamily: 'sans-serif', color: '#03b45e' }}>
                                        &#8377;{this.numberWithCommas(parseFloat(return_data["5y"]).toFixed(2))}
                                      </span> : <span>{'-'}</span>
                                    }
                                  </td>
                                  <td >
                                    {return_data["5y"] && !isNaN(return_data["5y"]) ?
                                      <span style={{ color: (parseFloat(return_data["5y_percent"]) < 0) ? 'red' : '#03b45e' }}>
                                        {return_data['5y_percent']}%
                                      </span> : <span>{'-'}</span>
                                    }
                                  </td>
                                  {!this.state.is_sip_calculator &&
                                    <td >
                                      {return_data["5y"] && !isNaN(return_data["5y"]) ?
                                        <span style={{ color: (parseFloat(return_data["5y_percent_annualized"]) < 0) ? 'red' : '#03b45e' }}>
                                          {return_data['5y_percent_annualized']}%
                                        </span> : <span>{'-'}</span>
                                      }
                                    </td>
                                  }
                                </tr>

                              </tbody>

                            </Table>

                          </form>

                        </div>

                      </div>

                    </div>
                  </div>

                  {/* Table data */}
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="row">
                        {/* <div class="col-xl-8 col-lg-12 col-xxl-8"> */}
                        <div class="card profile_chart">
                          <div class="card-header">
                            <h4 class="card-title">Fund Portfolio</h4>
                          </div>
                          <Box sx={{ width: "100%" }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                              <Tabs
                                value={this.state.tab_portfolio}
                                onChange={(event, newValue) => {
                                  this.setState({ tab_portfolio: newValue });
                                }}
                              >
                                <Tab label="Scheme Returns" {...a11yProps(0)} style={{ fontSize: 12, fontWeight: 'bold' }} />
                                <Tab label="Holdings" {...a11yProps(1)} style={{ fontSize: 12, fontWeight: 'bold' }} />
                                <Tab label="Sector" {...a11yProps(2)} style={{ fontSize: 12, fontWeight: 'bold' }} />
                                <Tab label="Fund Managers" {...a11yProps(3)} style={{ fontSize: 12, fontWeight: 'bold' }} />
                                <Tab label="Scheme Information" {...a11yProps(4)} style={{ fontSize: 12, fontWeight: 'bold' }} />
                              </Tabs>
                            </Box>
                            <TabPanel value={this.state.tab_portfolio} index={0}>
                              <div class="buy-sell-widget">
                                <div id="chart-timeline">

                                  <div class="row">
                                    <div class="col-2">
                                      <div style={{ marginTop: 50 }}>
                                        Absolute
                                      </div>
                                      <div style={{ marginTop: 10 }} />
                                      <div style={{ marginTop: 20 }}>
                                        Annualized
                                      </div>

                                    </div>

                                    <div class="col-5">
                                      <div style={{ fontFamily: 'sans-serif', borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                        <h6>Lumpsum/Onetime</h6>
                                      </div>
                                      {
                                        this.state.lumpsum_returns_calculation['absolute'] &&
                                        <div class="row">
                                          {!isNaN(this.state.lumpsum_returns_calculation['absolute']['1y_percent']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                              <div style={{ marginTop: 5 }}></div>
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.lumpsum_returns_calculation['absolute']['1y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.lumpsum_returns_calculation['absolute']['1y_percent']}%</span>
                                            </div>
                                          }
                                          {!isNaN(this.state.lumpsum_returns_calculation['absolute']['3y_percent']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                              <div style={{ marginTop: 5 }}></div>
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.lumpsum_returns_calculation['absolute']['3y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.lumpsum_returns_calculation['absolute']['3y_percent']}%</span>
                                            </div>
                                          }
                                          {!isNaN(this.state.lumpsum_returns_calculation['absolute']['5y_percent']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                              <div style={{ marginTop: 5 }}></div>
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.lumpsum_returns_calculation['absolute']['5y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.lumpsum_returns_calculation['absolute']['5y_percent']}%</span>
                                            </div>
                                          }
                                        </div>
                                      }
                                      <div style={{ marginTop: 15 }} />
                                      {
                                        this.state.lumpsum_returns_calculation['annualized'] &&
                                        <div class="row">
                                          {!isNaN(this.state.lumpsum_returns_calculation['annualized']['1y_percent']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ marginTop: 5 }}></div>
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.lumpsum_returns_calculation['annualized']['1y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.lumpsum_returns_calculation['annualized']['1y_percent']}%</span>
                                            </div>
                                          }
                                          {!isNaN(this.state.lumpsum_returns_calculation['annualized']['3y_percent']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ marginTop: 5 }}></div>
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.lumpsum_returns_calculation['annualized']['3y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.lumpsum_returns_calculation['annualized']['3y_percent']}%</span>
                                            </div>
                                          }
                                          {!isNaN(this.state.lumpsum_returns_calculation['annualized']['5y_percent']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ marginTop: 5 }}></div>
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.lumpsum_returns_calculation['annualized']['5y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.lumpsum_returns_calculation['annualized']['5y_percent']}%</span>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>

                                    <div class="col-5">
                                      <div style={{ fontFamily: 'sans-serif', borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                        <h6>SIP</h6>
                                      </div>
                                      <div class="row">
                                        {!isNaN(this.state.sip_returns_calculation['1y_percent']) &&
                                          <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                            <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                            <div style={{ marginTop: 5 }}></div>
                                            <span style={{ fontSize: 13, color: parseFloat(this.state.sip_returns_calculation['1y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                              {this.state.sip_returns_calculation['1y_percent']}%</span>
                                          </div>
                                        }
                                        {!isNaN(this.state.sip_returns_calculation['3y_percent']) &&
                                          <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                            <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                            <div style={{ marginTop: 5 }}></div>
                                            <span style={{ fontSize: 13, color: parseFloat(this.state.sip_returns_calculation['3y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                              {this.state.sip_returns_calculation['3y_percent']}%</span>
                                          </div>
                                        }
                                        {!isNaN(this.state.sip_returns_calculation['5y_percent']) &&
                                          <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                            <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                            <div style={{ marginTop: 5 }}></div>
                                            <span style={{ fontSize: 13, color: parseFloat(this.state.sip_returns_calculation['5y_percent'] < 0) ? '#f57362' : '#03b45e' }}>
                                              {this.state.sip_returns_calculation['5y_percent']}%</span>
                                          </div>
                                        }
                                      </div>

                                      {/* <div style={{ marginTop: 20 }} />
                                      {
                                        this.state.sip_returns_calculation['1y_percent_annualized'] &&
                                        <div class="row">
                                          {!isNaN(this.state.sip_returns_calculation['1y_percent_annualized']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.sip_returns_calculation['1y_percent_annualized'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.sip_returns_calculation['1y_percent_annualized']}%</span>
                                            </div>
                                          }
                                          {!isNaN(this.state.sip_returns_calculation['3y_percent_annualized']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.sip_returns_calculation['3y_percent_annualized'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.sip_returns_calculation['3y_percent_annualized']}%</span>
                                            </div>
                                          }
                                          {!isNaN(this.state.sip_returns_calculation['5y_percent_annualized']) &&
                                            <div style={{ width: '30px', textAlign: 'center' }} class="col">
                                              <span style={{ fontSize: 13, color: parseFloat(this.state.sip_returns_calculation['5y_percent_annualized'] < 0) ? '#f57362' : '#03b45e' }}>
                                                {this.state.sip_returns_calculation['5y_percent_annualized']}%</span>
                                            </div>
                                          }
                                        </div>
                                      } */}

                                    </div>

                                  </div>


                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel value={this.state.tab_portfolio} index={1}>
                              {/* <Card sx={{ p: 1, borderRadius: 1 }}>
                                <CardContent  > */}
                              <Table>
                                <thead>
                                  <tr>
                                    <th>
                                      #
                                    </th>
                                    <th>
                                      Company Name
                                    </th>
                                    <th >
                                      Sector
                                    </th>
                                    <th>
                                      Instrument
                                    </th>
                                    <th>
                                      Assets
                                    </th>
                                  </tr>
                                </thead>
                                {this.state.holdings_list.map((item, index) => (
                                  <tbody key={item["stock_search_id"]}>
                                    <tr>
                                      <td>
                                        {index + 1}
                                      </td>
                                      <td>
                                        {item["company_name"]}
                                      </td>
                                      <td >
                                        {item["sector_name"]}
                                      </td>
                                      <td >
                                        {item["instrument_name"]}
                                      </td>
                                      <td >
                                        {" "}
                                        {item["corpus_per"].toFixed(2)}%
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </Table>
                              {/* </CardContent>
                              </Card> */}
                            </TabPanel>
                            <TabPanel value={this.state.tab_portfolio} index={2}>
                              <div class="buy-sell-widget">
                                <div class="col-xl-4 col-lg-12 col-xxl-4">
                                  <div class="card balance-widget transparent">
                                    <div >
                                      {/* <Chart
                                    options={this.state.optionspie_sector}
                                    series={this.state.seriespie_sector}
                                    type="bar"
                                    width="700"
                                  /> */}
                                      <ReactApexChart options={this.state.options_bar}
                                        series={this.state.seriespie_sector_bar}
                                        type="bar"
                                        height={this.state.seriespie_sector_length * this.state.series_bar_height}
                                        width="670" />

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel value={this.state.tab_portfolio} index={3}>
                              <div class="buy-sell-widget">
                                <div id="chart-timeline">
                                  <Table>
                                    {/* <thead>
                                  <tr>
                                    <th>
                                      <h5 class="card-title text-success">
                                        Profession Information
                                      </h5>
                                    </th>
                                  </tr>
                                </thead> */}
                                    {this.state.fund_manager_list.map((item) => (
                                      <tbody key={item["person_id"]}>
                                        <tr >
                                          <td>
                                            <h5 class="card-title" >
                                              {item["person_name"]}
                                            </h5>
                                            <hr style={{ width: (item["person_name"].length * 9) }} />
                                            <div >
                                              {item["education"]}
                                            </div>
                                            <div >
                                              {item["experience"]}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </Table>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel value={this.state.tab_portfolio} index={4}>
                              <div class="buy-sell-widget">
                                <div id="chart-timeline">
                                  <Table>
                                    <tbody >
                                      <tr >
                                        <td>
                                          Publish soon
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </TabPanel>
                          </Box>

                        </div>
                        {/* </div> */}

                      </div>
                    </div>
                  </div>

                </div>
                {/* </div> */}
              </div>

              <div class="col-xl-4 col-lg-12 col-xxl-4">

                <div style={{ position: 'fixed' }} class="card balance-widget transparent">

                  <div class="card">

                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={this.state.tab_investment_category}
                          onChange={(event, newValue) => {
                            this.setState({ tab_investment_category: newValue });
                          }}
                          aria-label="basic tabs example"
                        >
                          {this.state.frequency_option_list.length > 0 &&
                            <Tab label="INVEST IN SIP" {...a11yProps(0)} />}
                          {this.state.general_order_frequency_list.length > 0 &&
                            <Tab label="ONTIME/LUMPSUM" {...a11yProps(1)} />}
                        </Tabs>
                      </Box>
                      <TabPanel value={this.state.tab_investment_category} index={0}>
                        <div class="buy-sell-widget">
                          <form name="myform" class="currency_validate">

                            <div class="form-group">
                              {/* <h5 class="mr-sm-2 text-gray-dark">Choose Frequency</h5 > */}

                              <Select placeholder={`Choose Frequency`}
                                value={this.state.sip_order_frequency_list.find(object => object.value === this.state.sip_order['sip_frequency'] || '')}
                                onChange={(e) => this.handleChangeSelect(e, 'sip_order', 'sip_frequency', 'sip')}
                                options={this.state.sip_order_frequency_list}
                                isSearchable={false}
                              />
                            </div>
                            <div>
                              <label></label>
                            </div>
                            <div class="form-group">
                              {/* <h5 class="mr-sm-2 text-gray-dark">Choose Your Investment Range </h5 > */}

                              <Select placeholder={`Choose Your Investment Range`}
                                value={this.state.sip_order_frequency_option_list.find(obj => obj.scheme_code === this.state.sip_order['scheme_code'] || '')}
                                onChange={(e) => this.handleChangeSelect(e, 'sip_order', 'scheme_code', 'sip')}
                                options={this.state.sip_order_frequency_option_list}
                                isSearchable={false}
                              />
                            </div>

                            {this.state.sip_order['is_pause_allowed'] &&
                              // <div class="form-group">
                              // <div class="d-flex justify-content-between mt-3">
                              <p class="mb-0" style={{ color: "green" }}>{this.state.sip_order['pause_text']}</p>
                              // </div>
                              // </div>
                            }
                            <div>
                              <label></label>
                            </div>

                            <div class="form-group">
                              {/* <h5 class="mr-sm-2 text-gray-dark">Choose number of installments</h5 > */}

                              <Select placeholder={`Choose number of installments`}
                                value={this.state.sip_installment_numbers_list.find(obj => obj.value === this.state.sip_order['sip_installment_numbers'] || '')}
                                onChange={(e) => this.handleChangeSelect(e, 'sip_order', 'sip_installment_numbers', 'sip')}
                                options={this.state.sip_installment_numbers_list}
                                isSearchable={false}
                              />
                            </div>
                            <div>
                              <label></label>
                            </div>

                            <div class="form-group">
                              <div class="row">
                                <div class="col-7">
                                  {(this.state.sip_order['sip_frequency'] || '').length < 4 &&
                                    <div style={{ marginTop: 10 }} ></div>
                                  }
                                  <span style={{ fontFamily: 'sans-serif' }}>Enter Amount &#8377;
                                    {(this.state.sip_order['sip_frequency'] || '').length > 4 ?
                                      ' (' + this.state.sip_order['sip_frequency'] + ')' : ''}
                                  </span>
                                  <input
                                    type="text"
                                    name="sip_amount"
                                    class="form-control"
                                    placeholder=""
                                    maxLength="11"
                                    value={this.state.sip_order['sip_amount']}
                                    onKeyPress={event => {
                                      if (event.target.key === 'Enter') {
                                        event.preventDefault();
                                      }
                                    }}
                                    onChange={event => {
                                      const targetValue = event.target.value.replace(/\s/g, '');
                                      if (targetValue.length === 0)
                                        this.setState({ sip_order: { ...this.state.sip_order, sip_amount: targetValue } });
                                      else {

                                        if (targetValue.match(/^[0-9]+$/))
                                          if (parseFloat(targetValue) <= parseFloat(this.state.selected_sip_order['maximum_installment_amount']))
                                            this.setState({ sip_order: { ...this.state.sip_order, sip_amount: targetValue } });

                                      }

                                    }

                                    }
                                    disabled={(this.state.sip_order['display_text'] || '').length < 4}
                                  />
                                </div>
                                <div class="col-5">
                                  {(this.state.sip_order['sip_frequency'] || '').length < 4 &&
                                    <div style={{ marginTop: 10 }} ></div>
                                  }
                                  SIP Payment Date
                                  <DatePicker className="form-control"
                                    selected={this.state.sip_order.sip_date}
                                    onChangeRaw={(e) => e.preventDefault()}
                                    // readOnly={true}
                                    onChange={(date) => {
                                      this.setState({
                                        sip_order: { ...this.state.sip_order, sip_date: date }
                                        // sip_minimum_date: date
                                      })
                                    }
                                    }
                                    minDate={this.state.sip_minimum_date}
                                    maxDate={this.state.sip_maximum_date}
                                    shouldCloseOnSelect={true}
                                    excludeDates={this.state.sip_exclude_date_list}
                                  />
                                </div>
                              </div>

                            </div>

                            {this.state.sip_order['purchase_cutoff_time'].length > 4 &&
                              <div class="form-group" >
                                <div class="d-flex justify-content-between mt-3">
                                  <h5 class="mb-0">Lockin Period Enabled ?: {this.state.sip_order['lockin_period_flag'] === 'Y' ? 'YES' : 'NO'}</h5>
                                  {this.state.sip_order['lockin_period_flag'] === 'Y' &&
                                    <h5 class="mb-0">Lockin Period : {this.state.sip_order['lockin_period']}</h5>
                                  }
                                </div>
                                <div class="d-flex justify-content-between mt-3">
                                  <h5 class="mb-0">Cut-Off Time : {this.state.sip_order['purchase_cutoff_time']}</h5>
                                </div>
                              </div>

                            }
                            <div>
                              <label></label>
                            </div>
                            <div className="form-group">
                              {this.state.sip_order['sip_frequency'].length > 4 &&
                                <div className="form-group">
                                  <div >
                                    <p style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>SIP amount will be debited from your primary account on
                                      <b>{' '}{this.fnDateOrdinalformat(this.state.sip_order['sip_date']).split(' ')[0]}</b>
                                      {' of every '}
                                      {this.state.sip_order['sip_frequency'].toLowerCase().replace('ly', '')}
                                      {'.'}
                                    </p>
                                    <p style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                      {'Your first installment date would be '}
                                      <b>{this.state.sip_order['sip_date'].toLocaleDateString('en-GB', {
                                        day: 'numeric', month: 'short', year: 'numeric'
                                      }).replace(/ /g, '-')}</b></p>
                                  </div>
                                  <hr />
                                </div>
                              }
                            </div>

                            {this.state.is_order_checkout_allowed && !this.state.is_pending_order &&
                              <div>
                                <label></label>
                              </div>
                            }

                            {this.state.is_order_checkout_allowed && !this.state.is_pending_order &&
                              <button
                                type="submit"
                                name="submit"
                                class="btn btn-success btn-block"
                                onClick={() => { this.startSIP() }}
                                disabled={isNaN(this.state.sip_order['sip_amount'] || '') ||
                                  !this.sipParameterValidation() ||
                                  !this.state.is_order_checkout_allowed ||
                                  this.state.is_pending_order
                                }
                              >
                                Start SIP
                              </button>
                            }


                            {!this.state.is_order_checkout_allowed && !this.state.isloading ?

                              (
                                <div class="row">
                                  <div class="col">
                                    <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', backgroundColor: '#f5c09d', }}>
                                      {this.state.order_checkout_validation_message}{` `}
                                    </span>
                                    {!this.state.is_kyc_esign_verified &&
                                      <a
                                        role="button"
                                        style={{ fontSize: 16, color: '#e05d38', textDecoration: 'underline' }}
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: '/' + this.state.order_checkout_redirect_url + '/',
                                          });
                                        }}
                                      >
                                        Click here
                                      </a>
                                    }
                                    {!this.state.is_kyc_esign_verified &&
                                      <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                        {`  `} to complete the process
                                      </span>
                                    }
                                  </div>
                                </div>
                              )
                              :
                              (

                                <div class="row">
                                  {this.state.is_pending_order &&
                                    <div class="col">
                                      <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                        {this.state.order_checkout_validation_message}{` `}
                                      </span>
                                      <a
                                        role="button"
                                        style={{ fontSize: 16, color: '#e05d38', textDecoration: 'underline' }}
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: '/' + this.state.order_checkout_redirect_url + '/',
                                            // pathname: '/' + this.state.login_stage + '/',
                                            // state: { otp_result: this.props.login_otp_result } 
                                          });
                                        }}
                                      >
                                        Click here{` `}
                                      </a>{`  `}
                                      <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                        to complete the process
                                      </span>
                                    </div>
                                  }
                                </div>

                              )

                            }

                          </form>
                        </div>
                      </TabPanel>
                      <TabPanel value={this.state.tab_investment_category} index={1}>

                        <div class="buy-sell-widget">
                          <form name="myform" class="currency_validate">

                            <div class="form-group">
                              <Select placeholder={`Select Scheme Investment Option`}
                                value={this.state.general_order_frequency_list.find(obj => obj.param_scheme_code === this.state.general_order['scheme_code'] || '')}
                                onChange={(e) => this.handleChangeSelect(e, 'general_order', 'scheme_code', 'order')}
                                options={this.state.general_order_frequency_list}
                                isSearchable={false}
                              />
                            </div>
                            <div>
                              <label></label>
                            </div>

                            <div class="form-group">
                              <div class="row">
                                <div class="col-4">
                                  <div style={{ marginTop: 10 }} ></div>
                                  <label style={{ fontFamily: 'sans-serif' }} class="mr-sm-2"> Enter Amount &#8377;</label>
                                  {(this.state.sip_order['sip_frequency'] || '').length > 4 ?
                                    ' (' + this.state.sip_order['sip_frequency'] + ')' : ''}
                                </div>
                                <div class="col">
                                  <input type="text" className="form-control font-weight-bold"
                                    value={this.state.general_order['order_amount']}
                                    maxLength="8"
                                    onKeyPress={event => {
                                      if (event.target.key === 'Enter') {
                                        event.preventDefault();
                                      }
                                    }}
                                    onChange={event => {

                                      const targetValue = event.target.value; //.replace(/\s/g, '');
                                      if (targetValue === "")
                                        this.setState({ general_order: { ...this.state.general_order, order_amount: targetValue } });
                                      else {

                                        if (targetValue.match(/^[0-9]+$/) && targetValue.toString().length < 9) {

                                          if (parseFloat(targetValue) <= parseFloat(this.state.selected_order['maximum_purchase_amount']))
                                            this.setState({ general_order: { ...this.state.general_order, order_amount: targetValue } });

                                        }

                                      }

                                    }}

                                    disabled={this.state.general_order['display_text'].length < 4}
                                  />
                                </div>
                              </div>

                            </div>

                            <div>
                              <label></label>
                            </div>

                            {this.state.general_order['display_text'].length > 4 &&
                              <div class="form-group">
                                <div class="row">
                                  <div class="col">
                                    <p
                                      style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                      {this.state.general_order['display_text'].split('-')[0]}
                                    </p>
                                  </div>
                                  <div class="col">
                                    <p
                                      style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                      {this.state.general_order['display_text'].split('-')[1].split('(')[0]}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <p
                                      style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                      {this.state.general_order['display_text'].split('-')[1].split('(')[1].slice(0, -1)}
                                    </p>
                                  </div>
                                  <div class="col">
                                    <p style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                      <p class="mb-0">Cut-Off Time : {this.state.general_order['purchase_cutoff_time']}</p>
                                    </p>
                                  </div>
                                </div>


                              </div>
                            }
                            {this.state.general_order['display_text'].length > 4 &&
                              <div class="form-group">

                                <div class="d-flex justify-content-between mt-3"
                                  style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                  <p class="mb-0">Exit Load Enabled: {this.state.general_order['param_exit_load_flag'] === 'Y' ? 'YES' : 'NO'}</p>
                                  {this.state.general_order['param_exit_load_flag'] === 'Y' &&
                                    <p class="mb-0">Exit Load Value: {this.state.general_order['param_exit_load']}</p>
                                  }
                                </div>

                                <div class="d-flex justify-content-between mt-3"
                                  style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                  <p class="mb-0">Lockin Period Enabled: {this.state.general_order['lockin_period_flag'] === 'Y' ? 'YES' : 'NO'}</p>
                                  {this.state.general_order['lockin_period_flag'] === 'Y' &&
                                    <p class="mb-0">Lockin Period : {this.state.general_order['lockin_period']}</p>
                                  }
                                </div>

                                <hr />
                              </div>

                            }

                            {//this.state.is_order_checkout_allowed && !this.state.is_pending_order &&
                              <button
                                type="submit"
                                name="submit"
                                class="btn btn-success btn-block"
                                onClick={() => { this.startOrder() }}
                                disabled={isNaN(this.state.general_order['order_amount'] || '') ||
                                  !this.orderParameterValidation() ||
                                  !this.state.is_order_checkout_allowed ||
                                  this.state.is_pending_order

                                }
                              >
                                Invest Now{" "}
                              </button>
                            }

                            {!this.state.is_order_checkout_allowed && !this.state.isloading ?

                              (
                                <div class="row">
                                  <div>
                                    <label></label>
                                  </div>
                                  <div class="col">
                                    <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', backgroundColor: '#f5c09d', }}>
                                      {this.state.order_checkout_validation_message}{` `}
                                    </span>
                                    {!this.state.is_kyc_esign_verified &&
                                      <a
                                        role="button"
                                        style={{ fontSize: 16, color: '#e05d38', textDecoration: 'underline' }}
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: '/' + this.state.order_checkout_redirect_url + '/',
                                          });
                                        }}
                                      >
                                        Click here
                                      </a>
                                    }
                                    {!this.state.is_kyc_esign_verified &&
                                      <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                        {`  `} to complete the process
                                      </span>
                                    }
                                  </div>
                                </div>
                              )
                              :
                              (

                                <div>
                                  {this.state.is_pending_order &&

                                    <div class="row">
                                      <div>
                                        <label></label>
                                      </div>
                                      <div class="col">
                                        <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                          {this.state.order_checkout_validation_message}{` `}
                                        </span>
                                        <a
                                          role="button"
                                          style={{ fontSize: 16, color: '#e05d38', textDecoration: 'underline' }}
                                          onClick={() => {
                                            this.props.history.push({
                                              pathname: '/' + this.state.order_checkout_redirect_url + '/',
                                              // pathname: '/' + this.state.login_stage + '/',
                                              // state: { otp_result: this.props.login_otp_result } 
                                            });
                                          }}
                                        >
                                          Click here{` `}
                                        </a>{`  `}
                                        <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                          to complete the process
                                        </span>
                                      </div>
                                    </div>
                                  }
                                </div>
                              )

                            }

                          </form>
                        </div>
                      </TabPanel>
                    </Box>

                    {this.state.isloading &&
                      <Spinner animation="border" variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                    }

                  </div>

                </div>
              </div>

            </div>


          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  chart_result: state.charts.chart_result,
  loading: state.charts.loading,
});

export default connect(mapStateToProps, { listChartSchemes })(Charts);
