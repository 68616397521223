import React from "react";
import { Link } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  verifyAadhaar,
  verifyStage,
  loadStageInfo,
} from "../../jsx/actions/verify";
import { Redirect } from "react-router-dom";
import Header1 from "../layout/header1";
import Sidebar from "../layout/sidebar";

var is_esign_disabled = false;
class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login_mobile_number: "",
      login_email: "",
      login_state: "",
      is_stage_verified: false,
      is_kyc_verified: false,
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.onLoadStageInfo();
  }

  handleConfirmCheck = (event) => {
    //  this.setState({is_confirm_chk_checked: !this.state.is_confirm_chk_checked})
  };

  onLoadStageInfo = () => {
    this.setState({ isLoading: true });

    this.props
      .loadStageInfo({ stage_name: "esignkyc" })
      .then((response) => {
        this.setState({ isLoading: false });
        if (this.props.personal_result["code"] !== "000")
          alert(this.props.personal_result["messageText"]);
        else {
          if (this.props.personal_result["state_parameters"])
            this.setState(this.props.personal_result["state_parameters"]);
          else
            this.setState({
              is_stage_verified: this.props.personal_result["is_verified"],
              is_verify_enabled: this.props.personal_result["is_verified"],
              is_confirm_chk_enabled: true,
              esignkyc_information:
                this.props.personal_result["id_information"],
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  render() {
    // if(this.props.personal_result && this.props.personal_result['esignkyc_stage_verified'])
    //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div className="authincation section-padding">
          <div className="content-body" style={{ marginTop: 100 }}>
            <div className="container">
              <div class="row">
                <div class="col-xl-12">
                  <div className="auth-form card">
                    <div className="card-body">
                      {this.state.isLoading && (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{ position: "fixed", top: "50%", left: "50%" }}
                        />
                      )}
                      {/* <Link className="page-back text-muted" to={'./panVerification'}><span><i
                                        className="fa fa-angle-left"></i></span> Back</Link> */}
                      <h3 className="text-center">Account Information</h3>
                      {/* <p className="text-center mb-5">PAN card number is compulsory for investing on mutual funds</p> */}

                      {this.state.esignkyc_information &&
                        this.state.esignkyc_information["general"] && (
                          <div>
                            <div className="card-body">
                              {this.state.isLoading && (
                                <Spinner
                                  animation="border"
                                  variant="dark"
                                  style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                  }}
                                />
                              )}
                              {
                                <div>
                                  <span
                                    style={{
                                      color: "InfoText",
                                      backgroundColor: "#e3e0de",
                                      padding: 4,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      margin: 4,
                                      borderRadius: 4,
                                    }}
                                  >
                                    {this.state.login_email}
                                  </span>
                                  <span
                                    style={{
                                      color: "InfoText",
                                      backgroundColor: "#e3e0de",
                                      padding: 4,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      margin: 4,
                                      borderRadius: 4,
                                    }}
                                  >
                                    {this.state.login_mobile_number}
                                  </span>

                                  {!this.state.is_kyc_verified && (
                                    <div style={{marginTop:20}}>
                                      

                                      <li>
                                        <a href={"/esignkycVerification"} className="btn btn-success btn-block">
                                        KYC is not submitted and current
                                        verification stage is { ''}
                                        {this.state.login_stage} 
                                        </a>
                                      </li>
                                    </div>
                                  )}
                                  <hr />
                                </div>
                              }
                            </div>
                          </div>
                        )}

                      {this.state.esignkyc_information &&
                        this.state.esignkyc_information["pan"] &&
                        this.state.login_stage !== "pan" && (
                          <div>
                            <div className="card-body">
                              <form action="#">
                                <b>PAN Information</b>
                                {this.state.isLoading && (
                                  <Spinner
                                    animation="border"
                                    variant="dark"
                                    style={{
                                      position: "fixed",
                                      top: "50%",
                                      left: "50%",
                                    }}
                                  />
                                )}
                                {this.state.esignkyc_information["pan"].map(
                                  (el, i) => (
                                    <div key={i}>
                                      <label>
                                        {el["attribute"]} {"  :  "}
                                        <label>{el["text"]}</label>
                                      </label>
                                    </div>
                                  )
                                )}
                                {this.state.esignkyc_information[
                                  "pan_attachment_list"
                                ] && (
                                  <div className="text-center">
                                    {this.state.esignkyc_information[
                                      "pan_attachment_list"
                                    ].map((el, i) => (
                                      <div key={i} className="form-group">
                                        <img
                                          src={el}
                                          style={{ width: 250, height: 250 }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        )}

                      {this.state.esignkyc_information &&
                        this.state.esignkyc_information["aadhaar"] &&
                        this.state.login_stage !== "pan" &&
                        this.state.login_stage !== "aadhaar" && (
                          <div>
                            <div className="card-body">
                              <form action="#">
                                {this.state.isLoading && (
                                  <Spinner
                                    animation="border"
                                    variant="dark"
                                    style={{
                                      position: "fixed",
                                      top: "50%",
                                      left: "50%",
                                    }}
                                  />
                                )}
                                <b>Aadhaar Information</b>
                                {this.state.esignkyc_information["aadhaar"].map(
                                  (el, i) => (
                                    <div key={i}>
                                      <label>
                                        {el["attribute"]} {"  :  "}
                                        <label>{el["text"]}</label>
                                      </label>
                                    </div>
                                  )
                                )}
                                {this.state.esignkyc_information[
                                  "aadhaar_attachment_list"
                                ] && (
                                  <div className="text-center">
                                    {this.state.esignkyc_information[
                                      "aadhaar_attachment_list"
                                    ].map((el, i) => (
                                      <div key={i} className="form-group">
                                        <img
                                          src={el}
                                          style={{ width: 250, height: 250 }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        )}

                      {this.state.esignkyc_information &&
                        this.state.esignkyc_information["bank"] &&
                        this.state.login_stage !== "pan" &&
                        this.state.login_stage !== "aadhaar" &&
                        this.state.login_stage !== "bank" && (
                          <div>
                            <div className="card-body">
                              <form action="#">
                                <b>Bank Information</b>
                                {this.state.esignkyc_information["bank"].map(
                                  (el, i) => (
                                    <div key={i}>
                                      <label>
                                        {el["attribute"]} {"  :  "}
                                        <label>{el["text"]}</label>
                                      </label>
                                    </div>
                                  )
                                )}
                                {this.state.esignkyc_information[
                                  "bank_attachment_list"
                                ] && (
                                  <div className="text-center">
                                    {this.state.esignkyc_information[
                                      "bank_attachment_list"
                                    ].map((el, i) => (
                                      <div key={i} className="form-group">
                                        <img
                                          src={el}
                                          style={{ width: 250, height: 250 }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        )}

                      {this.state.esignkyc_information &&
                        this.state.esignkyc_information["address"] &&
                        this.state.login_stage === "personal" && (
                          <div>
                            <div className="card-body">
                              <form action="#">
                                <b>Communication Address</b>
                                {this.state.esignkyc_information["address"].map(
                                  (el, i) => (
                                    <div key={i}>
                                      <label>
                                        {el["attribute"]} {"  :  "}
                                        <label>{el["text"]}</label>
                                      </label>
                                    </div>
                                  )
                                )}
                              </form>
                            </div>

                            {this.state.esignkyc_information["nominee"] && (
                              <div className="card-body">
                                <form action="#">
                                  <b>Nominee Information</b>
                                  {this.state.esignkyc_information[
                                    "nominee"
                                  ].map((el, i) => (
                                    <div key={i}>
                                      <label>
                                        {el["attribute"]} {"  :  "}
                                        <label>{el["text"]}</label>
                                      </label>
                                    </div>
                                  ))}
                                  {this.state.esignkyc_information[
                                    "nominee_attachment_list"
                                  ] && (
                                    <div className="text-center">
                                      {this.state.esignkyc_information[
                                        "nominee_attachment_list"
                                      ].map((el, i) => (
                                        <div key={i} className="form-group">
                                          <img
                                            src={el}
                                            style={{ width: 250, height: 250 }}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </form>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// EsignkycVerification.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = (state) => ({
  personal_result: state.verify.personal_result,
});

export default connect(mapStateToProps, { verifyStage, loadStageInfo })(
  Accounts
);
