import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import Index from './jsx';
import { Lines } from 'react-preloaders';
import { LOGOUT } from './jsx/actions/types';
import setAuthToken from './jsx/utils/setAuthToken';
// Redux
import { Redirect, Switch, Route, withRouter } from "react-router";

import Spinner from "./jsx/layout/spinner";
import { Provider } from 'react-redux';
import store from './store';
//import { loadUser } from './jsx/actions/auth';
import Background from './images/background/bg-16.jpg';

// import Emitter from './jsx/utils/emitter';

class App extends React.Component  {
  constructor (props) {
    super(props);
   }

componentDidMount(){
  
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    
  }
 
  // Emitter.on('APPJS', (data) => {
  //     alert(data)
  // });

  window.dispatchEvent(new CustomEvent("navigationhandler"));

 // store.dispatch(loadUser());

  // log user out from all tabs if they log out in one tab
  window.addEventListener('storage', () => {
    if (!localStorage.token) store.dispatch({ type: LOGOUT });
  });  

  
  const script = document.createElement("script");
  script.src = "https://app.digio.in/sdk/v9/digio.js";
  script.async = true;
  document.head.appendChild(script);      

}





  render(){
    
  return (
    <div>
    <div className="App">
{this.props.isloading && <Spinner />}
        <Provider store={store}>
       
      <Index />
      <Lines />
      </Provider>
    </div>
    </div>
  );
}
}
export default App;
