import React, { TouchableOpacity} from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router";
import { DropdownButton,Button,Form } from 'react-bootstrap';
import Emitter from '../utils/emitter';
import { connect } from "react-redux";

// let masterList = JSON.parse(localStorage.getItem("profileData") || JSON.stringify({}));

class Header2 extends React.Component {

// function Header2(props) {

    // const history = useHistory();
    constructor(props) {

        super(props);
        this.state = {
          masterList: {}
        };
  
    }
    
      componentDidMount() {

        // alert(6)
        // masterList = JSON.parse(localStorage.getItem("profileData") || JSON.stringify({}));
        this.setState({ masterList: JSON.parse(localStorage.getItem("profileData") || JSON.stringify({}))});

        Emitter.once('HEADER1', (data) => {
            console.log(data);
            this.setState({ masterList: typeof data === 'string' ? JSON.parse(data) : data});
        });
  
      }

    onLogout = () => {

        localStorage.removeItem('token'); 
        localStorage.removeItem('profileData'); 
        localStorage.removeItem('masterList'); 
        
        this.props.history.push({ 
            pathname: '/signin'
        });

    };

    render() 
    {
    return (
       
        <>
            <div className="header dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                                <div className="header-search d-flex align-items-center">
                                   
                                        {/* <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search" />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2"><i
                                                    className="fa fa-search"></i></span>
                                            </div>
                                        </div> */}
                                    
                                </div>

                                <div className="dashboard_log my-2">
                                    <div className="d-flex align-items-center">
                                        {this.state.masterList && (this.state.masterList["login_email"] || 
                                            this.state.masterList["login_mobile_number"]) && 
                                            <DropdownButton
                                                alignRight
                                                title={(this.state.masterList["login_email"] || '').length > 1 ? this.state.masterList["login_name"] : 
                                                    this.state.masterList["login_mobile_number"]}
                                                variant="success"
                                                className="profile_log"
                                            >
                                                <Link to={'/accounts'} className="dropdown-item">
                                                    <i className="la la-user"></i> Account
                                                </Link>
                                                <Link to={'/orderList'} className="dropdown-item">
                                                    <i className="la la-book"></i> Order List
                                                </Link>
                                                {/* <Link to={'/settings'} className="dropdown-item">
                                                    <i className="la la-cog"></i> Setting
                                                </Link>
                                                <Link to={'/lock'} className="dropdown-item">
                                                    <i className="la la-lock"></i> Lock
                                                </Link> */}
                                                <Link to='/signin' onClick={() => this.onLogout} className="dropdown-item logout">
                                                    <i className="la la-sign-out"></i> Logout
                                                    

                                                </Link>
                                            </DropdownButton>
                                        }
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    }
}

// export default Header2;

const mapStateToProps = (state) => ({
    // isloading: state.master.loading,
});

export default connect(mapStateToProps, { })(Header2);