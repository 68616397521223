import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { createOrder, modifyOrder, uploadMandate, loadNachMandateForm } from '../../actions/orders';
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
//import Spinner from "../../layout/spinner";
import { Table, Collapse, } from "react-bootstrap";
import Popup from '../popup'
import { PDFReader } from 'reactjs-pdf-reader';

class OrderCheckout extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selected_order: {},
            general_order: {},
            list_bank_account: [],
            loading: false,
            nofitication_html: ``,
            render_pdf: null,
            enachmandateFile: null,
            src_enachmandateFile: null,
            byte_enachmandateFile: null,
            invest_more_amount: 0.00,
            redeemable_amount: 0.00,
            is_redeem_full_checked: false,
            is_stage_verified: false,
            is_verify_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            show_popup: false,
            pop_messagetext: '',
            isloading: false,
            error: ''
        };

    }

    componentDidMount() {
        // console.log(this.props.location.state)
        this.setState(this.props.location.state);
    }

    gotoPaymentPage() {

        this.props.history.push({
            pathname: '/paymentPage/',
            state: { selected_order: this.state.selected_order }
        });

    }

    onCreateOrder = () => {

        this.setState({ isloading: true });

        let _bank_account = (this.state.list_bank_account || []).filter(el => {
            return el['is_primary'] === true;
        })[0];

        this.props.createOrder({
            client_info: {
                'scheme_code': this.state.general_order['scheme_code'],
                'scheme_name': this.state.general_order['scheme_name'],
                'scheme_isin': this.state.general_order['scheme_isin'],
                'buy_sell': 'P',
                'buy_sell_type': 'FRESH',
                'order_amount': this.state.general_order['order_amount'],
                'bank_account_information': _bank_account,
                'selected_order': this.state.selected_order
            }

        }).then(response => {

            this.setState({ isloading: false });
            if (this.props.order_result['code'] !== '000') {
                /*
                this.setState({
                    show_popup: true,
                    pop_messagetext: this.props.order_result['messageText']
                });
                */
                alert(this.props.order_result['messageText']);
            }
            else {
                if (this.props.order_result['result']) {

                    this.props.history.push({
                        pathname: '/orderEditCheckout/',
                        state: { selected_order: this.props.order_result['result'] }
                    });

                }

            }

        })
            .catch(err => {
                this.setState({ error: err })
            });


    };

    handleConfirmCheck = (e, item) => {

        const _list = this.state.list_bank_account;
        _list.forEach(el => {

            if (el['beneficiary_account_no'] === item['beneficiary_account_no'])
                item['is_primary'] = !item['is_primary'];
            else
                item['is_primary'] = false;
        });

        this.setState({ list_bank_account: _list });


        console.log(this.state.list_bank_account)

    }

    handleInvestAmount(event, attribute) {

        const re = /^\d+(\.\d{0,3})?$/ ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                /*invest_more_amount*/[attribute]: event.target.value.replace(/\b0+/g, '')
            });
        }

    }

    changeHandler = async (event) => {

        console.log(event.target.files[0])
        if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
            event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {


            {
                const file = event.target.files[0];
                const buffer = await file.arrayBuffer();
                console.log(buffer)
                let byteArray = new Int8Array(buffer);
                this.setState({
                    enachmandateFile: file,
                    byte_enachmandateFile: byteArray,
                    src_enachmandateFile: URL.createObjectURL(file)
                });
            }
        }
        else {
            this.setState({
                show_popup: true,
                pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
            });
        }

    }

    createDefaultMarkup(text) {
        this.setState({ isloading: true })
        return {
            __html: this.state.nofitication_html,
        }
        this.setState({ isloading: false })
    };

    closePopup = () => {
        this.setState({
            show_popup: false,
            pop_messagetext: ''
        })
    }

    render() {

        // if(this.props.loading)
        //   return <Spinner />;

        // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />; 


        return (
            <>
                <Header1 />
                <Sidebar />

                {this.state.isloading && <div className="loading-overlay"></div>}
                {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                        <Modal.Header>

                        </Modal.Header>
                        <Modal.Body>

                            <p>{this.state["pop_messagetext"]}</p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.closePopup()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {this.state.isloading &&
                    <Spinner animation="border" variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                }

                {!this.props.isloading &&
                    <div className="container" style={{ marginTop: 120 }}>
                        {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="card"  style={{marginLeft:55}}>
                                        <div className="card-header">
                                            <h5 style={{marginLeft:55}} >Create Order - One Time Investment</h5>
                                        </div>
                                        <div className="card-body"> */}
                        <div class="row">
                            <h5 style={{ marginLeft: 55 }} >New Order - One Time Investment</h5>
                        </div>
                        {/* <div class="row">
                                                <div class="col-4" style={{marginLeft:50}}>
                                                    <hr />
                                                </div>
                                            </div> */}

                        <div className="card" style={{marginLeft:50}}>

                            <div className="card-body" >
                                <div class="row" >
                                    <div class="col-8">
                                    <h5 >
                                            {this.state.selected_order['param_scheme_name']}
                                        </h5>
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-4">
                                        {`Scheme Code  : `}<span style={{ fontSize: 13 }}>
                                            {this.state.selected_order['param_scheme_code']}
                                        </span>
                                    </div>
                                    <div class="col-3">
                                        <p style={{ fontSize: 13 }}>
                                            Redeem Allowed : {this.state.selected_order['param_redemption_allowed'] === 'Y' ? 'YES' : 'NO'}
                                        </p>

                                    </div>
                                    <div class="col-2">
                                        <p style={{ fontSize: 13 }}>
                                            Exit Load : {this.state.selected_order['param_exit_load_flag'] === 'Y' ? 'YES' : 'NO'}
                                        </p>
                                    </div>
                                    <div class="col-2">
                                        <p style={{ fontSize: 13 }}>
                                            SWP Available : {this.state.selected_order['param_swp_flag'] === 'Y' ? 'YES' : 'NO'}
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="card" style={{marginLeft:50}}>
                            <div className="card-body" >

                                <div class="row">
                                    <p>
                                        #Primary Bank Account Information
                                    </p>
                                    <table style={{ marginLeft: 15 }}>
                                        {(this.state.list_bank_account || []).map((item, index) => (
                                            <tbody key={index}>
                                                {item["beneficiary_account_no"] !== undefined ?
                                                    <tr >
                                                        <td colSpan={2}>

                                                            <p style={{ fontSize: 13 }}>
                                                                {item["beneficiary_account_no"]} ({item["beneficiary_account_type"]})
                                                            </p>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <p style={{ fontSize: 13 }}>
                                                                {item['beneficiary_account_holder_name'] || ''}
                                                            </p>
                                                        </td>
                                                        <td colSpan={3}>

                                                            <p style={{ fontSize: 13 }}>
                                                                IFSC : {item["beneficiary_ifsc"]}
                                                            </p>

                                                        </td>
                                                        <td colSpan={3}>
                                                            <p style={{ fontSize: 13 }}>
                                                                {item["beneficiary_bank_branch_name"]}
                                                            </p>

                                                        </td>


                                                        <td colSpan={3}>
                                                            <label>
                                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                    <Form.Check type="checkbox"
                                                                        label="Primary Account"
                                                                        onChange={e => { this.handleConfirmCheck(e, item) }}
                                                                        defaultChecked={item["is_primary"]}
                                                                        disabled={true}
                                                                    />
                                                                </Form.Group>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    : <tr>
                                                        <td>
                                                            <h4 class="card-title text-gray-dark" >
                                                                KYC Pending : Please Complete your KYC
                                                            </h4>
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        ))}

                                    </table>
                                </div>

                            </div>
                        </div>
                        
                        {this.state.general_order['order_amount'] &&
                            <div className="card" style={{marginLeft:50}}>
                                <div className="card-body" >
                                    
                                    <div class="row" >
                                        <div class="col-3">
                                            <h5 sstyle={{fontFamily:'sans-serif'}}>
                                                Purchase Amount: &#8377; {this.state.general_order['order_amount']}
                                            </h5>
                                            CUT-OFF TIME : {this.state.selected_order['param_purchase_cutoff_time']}

                                        </div>
                                        {this.state.list_bank_account.length > 0 &&
                                            (this.state.selected_order['request_mode'] || '') !== 'paynow' &&
                                            <div class="col-3">
                                                <Button className="btn btn-success btn-block"
                                                    variant="success"
                                                    onClick={() => {
                                                        this.onCreateOrder()
                                                    }}
                                                    disabled={this.state.isloading}
                                                >
                                                    Create Order
                                                </Button>
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        }

                        {(this.state.selected_order['request_mode'] || '') === 'paynow' &&
                            <div className="card">
                                <div>
                                    <form action="#">
                                        <div class="row">
                                            <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                                        </div>
                                        <div class="row">
                                            <div class="col-3">

                                                <Button className="btn btn-success btn-block"
                                                    variant="success"
                                                    onClick={() => {
                                                        this.gotoPaymentPage()
                                                    }}
                                                >
                                                    Pay Rs. {this.state.selected_order['mf_0rder_pay_amount']}
                                                </Button>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>
                        }

                        {/* </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                }
            </>
        )

    }

}

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {

    const { loading, order_result } = order;
    return {
        loading,
        order_result,

    }

};

export default connect(mapStateToProps, { createOrder, modifyOrder, uploadMandate, loadNachMandateForm })
    (OrderCheckout);