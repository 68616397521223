import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Label, Spinner } from "react-bootstrap";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import useForm from "../../utils/customhooks";
import validate from "../../utils/validate";
//import Spinner from "../../layout/spinner";
const PersonalDetails = ({
  nextStep,
  handleChange,
  values,
  handleRadioChange,
  touched,
  isValid,
  handleInputValidation,
  handleObjectInputChange,
  errors,
  loading,
}) => {
  // for continue event listener

  // handleInputValidation('address_information',
  //   [{ value: values['address_information']['pincode'], pattern: /^[0-9\s]*$/}]
  // );

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };
  return (
    <>
      <Header1 />
      <Sidebar />
      <div className="authincation section-padding" style={{ marginTop: 120 }}>
        <div className="content-body">
          <div className="container">
            <div class="row">
              <div class="col-xl-12">
                <div className="auth-form card">
                  <div className="card-body">
                    {/* <h3 className="text-center">Please Provide Your Details</h3> */}
                    <p className="text-center mb-5">Investor Information</p>
                    <Form>
                      <fieldset>

                        <Form.Group as={Row} className="mb-3">
                          <Col sm={4}>
                            <Form.Label className="text-dark">
                              Marital Status*
                            </Form.Label>
                            <Form.Check
                              type="radio"
                              label="Single"
                              name="marital_status"
                              id="s"
                              value="s"
                              checked={values.marital_status === "s"}
                              onChange={handleRadioChange("marital_status")}
                            />
                            <Form.Check
                              type="radio"
                              label="Married"
                              name="marital_status"
                              id="m"
                              value="m"
                              checked={values.marital_status === "m"}
                              onChange={handleRadioChange("marital_status")}
                            />
                            <Form.Text className="text-danger">
                              {errors && errors["marital_status"]}
                            </Form.Text>
                          </Col>
                          <Col sm={5}>
                            <Form.Label className="text-dark">Email*</Form.Label>

                            <Form.Control
                              placeholder=""
                              onChange={handleChange("email")}
                              disabled
                              defaultValue={values.email}
                            />
                            <Form.Text className="text-danger">
                              {errors.errors && errors.errors["email"]}
                            </Form.Text>
                          </Col>
                        </Form.Group>
                      </fieldset>

                      <Form.Group as={Row}  className="mb-3" controlId="formGridAddress2">
                      <Col sm={12}>
                        <Form.Label className="text-dark">Address*</Form.Label>
                        { `  `}Your Communication Details (You can still edit)
                        <textarea
                        style={{
                          width: "100%",
                          height: '70%'
                        }}
                        value={values["address_information"]["address"] || ""}
                        onChange={handleObjectInputChange(
                          "address_information",
                          "address"
                        )}
                        maxLength="130"
                        defaultValue={
                          values["address_information"]["address"] || ""
                        }
                      />
                      </Col>
                      </Form.Group>
                      

                      <Form.Group as={Row} controlId="formGridCity">
                      <Col sm={4}>
                      <Form.Label className="text-dark">City*</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          value={values["address_information"]["city"] || ""}
                          maxLength="25"
                          onChange={handleObjectInputChange(
                            "address_information",
                            "city",
                            /^[a-zA-Z\s]*$/
                          )}
                        />
                        <Form.Text className="text-danger">
                          {errors && errors["city"]}
                        </Form.Text>
                      </Col>
                      <Col sm={4}>
                      <Form.Label className="text-dark">State*</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          value={values["address_information"]["state"] || ""}
                          maxLength="25"
                          onChange={handleObjectInputChange(
                            "address_information",
                            "state",
                            /^[a-zA-Z\s]*$/
                          )}
                        />
                        <Form.Text className="text-danger">
                          {errors && errors["state"]}
                        </Form.Text>
                      </Col>
                      <Col sm={4}>
                      <Form.Label className="text-dark">Pincode*</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          pattern="[0-9]*"
                          value={values["address_information"]["pincode"] || ""}
                          maxLength="8"
                          onChange={handleObjectInputChange(
                            "address_information",
                            "pincode",
                            /^[0-9\s]*$/
                          )}
                        />
                        <Form.Text className="text-danger">
                          {errors && errors["pincode"]}
                        </Form.Text>
                      </Col>
                        
                      </Form.Group>
                     
                      
                      <Form.Group className="mb-3"></Form.Group>

                      <div className="text-center mb-4">
                        <Button
                          onClick={Continue}
                          // disabled={values['err_address_information']}
                          type="submit"
                          variant="success"
                          color="primary"
                          style={{ width: "120px" }}
                          disabled={loading}
                        >
                          Next
                        </Button>
                      </div>
                    </Form>
                    {loading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
