import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    createSipOrder,
    uploadMandate,
    registerMandate,
    loadBankAccountList,
    loadNachMandateForm,
    refreshMandateStatus,
    cancelMandate,
} from "../../actions/orders";
import { Redirect } from "react-router-dom";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
//import Spinner from "../../layout/spinner";
import { PDFReader } from "reactjs-pdf-reader";
// import { PDFViewer } from 'pdf-viewer-reactjs';
import {
    Table,
    Collapse,
    Modal,
    Form,
    Row,
    Col,
    Button,
    Label,
    Spinner
} from "react-bootstrap";
import Select from "react-select";
import { saveAs } from "file-saver";
import Popup from "../popup";

class MandateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_order: {},
            sip_order: {},
            list_bank_account: [],
            list_mandate_initial: [],
            list_mandate: [],
            list_pending_mandate: [],
            list_mandate_debit: [],
            src_nach_form: "",
            selected_mandate_id: "",
            is_mandate_selected: false,
            is_mandate_registered: false,
            is_mandate_uploaded: false,
            is_debit_max_limit: false,
            mandate_amount: 25000,
            mandate_id: "",
            mandate_date_validation: {
                message_text: "",
            },
            is_first_order_today: false,
            loading: false,
            is_nach: true,
            nach_option: "existing",
            nach_option_new_processed: false,
            nach_option_new_processed_message_text: "",
            sip_information_text: "You need to make payment physically on ",
            render_pdf: null,
            nachmandateFile: null,
            src_nachmandateFile: null,
            byte_nachmandateFile: null,

            show_upload_popup: false,
            modal_upload_mandate: {},
            modal_nachmandateFile: null,
            src_modal_nachmandateFile: null,

            show_popup: false,
            popup_messageText: "",
            invest_more_amount: 0.0,
            redeemable_amount: 0.0,
            is_redeem_full_checked: false,
            is_stage_verified: false,
            is_verify_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            isloading: false,
        };
    }

    componentDidMount() {
        this.onLoadBankAccountList();
    }

    onLoadBankAccountList = () => {

        this.setState({ isloading: true });

        this.props
            .loadBankAccountList({
                sip_date: ''
            })
            .then((response) => {

                if (this.props.order_result["code"] !== "000") {
                    this.setState({ isloading: false });
                    alert(this.props.order_result["messageText"]);
                } else {
                    let _result = this.props.order_result["result"];

                    if (_result) {
                        let _customer_pending_mandate_list =
                            _result["customer_pending_mandate_list"];
                        let _nach_form = _result["mandate_nach_list"].filter((el) => {
                            return (
                                el["mandate_id"] ===
                                _customer_pending_mandate_list[0]["mandate_id"]
                            );
                        });

                        let setStateObject = {
                            isloading: false,
                            list_bank_account: _result['customer_bank_list'],
                            list_mandate_initial: _result["customer_mandate_list"],
                            list_mandate: _result["customer_mandate_list"],
                            list_pending_mandate: _customer_pending_mandate_list,
                            list_mandate_debit: _result["customer_mandate_debit_list"],
                            mandate_date_validation: _result["mandate_date_validation"],
                            is_mandate_registered:
                                _customer_pending_mandate_list.filter((obj) => {
                                    return obj.is_selected === true;
                                }).length > 0,
                            src_nach_form:
                                _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                            selected_mandate_id:
                                _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",
                        };

                        this.setState(setStateObject);
                    } else
                        this.setState({
                            list_mandate: [],
                            list_mandate_initial: [],
                            list_pending_mandate: [],
                            list_mandate_debit: [],
                            mandate_date_validation: {
                                message_text: "",
                            },
                            is_mandate_registered: false,
                            src_nach_form: "",
                            selected_mandate_id: "",
                        });
                }
            })
            .catch((err) => {
                console.log("line 146 :");
                console.log(err);
                alert(err);
            });
    };

    onloadNachMandateForm = () => {

        this.setState({ isloading: true });
        this.props
            .loadNachMandateForm(this.state.selected_order)
            .then((response) => {
                this.setState({ isloading: false });
                if (this.props.order_result["code"] !== "000")
                    alert(this.props.order_result["messageText"]);
                else {
                    if (this.props.order_result["render_pdf"])
                        this.setState({
                            render_pdf: atob(this.props.order_result["pdf_base64"]),
                        });
                }
            })
            .catch((err) => {
                console.log("line 168 :");
                console.log(err);
                alert(err);
            });
    };

    refreshMandateStatus = (mandate_id) => {

        this.setState({ isloading: true });
        this.props
            .refreshMandateStatus(
                Object.assign(this.state.selected_order, { mandate_id: mandate_id })
            )
            .then((response) => {
                this.setState({ isloading: false });
                if (this.props.order_result["code"] !== "000")
                    alert(this.props.order_result["messageText"]);
                else {
                    if (this.props.order_result["render_pdf"])
                        this.setState({
                            render_pdf: atob(this.props.order_result["pdf_base64"]),
                        });
                    else {

                        let _result = this.props.order_result["result"];

                        if (_result && _result["status"]
                        ) {

                            let _list = this.state.list_mandate_initial;

                            _list.forEach((el) => {

                                if (el["mandate_id"] === _result["mandateId"]) {

                                    el["is_processed"] = _result["is_processed"];
                                    el["is_reupload"] = _result["is_reupload"];

                                    el["status"] = _result["status"];

                                }

                            });

                            console.log(_list);

                            this.setState({
                                list_mandate_initial: _list,
                                list_mandate: _list
                            });

                        }
                    }
                }
            })
            .catch((err) => {
                console.log("line 168 :");
                console.log(err);
                alert(err);
            });
    };

    onRegisterMandate = () => {

        this.setState({ isloading: true });

        let _bank_information =
            this.state.list_bank_account.filter((el) => {
                return el["is_primary"] === true;
            })[0] || {};

        this.props
            .registerMandate({
                client_info: {
                    account_no: _bank_information["beneficiary_account_no"],
                    account_type: _bank_information["beneficiary_account_type"],
                    ifsc_code: _bank_information["beneficiary_ifsc"],
                    account_holder_name:
                        _bank_information["beneficiary_account_holder_name"],
                    debit_amount: this.state.mandate_amount,
                    request_mode: "web",
                    mandate_type: "X",
                },
            })
            .then((response) => {

                this.setState({ isloading: false });

                if (this.props.order_result["code"] !== "000")
                    alert(this.props.order_result["messageText"]);
                else {
                    //   if(this.props.order_result['state_parameters'])
                    //       this.setState(this.props.order_result['state_parameters']);
                    let _result = this.props.order_result["result"];

                    if (_result) {
                        let _customer_pending_mandate_list =
                            _result["customer_pending_mandate_list"];
                        let _nach_form = _result["mandate_nach_list"].filter((el) => {
                            return (
                                el["mandate_id"] ===
                                _customer_pending_mandate_list[0]["mandate_id"]
                            );
                        });

                        this.setState({
                            list_mandate: _result["customer_mandate_list"],
                            list_pending_mandate: _customer_pending_mandate_list,
                            list_mandate_debit: _result["customer_mandate_debit_list"],
                            mandate_date_validation: _result["mandate_date_validation"],
                            is_mandate_registered:
                                _customer_pending_mandate_list.filter((obj) => {
                                    return obj.is_selected === true;
                                }).length > 0,
                            src_nach_form:
                                _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                            selected_mandate_id:
                                _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",
                        });
                    }
                }
            })
            .catch((err) => {
                console.log("line 303 :");
                console.log(err);
                alert(err);
            });
    };

    onUploadMandate = (request_from) => {

        this.setState({ isloading: true });
        let formData = new FormData();

        let _nachmandateFile = (request_from === 'modal' ?
            this.state.modal_nachmandateFile : this.state.nachmandateFile);

        if (_nachmandateFile) {

            formData.append("upload_file", _nachmandateFile);
            formData.append("id_type", "scanmandate");
            formData.append("mandateId", this.state.selected_mandate_id);
            formData.append("mandateType", "XSIP");
            formData.append("imageName", _nachmandateFile.name);
            formData.append("imageType", _nachmandateFile.type);
            // formData.append("pFileBytes", this.state.byte_nachmandateFile);

            this.props
                .uploadMandate(formData)
                .then((response) => {

                    this.setState({ isloading: false });
                    if (this.props.order_result["code"] !== "000")
                        alert(this.props.order_result["messageText"]);
                    else {
                        if (this.props.order_result["state_parameters"]) {
                            alert(
                                this.props.order_result["state_parameters"][
                                "nach_option_new_processed_message_text"
                                ]
                            );
                            this.setState(this.props.order_result["state_parameters"]);
                        }
                        this.onLoadBankAccountList();
                    }
                })
                .catch((err) => {
                    console.log("line 340 :");
                    console.log(err);
                    alert(err);
                });
        }
    };

    onCancelMandate = (mandate_id) => {

        this.setState({ isloading: true });
        this.props
            .cancelMandate(
                Object.assign(this.state.selected_order, { mandate_id: mandate_id })
            )
            .then((response) => {

                this.setState({ isloading: false });

                let _result = this.props.order_result["result"];

                if (this.props.order_result["code"] !== "000")
                    alert(this.props.order_result["messageText"]);
                else {
                    if (this.props.order_result["render_pdf"])
                        this.setState({
                            render_pdf: atob(this.props.order_result["pdf_base64"]),
                        });
                    else {

                        if (_result && _result["customer_mandate_list"]) {
                            let _customer_pending_mandate_list =
                                _result["customer_pending_mandate_list"];
                            let _nach_form = (_result["mandate_nach_list"] || []).filter((el) => {
                                return (
                                    el["mandate_id"] ===
                                    _customer_pending_mandate_list[0]["mandate_id"]
                                );
                            });

                            let setStateObject = {
                                list_mandate: _result["customer_mandate_list"],
                                list_pending_mandate: _customer_pending_mandate_list,
                                is_mandate_registered:
                                    _customer_pending_mandate_list.filter((obj) => {
                                        return obj.is_selected === true;
                                    }).length > 0,
                                src_nach_form:
                                    _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                                selected_mandate_id:
                                    _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",
                            };

                            this.setState(setStateObject);
                        }


                    }
                }
            })
            .catch((err) => {
                console.log("line 168 :");
                console.log(err);
                alert(err);
            });
    };

    handleMandateCheck = (e, item) => {

        this.setState({ isloading: true });

        const _list = this.state.list_mandate_initial;

        _list.forEach((el) => {
            el["is_selected"] = false;

            if (el["mandate_id"] === item["mandate_id"])
                el["is_selected"] = e.target.checked;
        });

        let _selected_mandate = _list.filter((el) => {
            return el["is_selected"] === true;
        });
        this.setState({ isloading: false });

        this.setState({
            list_mandate: _selected_mandate.length > 0 ? _selected_mandate : _list,
            is_mandate_selected: _selected_mandate.length > 0,
            selected_mandate_id:
                _selected_mandate.length > 0 ? _selected_mandate[0]["mandate_id"] : "",
        });
    };

    handleMandateIdSelect = (item) => {
        this.setState({ isloading: true });
        const _list = this.state.list_pending_mandate;
        _list.forEach((el) => {
            if (el["mandate_id"] === item.value) item["is_selected"] = !item.value;
            else item["is_selected"] = false;
        });
        this.setState({ isloading: false });
        this.setState({
            selected_mandate_id: item.value,
            list_pending_mandate: _list,
            is_mandate_selected:
                _list.filter((el) => {
                    return el["is_selected"] === true;
                }).length > 0,
        });
    };

    handleInvestAmount(event, attribute) {
        const re = /^\d+(\.\d{0,3})?$/; ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            this.setState({
        /*invest_more_amount*/[attribute]: event.target.value.replace(
                /\b0+/g,
                ""
            ),
            });
        }
    }

    changeHandler = async (event) => {

        if (
            event.target.files[0] &&
            (event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/jpeg" ||
                event.target.files[0].type === "image/jpg")
        ) {
            {
                const file = event.target.files[0];
                const buffer = await file.arrayBuffer();

                let byteArray = new Int8Array(buffer);
                this.setState({
                    nachmandateFile: file,
                    byte_nachmandateFile: byteArray,
                    src_nachmandateFile: URL.createObjectURL(file),
                });
            }
        } else {
            this.setState({
                show_popup: true,
                popup_messageText:
                    "Upload file format should be of PNG, JPEG, JPG only",
            });
        }
    };

    changeHandlerModal = async (event) => {

        if (
            event.target.files[0] &&
            (event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/jpeg" ||
                event.target.files[0].type === "image/jpg")
        ) {
            {
                const file = event.target.files[0];
                const buffer = await file.arrayBuffer();

                let byteArray = new Int8Array(buffer);
                this.setState({
                    modal_nachmandateFile: file,
                    src_modal_nachmandateFile: URL.createObjectURL(file),
                });
            }
        } else {
            this.setState({
                show_popup: true,
                popup_messageText:
                    "Upload file format should be of PNG, JPEG, JPG only",
            });
        }
    };

    handleRadioChange = (value, attribute) => {
        // console.log(event);
        this.setState({ [attribute]: value });
    };

    base64toBlob = (data, pdfContentType) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr(
            `data:${pdfContentType};base64,`.length
        );

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        let _blob = new Blob([out], { type: pdfContentType });

        return URL.createObjectURL(_blob);
        // return new Blob([out], { type: pdfContentType });
    };

    async downloadNachForm() {
        if (this.state.src_nach_form && this.state.src_nach_form.length > 255) {
            const byteCharacters = atob(this.state.src_nach_form);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // const { data } = await getTicketsPdf()
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, this.state.selected_mandate_id + ".pdf");
        }
    }

    closePopup = () => {
        this.setState({
            show_popup: false,
        });
    };

    fnDateOrdinalformat(date, tmp) {
        return [
            (tmp = date.getDate()) +
            ([, 'st', 'nd', 'rd'][/1?.$/.exec(tmp)] || 'th'),
            ['January', 'February', 'March', 'April',
                'May', 'June', 'July', 'August',
                'September', 'October', 'November', 'December'
            ][date.getMonth()],
            date.getFullYear()
        ].join(' ')
    }

    render() {

        // if(this.props.loading)
        //   return <Spinner />;

        // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
        //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

        return (
            <>
                <Header1 />
                <Sidebar />

                <div className="content-body" style={{ marginTop: 105 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    {this.state.isloading && (
                                        <Spinner
                                            animation="border"
                                            variant="dark"
                                            style={{ position: "fixed", top: "50%", left: "50%" }}
                                        />
                                    )}
                                    <div className="card-body" style={{ marginLeft: 20 }}>

                                        <div >

                                            <p style={{ fontSize: 14 }}>Primary Bank Account Information</p>

                                            <ul className="linked_account">
                                                {(this.state.list_bank_account || []).map(
                                                    (item, index) => (
                                                        // <div>
                                                        <li key={index}>
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <div className="media">
                                                                        <div className="media-body">
                                                                            <p>
                                                                                <span className="mr-3">
                                                                                    <i className="fa fa-bank"></i>
                                                                                </span>
                                                                                {item["beneficiary_account_no"]} (
                                                                                {item["beneficiary_account_type"]}
                                                                                )
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <p>

                                                                        {item[
                                                                            "beneficiary_account_holder_name"
                                                                        ] || ""}
                                                                        {` `}
                                                                        <span>
                                                                            <i className="la la-check"></i>
                                                                        </span>
                                                                        <Link to={"#"}>Verified</Link>
                                                                    </p>
                                                                </div>
                                                                <div class="col-2">
                                                                    IFSC :{" "}
                                                                    {item["beneficiary_ifsc"]}
                                                                </div>
                                                                <div class="col-3">
                                                                    <p>

                                                                        {item[
                                                                            "beneficiary_bank_branch_name"
                                                                        ] || ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        // </div>
                                                    )
                                                )}
                                            </ul>

                                        </div>
                                        <hr />
                                        {/* <div>
                    <div> */}

                                        <div>
                                            {/* <div className="col-xl-12 col-md-8"> */}

                                            <div>
                                                <p >
                                                    {this.state.nach_option ===
                                                        "existing" && (this.state.list_mandate || []).length === 0 &&
                                                        <span style={{
                                                            width: 400,
                                                            fontSize: 12,
                                                            color: 'InfoText',
                                                            backgroundColor: '#d9b998', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                                        }}>
                                                            Your mandate list is empty. Kindly register new mandate.
                                                        </span>
                                                    }
                                                </p>
                                                {/* <div className="card-body"> */}
                                                <div className="form">
                                                    {
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Col sm={12}>

                                                                {this.state.is_nach && (
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <Form.Check
                                                                                type="radio"
                                                                                label="Choose existing mandate"
                                                                                name="nach_option"
                                                                                id="existing"
                                                                                value="existing"
                                                                                checked={
                                                                                    this.state.nach_option ===
                                                                                    "existing"
                                                                                }
                                                                                onChange={(e) =>
                                                                                    this.handleRadioChange(
                                                                                        "existing",
                                                                                        "nach_option"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <Form.Check
                                                                                type="radio"
                                                                                label="Register new mandate"
                                                                                name="nach_option"
                                                                                id="new"
                                                                                value="new"
                                                                                checked={
                                                                                    this.state.nach_option === "new"
                                                                                }
                                                                                onChange={(e) =>
                                                                                    this.handleRadioChange(
                                                                                        "new",
                                                                                        "nach_option"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <label></label>
                                                                        </div>
                                                                        {this.state.nach_option ===
                                                                            "existing" && (this.state.list_mandate || []).length > 0 &&
                                                                            (
                                                                                <table class="table mb-0 table-responsive-sm bordered">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th colSpan="1" style={{ fontSize: 12 }}>#</th>
                                                                                            <th colSpan="6" style={{ fontSize: 12 }}>Mandate Id</th>
                                                                                            <th colSpan="3" style={{ fontSize: 12 }}>Debit Limit</th>
                                                                                            <th colSpan="3" style={{ fontSize: 12 }}>Bank Info</th>
                                                                                            <th colSpan="3" style={{ fontSize: 12 }}>Validity</th>
                                                                                            {/* <th colSpan="3" style={{ fontSize: 12 }}>Select</th> */}
                                                                                            <th colSpan="6" style={{ fontSize: 12 }}>Status</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    {(
                                                                                        this.state.list_mandate || []
                                                                                    ).map((item, index) => (
                                                                                        <tbody key={index}>
                                                                                            <tr>
                                                                                                <td colSpan="1" style={{ fontSize: 12, color: "#000" }}>{index + 1}</td>
                                                                                                <td colSpan="6" style={{ fontSize: 12, color: "#000" }}>
                                                                                                    {item["mandate_id"]} 
                                                                                                    {item['is_reupload'] && !item['is_processed'] &&
                                                                                                        <p>

                                                                                                            <Button className="text-success"
                                                                                                                variant="link"
                                                                                                                style={{ marginLeft: -30 }}
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        show_upload_popup: true,
                                                                                                                        modal_upload_mandate: item
                                                                                                                    })
                                                                                                                    // window.scrollTo(0, document.body.scrollHeight - 300);
                                                                                                                }}>
                                                                                                                Re-Upload </Button>
                                                                                                        </p>
                                                                                                    }
                                                                                                </td>
                                                                                                <td colSpan="3" style={{ fontFamily: 'sans-serif', fontSize: 12, color: "#000" }}>
                                                                                                    &#8377;{item["amount"] || `-`}
                                                                                                </td>
                                                                                                <td colSpan="3" style={{ fontSize: 12, color: "#000" }}>
                                                                                                    {item["account_no"] || `-`} ({item["account_type"] || `-`})
                                                                                                    <p>
                                                                                                        {item["ifsc_code"] || `-`}
                                                                                                    </p>
                                                                                                </td>
                                                                                                <td colSpan="3" style={{ fontSize: 12, color: "#000" }}>
                                                                                                    {item["start_date"]} - {item["end_date"]}
                                                                                                </td>

                                                                                                <td colSpan="6" style={{ fontSize: 12, color: "#000" }}>
                                                                                                    <span>
                                                                                                        {item["status"] ||
                                                                                                            "Under process for bank approval"}
                                                                                                    </span>
                                                                                                    {!item[
                                                                                                        "is_cancelled"
                                                                                                    ] && !item[
                                                                                                    "is_processed"
                                                                                                    ] && (
                                                                                                            <div>
                                                                                                                {item[
                                                                                                                    "mandate_id"
                                                                                                                ] && (
                                                                                                                        <Button
                                                                                                                            className="btn btn-success btn-sm"
                                                                                                                            variant="success"
                                                                                                                            onClick={(e) =>
                                                                                                                                this.refreshMandateStatus(
                                                                                                                                    item[
                                                                                                                                    "mandate_id"
                                                                                                                                    ]
                                                                                                                                )
                                                                                                                            }
                                                                                                                            disabled={this.state.isloading}
                                                                                                                        >
                                                                                                                            Refresh
                                                                                                                        </Button>
                                                                                                                    )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>
                                                                                    ))}
                                                                                </table>
                                                                            )}

                                                                        {(this.state.nach_option === "new" ||
                                                                            this.state.nach_option ===
                                                                            "existing") &&
                                                                            (!this.state.is_mandate_selected ||
                                                                                this.state.nach_option ===
                                                                                "new") && (
                                                                                <div class="row">
                                                                                    {
                                                                                        //this.state.is_mandate_registered &&
                                                                                        <div>

                                                                                            <div>
                                                                                                {this.state
                                                                                                    .nach_option ===
                                                                                                    "new" && (
                                                                                                        <div class="row">
                                                                                                            <div class="col-3">
                                                                                                                {" "}
                                                                                                                Debit Amount Limit
                                                                                                                <Select
                                                                                                                    value={this.state.list_mandate_debit.find(
                                                                                                                        (obj) =>
                                                                                                                            obj.value.toString() ===
                                                                                                                            this.state.mandate_amount.toString()
                                                                                                                    )}
                                                                                                                    onChange={(e) =>
                                                                                                                        this.setState({
                                                                                                                            mandate_amount:
                                                                                                                                e.value,
                                                                                                                        })
                                                                                                                    }
                                                                                                                    options={
                                                                                                                        this.state
                                                                                                                            .list_mandate_debit
                                                                                                                    }
                                                                                                                    isDisabled={
                                                                                                                        this.state
                                                                                                                            .is_mandate_registered
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div class="col-3">
                                                                                                                <br />
                                                                                                                {this.state.list_pending_mandate.filter(
                                                                                                                    (obj) => {
                                                                                                                        return (
                                                                                                                            obj.is_selected ===
                                                                                                                            true
                                                                                                                        );
                                                                                                                    }
                                                                                                                ).length === 0 &&
                                                                                                                    this.state
                                                                                                                        .nach_option ===
                                                                                                                    "new" && (
                                                                                                                        <Button
                                                                                                                            className="btn btn-success btn-block"
                                                                                                                            variant="success"
                                                                                                                            disabled={
                                                                                                                                (this.state
                                                                                                                                    .mandate_date_validation[
                                                                                                                                    "message_text"
                                                                                                                                ].length > 15 &&
                                                                                                                                    !this.state
                                                                                                                                        .is_first_order_today) ||
                                                                                                                                this.state.isloading
                                                                                                                            }
                                                                                                                            onClick={
                                                                                                                                this
                                                                                                                                    .onRegisterMandate
                                                                                                                            }
                                                                                                                        >
                                                                                                                            Register Mandate
                                                                                                                        </Button>
                                                                                                                    )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                {this.state
                                                                                                    .nach_option ===
                                                                                                    "new" &&
                                                                                                    this.state
                                                                                                        .list_pending_mandate
                                                                                                        .length > 0 && (
                                                                                                        <div class="col-4">
                                                                                                            <div>
                                                                                                                {" "}
                                                                                                                Choose Mandate Id
                                                                                                                <Select
                                                                                                                    value={this.state.list_pending_mandate.find(
                                                                                                                        (obj) =>
                                                                                                                            obj.is_selected ===
                                                                                                                            true
                                                                                                                    )}
                                                                                                                    onChange={(e) =>
                                                                                                                        this.handleMandateIdSelect(
                                                                                                                            e
                                                                                                                        )
                                                                                                                    }
                                                                                                                    options={
                                                                                                                        this.state
                                                                                                                            .list_pending_mandate
                                                                                                                    }
                                                                                                                    isDisabled={
                                                                                                                        this.state
                                                                                                                            .nach_option_new_processed
                                                                                                                    }
                                                                                                                    isSearchable={
                                                                                                                        false
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>

                                                                                            {this.state
                                                                                                .nach_option ===
                                                                                                "new" &&
                                                                                                <div>
                                                                                                    <div class="col">
                                                                                                        <h6>
                                                                                                            {/*  */}
                                                                                                            <p></p>
                                                                                                            Total amount limit is
                                                                                                            not actual debit
                                                                                                            amount and it is upper
                                                                                                            limit where multiple
                                                                                                            debit transactions
                                                                                                            done from different
                                                                                                            sip orders. You are
                                                                                                            suggested to keep a
                                                                                                            higher Mandate amount
                                                                                                            so that with it any
                                                                                                            amount of transactions
                                                                                                            can be placed easily,
                                                                                                            without the need for
                                                                                                            registering a new
                                                                                                            mandate/increasing
                                                                                                            mandate limit You can
                                                                                                            use same mandate id
                                                                                                            for your future sip
                                                                                                            investments
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                            <div class="row">
                                                                                                <div class="col">
                                                                                                    <Form.Group
                                                                                                        className="mb-3"
                                                                                                        controlId="formBasicCheckbox"
                                                                                                    >
                                                                                                        {this.state
                                                                                                            .mandate_date_validation[
                                                                                                            "message_text"
                                                                                                        ].length > 15 && (
                                                                                                                <div>
                                                                                                                    <p
                                                                                                                        style={{
                                                                                                                            color: "red",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            this.state
                                                                                                                                .mandate_date_validation[
                                                                                                                            "message_text"
                                                                                                                            ]
                                                                                                                        }
                                                                                                                    </p>

                                                                                                                    <Form.Check
                                                                                                                        type="checkbox"
                                                                                                                        label="Is First Order Today"
                                                                                                                        onChange={(e) =>
                                                                                                                            this.setState(
                                                                                                                                {
                                                                                                                                    is_first_order_today:
                                                                                                                                        !this
                                                                                                                                            .state
                                                                                                                                            .is_first_order_today,
                                                                                                                                }
                                                                                                                            )
                                                                                                                        }
                                                                                                                        defaultChecked={
                                                                                                                            this.state
                                                                                                                                .is_first_order_today
                                                                                                                        }
                                                                                                                        disabled={true}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </Form.Group>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }

                                                                                    {this.state
                                                                                        .is_mandate_registered &&
                                                                                        !this.state
                                                                                            .nach_option_new_processed && (
                                                                                            <div class="col">

                                                                                                <hr />
                                                                                                <div class="row">

                                                                                                    <div class="col-6">
                                                                                                        <div className="text-center">
                                                                                                            {
                                                                                                                this.state
                                                                                                                    .src_nach_form
                                                                                                                    .length > 255 && (
                                                                                                                    <PDFReader
                                                                                                                        data={atob(
                                                                                                                            this.state
                                                                                                                                .src_nach_form
                                                                                                                        )}
                                                                                                                        width="450"
                                                                                                                    />
                                                                                                                )
                                                                                                                // <PDFViewer document={{ base64: this.state.src_nach_form }} />
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-6">

                                                                                                        <div class="row">

                                                                                                            <div class="col-5">
                                                                                                                <Button
                                                                                                                    className="btn btn-success btn-block"
                                                                                                                    variant="success"
                                                                                                                    onClick={() =>
                                                                                                                        this.downloadNachForm()
                                                                                                                    }
                                                                                                                >
                                                                                                                    Download Mandate
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                            <div class="col-1">
                                                                                                            </div>
                                                                                                            <div class="col-5">
                                                                                                                <Button
                                                                                                                    className="btn btn-success btn-block"
                                                                                                                    variant="success"
                                                                                                                    onClick={(e) =>
                                                                                                                        this.onCancelMandate(
                                                                                                                            this.state
                                                                                                                                .list_pending_mandate[0][
                                                                                                                            "mandate_id"
                                                                                                                            ]
                                                                                                                        )
                                                                                                                    }
                                                                                                                    disabled={this.state.isloading}
                                                                                                                >
                                                                                                                    Cancel Mandate
                                                                                                                </Button>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                        <div class="row">

                                                                                                            <p
                                                                                                                style={{
                                                                                                                    textAlign: "left",
                                                                                                                }}
                                                                                                            >
                                                                                                                <b>Step 1</b>. Download <b>Step 2</b>. Put your signature <b>Step 3</b>. Scan it(png/jpeg format)

                                                                                                            </p>
                                                                                                            <p
                                                                                                                style={{
                                                                                                                    textAlign: "left",
                                                                                                                }}>
                                                                                                                Upload without changing the file name to complete the
                                                                                                                registration.
                                                                                                            </p>
                                                                                                            <p
                                                                                                                style={{
                                                                                                                    textAlign: "left",
                                                                                                                }}
                                                                                                            >
                                                                                                                For eg. file name
                                                                                                                should be strictly
                                                                                                                as
                                                                                                                <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>{ //this.state.selected_mandate_id +
                                                                                                                    ` ` + this.state
                                                                                                                        .list_pending_mandate[0]["mandate_id"]
                                                                                                                    + `.png`}</span>
                                                                                                                {` `}
                                                                                                                or
                                                                                                                <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                                                                                    {` ` + this.state
                                                                                                                        .list_pending_mandate[0]["mandate_id"] +
                                                                                                                        ".jpeg"}{` `}
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <Form.Control
                                                                                                                type="file"
                                                                                                                onChange={
                                                                                                                    this.changeHandler
                                                                                                                }
                                                                                                            />

                                                                                                        </div>

                                                                                                        {this.state
                                                                                                            .src_nachmandateFile && (
                                                                                                                <div class="row">
                                                                                                                    <div class="col-4">
                                                                                                                        <img
                                                                                                                            width="150"
                                                                                                                            src={
                                                                                                                                this.state[
                                                                                                                                "src_nachmandateFile"
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div class="col-4">
                                                                                                                        <br />
                                                                                                                        <Button
                                                                                                                            className="btn btn-success btn-sm"
                                                                                                                            variant="success"
                                                                                                                            onClick={() => {
                                                                                                                                this.onUploadMandate('main');
                                                                                                                            }
                                                                                                                            }
                                                                                                                            disabled={this.state.isloading}
                                                                                                                        >
                                                                                                                            Upload Mandate
                                                                                                                        </Button>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            )}

                                                                                                        {this.state
                                                                                                            .show_popup && (
                                                                                                                <Modal
                                                                                                                    show={
                                                                                                                        this.state
                                                                                                                            .show_popup
                                                                                                                    }
                                                                                                                    onHide={() =>
                                                                                                                        this.closePopup()
                                                                                                                    }
                                                                                                                >
                                                                                                                    <Modal.Header></Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <p>
                                                                                                                            {
                                                                                                                                this.state.popup_messageText
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        <Button
                                                                                                                            variant="primary"
                                                                                                                            onClick={() =>
                                                                                                                                this.closePopup()
                                                                                                                            }
                                                                                                                        >
                                                                                                                            Close
                                                                                                                        </Button>
                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>
                                                                                                            )}
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Form.Group>
                                                    }
                                                </div>
                                                {/* </div> */}
                                            </div>

                                            {this.state.render_pdf !== null && (
                                                <PDFReader
                                                    data={this.state.render_pdf}
                                                    width="100px"
                                                    height="100px"
                                                />
                                            )}

                                            {((this.state.nach_option === "existing" &&
                                                this.state.is_mandate_selected) ||
                                                (this.state.nach_option === "new" &&
                                                    this.state.nach_option_new_processed)) && (
                                                    <div class="row" style={{ margin: 20 }}>
                                                        <div class="col-lg-12">
                                                            {/* {this.state.nach_option === "new" && ( */}
                                                            <h5 className="card-title">
                                                                {
                                                                    this.state
                                                                        .nach_option_new_processed_message_text
                                                                }
                                                            </h5>
                                                            {/* )} */}


                                                        </div>
                                                    </div>
                                                )}

                                            {/* </div> */}
                                        </div>

                                        {/* </div>
                  </div> */}

                                    </div>



                                    <Modal
                                        show={
                                            this.state
                                                .show_upload_popup
                                        }
                                        onHide={() => {
                                            this.setState({
                                                modal_upload_mandate: {},
                                                show_upload_popup: false
                                            });
                                        }
                                        }
                                    >
                                        <Modal.Header></Modal.Header>
                                        <Modal.Body>
                                            {
                                                this.state
                                                    .modal_upload_mandate['mandate_id'] &&
                                                <div class="row">

                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <b>Step 1</b>. Download Mandate
                                                        <br />
                                                        <b>Step 2</b>. Put your signature
                                                        <br />
                                                        <b>Step 3</b>. Scan it(png/jpeg format)

                                                    </p>
                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}>
                                                        Upload without changing the file name to complete the
                                                        registration.
                                                    </p>
                                                    <p
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        For eg. file name
                                                        should be strictly
                                                        as
                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>{ //this.state.selected_mandate_id +
                                                            ` ` + this.state.modal_upload_mandate['mandate_id'] + `.png`}</span>
                                                        {` `}
                                                        or
                                                        <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                                                            {` ` + this.state.modal_upload_mandate['mandate_id'] + ".jpeg"}{` `}
                                                        </span>
                                                    </p>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={
                                                            this.changeHandlerModal
                                                        }
                                                    />

                                                </div>
                                            }

                                            {this.state
                                                .src_modal_nachmandateFile && (
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <img
                                                                width="150"
                                                                src={
                                                                    this.state[
                                                                    "src_modal_nachmandateFile"
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-5">
                                                            <br />
                                                            <Button
                                                                className="btn btn-success btn-sm"
                                                                variant="success"
                                                                onClick={() => {
                                                                    this.onUploadMandate('modal');
                                                                }
                                                                }
                                                                disabled={this.state.isloading}
                                                            >
                                                                Upload Mandate
                                                            </Button>
                                                        </div>

                                                    </div>
                                                )}

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    this.setState({
                                                        modal_upload_mandate: {},
                                                        show_upload_popup: false
                                                    });
                                                }
                                                }
                                            >
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
// OrderCheckout.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
    const { loading, order_result } = order;
    return {
        loading,
        order_result,
    };
};

export default connect(mapStateToProps, {
    createSipOrder,
    uploadMandate,
    registerMandate,
    loadBankAccountList,
    loadNachMandateForm,
    refreshMandateStatus,
    cancelMandate,
})(MandateList);
