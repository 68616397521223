import api from '../utils/api';
import { setAlert } from './alert';
import {
  ORDER_LIST_LOAD,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL
} from './types';
import { API_END_POINTS } from '../constants/constants';

export const loadOrderList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: ORDER_LIST_LOAD
    });

    let res = await api.post(API_END_POINTS.LIST_ORDERS, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const loadBankAccountList = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.LIST_BANKACCOUNT, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const refreshMandateStatus = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: ORDER_LIST_LOAD
    });

    let res = await api.post(API_END_POINTS.STATUS_NACH_MANDATE, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const cancelMandate = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: ORDER_LIST_LOAD
    });

    let res = await api.post(API_END_POINTS.CANCEL_NACH_MANDATE, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const getOrderStatement = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: ORDER_LIST_LOAD
    });

    let res = await api.post(API_END_POINTS.GET_ORDER_STATEMENT, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};



export const loadNachMandateForm = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: ORDER_LIST_LOAD
    });

    let res = await api.post(API_END_POINTS.LOAD_NACH_MANDATE, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const loadSipCheckout = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: ORDER_LIST_LOAD
    });

    let res = await api.post(API_END_POINTS.LIST_ORDERS, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const loadPaymentResponse = (body_parameters) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    dispatch({
      type: ORDER_LIST_LOAD
    });

    let res = await api.post(API_END_POINTS.PAYMENT_RESPONSE, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const registerMandate = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.put(API_END_POINTS.REGISTER_NACH_MANDATE, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    // callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const uploadMandate = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    console.log(body_parameters)
    let res = await api.post(API_END_POINTS.UPLOAD_NACH_MANDATE, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    // callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const createOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.put(API_END_POINTS.CREATE_SINGLE_ORDER, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    // callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const additionalOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.ADDITIONAL_SINGLE_ORDER, body);
    console.log(res);
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    //callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const modifyOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.MODIFY_SINGLE_ORDER, body);
    console.log(res);
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    //callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const cancelOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.CANCEL_SINGLE_ORDER, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    // callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const redeem2FactorOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.SEND_2FACTOR_OTP /*REDEEM_2FACTOR_ORDER*/, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    //callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const createSipOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    console.log(body_parameters);
    let res = await api.put(body_parameters['client_info']['sip_type'].toLowerCase() === 'xsip' ? 
      API_END_POINTS.CREATE_XSIP_ORDER : API_END_POINTS.CREATE_SIP_ORDER, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    // callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const cancelSipOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(body_parameters['order_type'] === 'sip' ? 
       API_END_POINTS.CANCEL_SIP_ORDER : API_END_POINTS.CANCEL_XSIP_ORDER, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    //callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const pauseSipOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(body_parameters['order_type'] === 'sip' ? 
       API_END_POINTS.PAUSE_SIP_ORDER : API_END_POINTS.PAUSE_XSIP_ORDER, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    //callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const createSwpOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.put(API_END_POINTS.CREATE_SWP_ORDER, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    // callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

export const cancelSwpOrder = (body_parameters, callbackFunction) => async dispatch => {
   
  const body = body_parameters;

  try {
    
    let res = await api.post(API_END_POINTS.CANCEL_SWP_ORDER, body);
    
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: res.data
    });
    
    // callbackFunction(res['data'])
    
  } catch (err) {

    console.log(err)
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ORDER_LIST_FAIL
    });

  }
};

