import React, { } from 'react';
import { loadPaymentResponse } from '../../actions/orders';
import { connect } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class SipResponse extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            selected_order: {},
            is_status_loaded: false,

            isloading: this.props.loading,
            notification_html: `<html><head><title>Your Sip Order Response</title>
            <style>
                .bodytxt4 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 12px;font-weight: bold;color: #666666;}
                .bodytxt {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 13px;font-weight: normal;color: #000000;}
                .bullet1 {list-style-type:square;list-style-position: inside;list-style-image: none;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;color: #FF9900;}
                .bodytxt2 {font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 8pt;font-weight: normal;color: #333333;}A.sac2 {COLOR: #000000;font-family: Verdana, Arial, Helvetica, sans-serif;font-size: 10px;font-weight: bold;text-decoration: none;}A.sac2:visited {COLOR: #314D5A; TEXT-DECORATION: none}A.sac2:hover {COLOR: #FF9900; TEXT-DECORATION: underline}
            </style>
        </head>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">  
            <tr>
                <td align="left" valign="top">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                           <td align="center" valign="middle">
                               <table width="100%" border="0" cellspacing="0" cellpadding="0">   
                                    <tr>
                                        <td  align="center"></td>  
                                    </tr>  
                                    <tr>
                                        <td height="85" align="center"><br>
                                            <table width="80%" border="0" cellpadding="0" cellspacing="1" bgcolor="#CCCCCC">
                                                <tr>  
                                                    <td bgcolor="#CCCCCC"><table width="100%" border="0" cellpadding="6" cellspacing="0" bgcolor="#FFFFFF">  
                                                        <tr> 
                                                            <td colspan="2" align="left" valign="bottom"><span class="bodytxt4">We are confirming your sip order...</span></td>  
                                                        </tr>  
                                                        <tr valign="top"> 
                                                            <td colspan="2" align="left">
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tr>   
                                                                        <td width="87%" bgcolor="#cccccc" height="1" align="center"></td>
                                                                    </tr>
                                                                </table>
                                                            </td>  
                                                        </tr>  
                                                        <tr>
                                                            <td width="60%" align="left" valign="bottom"><table width="95%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                                <tr>   
                                                                    <td align="right" valign="top"></td>  
                                                                    <td class="bodytxt">&nbsp;</td>
                                                                </tr>
                                                            <tr>   
                                                                <td align="right" valign="top"><li class="bullet1"></li></td>  
                                                                <td class="bodytxt2" >Please do not press "Submit" button once again or the "Back" or "Refresh" buttons. </td>
                                                            </tr>  
                                            </table>
                                        </td>
                                        <td align="right" valign="bottom">
                                            <table width="80%" border="0" cellpadding="1" cellspacing="0" bgcolor="#FFFFFF">
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" bgcolor="#FFFFFF"></td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">   
                                                    <td align="right" valign="middle" bgcolor="#FFFFFF" class="bodytxt2">&nbsp;</td>
                                                </tr>
                                                <tr bgcolor="#FFFCF8">
                                                    <td align="right" bgcolor="#FFFFFF" class="bodytxt2" >&nbsp;</td>
                                                </tr>  
                                            </table>
                                        </td>  
                                    </tr>
                                </table>
                            </td>
                        </tr>  
                    </table>  
                </td>  
            </tr>
        </table>   
        
        </td></tr></table></td>  </tr>  </table>`
        };
        this.form = null
    }

    componentDidMount() {

        // this.setState({ isloading: false });

        if (this.props.location.state) {
            // this.setState({ selected_order: this.props.location.state.selected_order });
            let selected_order = this.props.location.state.selected_order;

            if (selected_order && (!selected_order['mf_child_order_information'] ||
                (!selected_order['mf_child_order_information']['orderNumber'] && selected_order['mf_order_number'].length < 5))) {

                this.props.loadPaymentResponse({
                    request_from: 'sipResponse',
                    request_mode: 'order_payment_status',
                    order_type: selected_order['mf_order_type'],
                    order_number: selected_order['mf_order_number'] || '',
                    selected_order: selected_order
                }).then(response => {

                    if (this.props.order_result['code'] !== '000') {
                        alert(this.props.order_result['messageText']);
                        this.setState({ isloading: false });
                    }
                    else {

                        if (this.props.order_result['result']['notification_html']) {
                            this.setState({
                                selected_order: selected_order,
                                isloading: false,
                                notification_html: this.props.order_result['result']['notification_html'],
                                is_status_loaded: true
                            });
                        }
                        window.history.replaceState(null, '');

                    }

                })
                .catch(err => {
                    alert(err)
                });

            }
            else {

                if (selected_order) {
                    this.setState({
                        notification_html: this.props.location.state['display_html'],
                        is_status_loaded: false
                    });
                }
            }

        }

    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    createDefaultMarkup() {
        return {
            __html: this.state.notification_html,
        }
    };

    gotoListPage = () => {

        let pageName = 'sipList';

        console.log(this.props);

        this.props.history.push({
            pathname: '/' + pageName + '/'
        });

    }

    render() {

        return (
            <>
                <Header1 />
                <Sidebar />

                <div className="content-body" style={{ marginTop: 100 }}>
                    <div className="container">

                    {!this.props.location.state &&
                        <div className="row">
                            <div className="col-lg-10" style={{ margin: 40 }}>
                                <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                    <CardContent  >
                                        <Stack direction="row">
                                            <Typography flex={1} whiteSpace="pre-line" >
                                                Sorry the information you are looking for could not be found
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    }

                        {this.props.location.state && 

                            <div className="row">
                                <div className="col-lg-12" style={{ margin: 40 }}>
                                    <div dangerouslySetInnerHTML={this.createDefaultMarkup()} />
                                </div>
                            </div>

                        }

                        {
                            this.state.is_status_loaded &&
                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-lg-2">
                                    <br />
                                </div>
                                <div className="col-lg-2">
                                    <div class="row">
                                        <Button
                                            className="btn btn-success btn-block"
                                            variant="success"
                                            onClick={this.gotoListPage}>
                                            Ok, Go to list
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </>
        );
    }

}


const mapStateToProps = ({ order }) => {
    const { loading, order_result } = order;
    return {
        loading,
        order_result,

    };
};
export default connect(mapStateToProps, { loadPaymentResponse })(SipResponse);