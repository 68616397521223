import React, { Component } from 'react'
import PersonalDetails from '../personaldetails/personaldetails'
import IncomeDetails from '../incomedetails/incomedetails';
import NomineeDetails from '../personaldetails/nomineedetails';
import Documentsupload from '../documentsupload/documentsupload';
import ConfirmApplicationDetails from '../personaldetails/ConfirmApplicationDetails';
import EsignkycVerification from '../esignkyc/esignkycVerification'

import {validate, validateincome, validatenominee, imageverification} from '../../utils/validate';
import ImageVerification from '../personaldetails/imageVerification';
import { connect } from "react-redux";
import { verifyAccount, verifyStage, loadStageInfo, updatePersonalInfo, verifyIdProof } from '../../actions/verify';
import { Button,Spinner,Modal } from "react-bootstrap";
class PersonalVerification extends Component {

  constructor(props) {

    super(props);
    this.state = {
      step: 1,
      pin : "",
      email:'',
      first_name : "",
      last_name : "",
      do_birth : "",
      gender : "",
      marital_status : "",
      age : 1,
      occupation: "",
      source_of_wealth: "",
      annual_income: "",
      errors:'',
      errorincome:'',
      errornominee:'',
      errorimage : '',
      is_income_outside_india: false,
      is_political_india: false,
      is_political_foreign: false,
      address_information: { 
        address: "",
        locality_or_post_office: "",
        district_or_city: "",
        city: '',
        state: "",
        pincode: ""
      },
      err_address_information: false,
      nominee_information : {
        "name": "",
        "relationship": "",
        "id_proof": "",
        "id_information": {},
        "poi": '',
        "attachment_id": '',
        "attachment_list": [],
      },

      is_stage_verified: false,
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_confirm_chk_checked: false,
      file_nominee: null,
      file_photograph: null,
      src_file_nominee: null,
      src_file_photograph: null,
      file_photograph_1: null,
      file_nominee_1: null,
      src_file_nominee_1: null,
      src_file_photograph_1: null,
      master_list: {},
      file_nominee_show_popup: false,
      file_nominee_popup_messagetext: 'Upload file format should be of PNG, JPEG, JPG only',
      loading:false,
      
    }

  }

  componentDidMount()
  {   
      this.onLoadStageInfo();
  }

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  // proceed to the next step
  nextStep = () => {

    const { step } = this.state;
    let validationerrors = validate(this.state);
    let incomevalidate = validateincome(this.state);
    let nomineevalidate = validatenominee(this.state);

    if(step === 1)
    {
    
      console.log(this.state);
      console.log( validationerrors);
      
      if(Object.keys(validationerrors).length > 0) {
        this.setState({errors: validationerrors});
      }
      else {
        let _body_parameters = this.state['address_information'];
        _body_parameters['update_attribute'] = 'address';
        _body_parameters['marital_status'] = this.state.marital_status;
        if(this.state.login_stage === 'personal')
           this.updatePersonalInfo(_body_parameters, step);

      }

    }
   
    else if((step) === 2) {
      
      if(Object.keys(incomevalidate).length > 0){
        this.setState({ errorincome: incomevalidate });
      }
      else {

        let _body_parameters = this.state;
        _body_parameters['update_attribute'] = 'income';
        
        if(this.state.login_stage === 'personal')
           this.updatePersonalInfo(_body_parameters, step);

      }
    }
    else if((step) === 3) {
      
      if(Object.keys(nomineevalidate).length > 0)
        this.setState({ errornominee: nomineevalidate });
      else
      {

        let _body_parameters = this.state;
        _body_parameters['update_attribute'] = 'nominee';
        if(this.state.login_stage === 'personal')
           this.updatePersonalInfo(_body_parameters, step);

      }
    }    
    else
      this.setState({ step: step + 1 });

  }

  // Handle fields change
  handleChange = input => e => {
    let errorvalues = validate(input);
    console.log(errorvalues)
    this.setState({ [input]:  e.target.value ,errors:errorvalues   });
  }

  handleInputChange = (attribute, input, pattern) => e => {
  
    // validate(input);
    // this.setState({ nominee_information:  {...this.state.nominee_information,[input]:e.target.value}  });
      if(pattern) {    
        
        const targetValue = e.target.value; //.replace(/\s/g, '');
        // alert(targetValue.match(/^[0-9a-zA-Z]+$/))
        const re = /^[A-Za-z]+$/;
        if (targetValue === "" || re.test(targetValue))
          this.setState({ [attribute]:  {...this.state[attribute], [input]: targetValue}  });
        
      }
      else
        this.setState({ [attribute]:  {...this.state[attribute], [input]:e.target.value}  });

  }

  handleObjectInputChange = (attribute, input, pattern) => e => {
this.setState({loading:true})
    if(pattern) {    
      
      const targetValue = e.target.value; //.replace(/\s/g, '');
      this.setState({ ['err_' + attribute]:  !pattern.test(targetValue)  });
      if(pattern.test(e.target.value))
        this.setState({ [attribute]:  {...this.state[attribute], [input]:e.target.value}  });
        this.setState({loading:false})
    }
    else
      this.setState({ [attribute]:  {...this.state[attribute], [input]:e.target.value}  });
      this.setState({loading:false})
  }

  handleSelectInputChange = (input, attribute)  => e =>{
    
    let errorvalues = (attribute === 'income' ? this.state.errorincome : 
    (attribute === 'nominee' ? this.state.errornominee: this.state.errors) );
       
    if(e.value && e.value.length > 1)
      delete errorvalues[(attribute === 'nominee' ? 'nominee_' : '') + input];
      
    this.setState({ 
      [attribute]:  attribute ? {...this.state[attribute], [input]: e.value } : e.value,
      errornominee: errorvalues  
    });
    
  }

  handleRadioChange = input => e => {
      // validate(input);
      this.setState({ [input]:  this.str2bool(e.target.value)   });

  }

  handleSelectChange = (attribute, input) => e => {
      
      let errorvalues = (attribute === 'income' ? this.state.errorincome : 
        (attribute === 'nominee' ? this.state.errornominee: this.state.errors) ); 
      
      if(e.value && e.value.length > 1)
        delete errorvalues[input];

      this.setState({ 
        [input]:  e.value,
        errorincome: errorvalues    
      });
  }

  handleInputValidation = (attribute, list_input) => {

      let _validate = true;
      list_input.forEach(el => {
          if(_validate)
            _validate = !el['pattern'].test(el['value']);
      })
      this.setState({ ['err_' + attribute]:  !_validate  });
    
  }

  str2bool = (value) => {

    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
        else
            return value;
    }
    return value;
  }

  onLoadStageInfo = () => {

    this.setState({loading:true})
    this.props.loadStageInfo({ stage_name: 'personal'}).then(response => {

        if(this.props.personal_result['code'] !== '000') {
            this.setState({loading:false})
            alert(this.props.personal_result['messageText']);}
        else {
          
            let _step = 1;
            if(sessionStorage.getItem('personal_info_step') && 
              !isNaN(sessionStorage.getItem('personal_info_step')) ) {
                _step = parseInt(sessionStorage.getItem('personal_info_step')) ;
            }
            if(this.props.personal_result['personal_stage_verified'])
              _step = 0;

            this.setState(this.props.personal_result['id_information']);
            this.setState({ 
              master_list: this.props.personal_result['master_list'],
              loading: false, 
              step: _step
            });
            
        }

    })
    .catch(err => {
      alert(err)
    });

    
  };

  handleFileChange = (selectedFile, id_type, attribute) => (event) => {
    
    this.setState({loading:true});

    if(event.target.files[0] && (event.target.files[0].type === 'image/png' || 
      event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg') ) {
      
      const uploaded_file = { 
          [selectedFile]: event.target.files[0], 
          ['src_' + selectedFile]:  URL.createObjectURL(event.target.files[0])
        };

      {

        let formData = new FormData();
        formData.append("upload_file", event.target.files[0]);
        formData.append("id_type", id_type);
        formData.append("folder_name", id_type);
        formData.append("attachment_category", 'nominee');
        formData.append("is_update_collection", attribute['is_update']);
        formData.append("attribute_name", attribute['name']);
        formData.append("source_data", JSON.stringify(attribute['data'] || {}));
        
        this.props.verifyIdProof(formData, response => {

          console.log(response);
          this.setState({loading:false});

          if(response['code'] === '000') 
          {   
            this.setState(uploaded_file);
            this.setState({ 
              nominee_information:  {...this.state.nominee_information, id_information: response['id_information']}  
            });
          }
          else{
            alert(response['messageText']);
          }
        });

        // .catch(err => {
        //   alert(err)
        // });

      }

    }
    else {

      if(event.target.files.length > 0)
        this.setState({ 
          [selectedFile + '_show_popup']:  true
        });

    }

  }

  handlePhotographChange = (selectedFile, imageSrc) => (event) => {
    
    this.setState({ 
        [selectedFile]: imageSrc
      });

  }

  handlePopupChange = (state_name) => (event) => {
      
      this.setState({ 
        [state_name]:  false
      });

  }

  updatePersonalInfo = (body_parameters, step) => {
this.setState({loading:true})
    this.props.updatePersonalInfo(body_parameters, response => {

        if(response['code'] !== '000'){
            alert(response['messageText']);
            this.setState({loading:false})
        }
        else {
          this.setState({loading:false})
          sessionStorage.setItem('personal_info_step', step);
          this.setState({ step: step + 1 });
        }

    })
    // .catch(err => {
    //   alert(err)
    // });

    
  };

  render() {

    // if (this.props.personal_result && this.props.personal_result['personal_stage_verified']) {
      
    //   this.props.history.push(
    //     { 
    //       pathname: '/' + this.props.personal_result['navigateScreen'] + '/'
    //     }
    //   );

    // }

    const { step,
    pin ,
    first_name ,
    last_name ,
    do_birth ,
    gender ,
    marital_status ,
    email,
    occupation,
    source_of_wealth,
    annual_income,
    is_income_outside_india,
    is_political_india,
    is_political_foreign,
    address,
    locality_or_post_office,
    district_or_city,
    city,
    state,
    pincode,
    address_information,
    err_address_information,
    nominee_information,
    file_photograph,
    file_nominee,
    src_file_nominee,
    src_file_photograph,
    file_photograph_1,
    file_nominee_1,
    src_file_nominee_1,
    src_file_photograph_1,
    master_list,
    file_nominee_show_popup,
    file_nominee_popup_messagetext
    } = this.state;

    const values = {
      pin ,
      first_name ,
      last_name ,
      do_birth ,
      gender ,
      marital_status ,
      email,
      occupation,
      source_of_wealth,
      annual_income,
      is_income_outside_india,
      is_political_india,
      is_political_foreign,
      address,
      locality_or_post_office,
      district_or_city,
      city,
      state,
      pincode,
      address_information,
      err_address_information,
      nominee_information,
      file_photograph,
      file_nominee,
      src_file_nominee,
      src_file_photograph,
      file_photograph_1,
      file_nominee_1,
      src_file_nominee_1,
      src_file_photograph_1,
      master_list,
      file_nominee_show_popup,
      file_nominee_popup_messagetext
    }
    
    switch(step) {
      case 0:
        return (
          <EsignkycVerification />
        )
      case 1: 
        return (
          <PersonalDetails 
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            handleObjectInputChange = {this.handleObjectInputChange }
            handleRadioChange={ this.handleRadioChange }
            handleInputChange={ this.handleInputChange }
            handleInputValidation={this.handleInputValidation}
            values={ values }
            errors = {this.state.errors}
            loading={this.state.loading}
          />
        )
      case 2: 
        return (
          <IncomeDetails 
            prevStep={ this.prevStep }
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            handleSelectChange = {this.handleSelectChange}
            handleRadioChange={ this.handleRadioChange }
            values={ values }
            errors = {this.state.errorincome}
            loading={this.state.loading}
          />
        )
        case 3: 
        return (
          <NomineeDetails 
          prevStep={ this.prevStep }
          nextStep={ this.nextStep }
          handleChange={ this.handleChange }
          handleSelectChange = {this.handleSelectChange}
          handleRadioChange={ this.handleRadioChange }
          handleInputChange={ this.handleInputChange }
          handleObjectInputChange = {this.handleObjectInputChange}
          handleSelectInputChange={ this.handleSelectInputChange }
          handleFileChange = {this.handleFileChange}
          handlePopupChange = {this.handlePopupChange}
          values={ values }
          errors = {this.state.errornominee}
          loading={this.state.loading}
          />
        )
        case 4: 
        return (
          <ImageVerification 
            prevStep={ this.prevStep }
            nextStep={ this.nextStep }
            values={ values }
            handlePhotographChange = {this.handlePhotographChange}
            errors = {this.state.errorimage}
            loading={this.state.loading}
          />
        )
        case 5: 
        return (
          <ConfirmApplicationDetails 
          prevStep={ this.prevStep }
          nextStep={ this.nextStep }
          handleChange={ this.handleChange }
          handleSelectChange = {this.handleSelectChange}
          handleRadioChange={ this.handleRadioChange }
          handleInputChange={ this.handleInputChange }
          handleSelectInputChange={ this.handleSelectInputChange }
          errors = {this.state.errors}
          values={ values }
          loading={this.state.loading}
          />
        )
        case 6: 
          return (
            <Documentsupload 
              prevStep={ this.prevStep }
              nextStep={ this.nextStep }
              values={ values }
              loading={this.state.loading}
            />
        )
        
      default: 
          // do nothing
    }


  }

}


const mapStateToProps = state => ({
  personal_result: state.verify.personal_result
});

export default connect(mapStateToProps, {  verifyAccount, verifyStage, loadStageInfo, updatePersonalInfo, verifyIdProof })
  ( PersonalVerification );
