import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import { Form,Container,Row,Col,Card,Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
const relation = [
  { value: 'Wife', label: 'Wife' },
  { value: 'Husband', label: 'Husband' },
  { value: 'Son', label: 'Son' },
  { value: 'Daughter', label: 'Daughter' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Father', label: 'Father' },
  { value: 'Grand Son', label: 'Grand Son' },
  { value: 'Grand Daughter', label: 'Grand Daughter' }
]

const identityproof = [
  { value: 'Aadhaar Number', label: 'Aadhaar Number' },
  { value: 'Passport Number', label: 'Passport Number' },
  { value: 'PAN Number', label: 'PAN Number' },
  { value: 'Voter Id', label: 'Voter Id' },
  { value: 'Driving License Number' , label: 'Driving License Number' },


]

const incomerange = [
  { value: 'Below 1 Lakh', label: 'Below 1 Lakh' },
  { value: '1-5 Lakhs', label: '1-5 Lakh' },
  { value: '5-10 Lakhs', label: '5-10 Lakhs' },
  { value: '10-25 Lakhs', label: '10-25 Lakhs' },
  { value: 'Agriculturist', label: 'Agriculturist' },
  { value: '25 lakhs - 1 Crore', label: '25 lakhs - 1 Crore' },
  { value: 'More than 1 Crore', label: 'More than 1 Crore' },
  { value: 'Above 5 Crore', label: 'Above 5 Crore' },


]

const ConfirmApplicationDetails = ({ prevStep, nextStep, handleChange,handleSelectInputChange,handleInputChange, values ,handleSelectChange,handleRadioChange,loading}) => {
  console.log(values);

  const Continue = e => {
    this.props.history.push('/imageverification/');
  //  nextStep();
 
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }
  return (
    <>
      <div className="authincation section-padding" style={{marginTop:120}}>
      <div className="content-body">
            <div className="container">
              <div class="row">
                <div class="col-xl-12">
              <div className="auth-form card">
                <div className="card-body">
                { loading &&
       <Spinner animation="border" variant="dark" 
      style={{ position: "fixed", top: "50%", left: "50%" }}/>
    }     
                  {/* <h3 className="text-center">Please Provide Your Details</h3>
                  <p className="text-center mb-5">Investor Information</p> */}
                  <h3 className="text-center mb-5">Confirm Your Details</h3>

                  <Form>
                  <Container>
  <Row>
  <Card style={{ width: '18rem' }}>
  <Card.Body>
    <Card.Title></Card.Title>
    <Card.Text>
    <Col>{values.nominee_information["aadhaarnumber"]}</Col>
    
    </Card.Text>
   
  </Card.Body>
</Card>
<Card style={{ width: '18rem' }}>
  <Card.Body>
    <Card.Title>Card Title</Card.Title>
    <Card.Text>
    <Col>{values.nominee_information["aadhaarnumber"]}</Col>
    
    </Card.Text>
   
  </Card.Body>
</Card>
  </Row>
 
</Container>
                 
                  
                    <div className="text-center mb-4">
                      <Button
                        onClick={Previous}
                        type="submit"
                        variant="secondary"
                        color="primary"
                      >
                        Previous
                      </Button>
                      <Button
                        onClick={Continue}
                        type="submit"
                        variant="success"
                        color="primary"
                      >
                        Next
                      </Button>
                    </div>
                    
                  </Form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default ConfirmApplicationDetails;