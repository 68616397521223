import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  modifyOrder,
  additionalOrder,
  redeem2FactorOrder
} from "../../actions/orders";
import Select from "react-select";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
// import Spinner from "../../layout/spinner";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class OrderEditCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request_mode: "",
      selected_order: {},
      bank_information: {},
      loading: false,
      render_pdf: null,
      enachmandateFile: null,
      src_enachmandateFile: null,
      byte_enachmandateFile: null,
      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_activity_completed: false,
      nofitication_html: ``,
      isloading: false,
      is_redeem_button_clicked: false,
      mobile_otp: '',
      email_otp: '',
      twofactor_token_id: '',
      pay_bank_mode_list: [],
      selected_pay_bank_mode: ''
    };
  }

  componentDidMount() {

    console.log(this.props.location.state);
    if (this.props.match && this.props.match.path === '/order2factorCheckout/:redeem_id') { }
    else {

      if (this.props.location.state) {

        let _selected_order = this.props.location.state.selected_order;
        _selected_order['selected_pay_bank_mode'] = '';
        _selected_order['upi_id'] = '';

        this.setState({
          request_mode:
            this.props.location.state.request_mode || _selected_order["request_mode"],
          selected_order: _selected_order,
          bank_information: _selected_order["bank_information"],
          pay_bank_mode_list: _selected_order['pay_bank_mode_list']
        });

      }

    }

  }

  onInvestOrder = () => {

    this.setState({ isloading: true });

    this.props
      .additionalOrder({
        client_info: {
          buy_sell: "P",
          buy_sell_type: "ADDITIONAL",
          order_id: this.state.selected_order["_id"],
          order_mode: "MOD",
          order_amount: this.state.invest_more_amount,
        },
      })
      .then((response) => {

        this.setState({ isloading: false });

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          
          if (this.props.order_result["result"]) {

            let _selected_order = this.props.order_result["result"];
            _selected_order['selected_pay_bank_mode'] = '';
            _selected_order['upi_id'] = '';

            this.setState({
              request_mode: _selected_order["request_mode"],
              selected_order: _selected_order,
              bank_information: _selected_order["bank_information"],
              pay_bank_mode_list: _selected_order['pay_bank_mode_list']
            });
          }
          
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onRedeem2FactorOrder = () => {

    this.setState({ isloading: true });

    this.props
      .redeem2FactorOrder({
        client_info: {
          buy_sell: "R",
          order_id: this.state.selected_order["_id"],
          order_number: this.state.selected_order["mf_order_number"],
          order_mode: "MOD",
          order_amount: this.state.redeemable_amount,
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result['twofactor_id'])
            this.setState({
              isloading: false,
              twofactor_token_id: _result['twofactor_id'],
              is_redeem_button_clicked: true
              // selected_order: _result,
            });
        }
      })
      .catch((err) => {
        alert(err);
      });

  };

  onRedeemOrder = () => {

    this.setState({ isloading: true });

    this.props
      .modifyOrder({
        client_info: {
          buy_sell: "R",
          order_id: this.state.selected_order["_id"],
          order_mode: "MOD",
          order_amount: this.state.redeemable_amount,
        },
        twofactor_token_id: this.state.twofactor_token_id,
        mobile_otp: this.state.mobile_otp,
        email_otp: this.state.email_otp
      })
      .then((response) => {

        this.setState({ isloading: false });

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["result"])
            this.setState({
              selected_order: this.props.order_result["result"],
            });
        }

      })
      .catch((err) => {
        alert(err);
      });

  };

  handleConfirmCheck = (event) => {

    let is_checked = !this.state.is_redeem_full_checked;
    this.setState({
      is_redeem_full_checked: is_checked,
      redeemable_amount: is_checked
        ? this.state.selected_order["mf_order_redeemable_amount"]
        : 0.0,
    });
  };

  handleInvestAmount(event, attribute) {

    const re = /^\d+(\.\d{0,3})?$/; ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({
        /*invest_more_amount*/[attribute]: event.target.value.replace(
        /\b0+/g,
        ""
      ),
      });
    }

  }

  createDefaultMarkup(html_text) {

    return {
      __html: html_text,
    };

  }

  gotoPaymentPage() {

    this.props.history.push({
      pathname: "/paymentPage/",
      state: { selected_order: this.state.selected_order },
    });
  }

  gotoOrderListPage() {
    this.props.history.push({
      pathname: "/orderList/",
    });
  }

  render() {
    // if(this.props.loading)
    //   return <Spinner />;

    // if(this.props.order_result && this.props.order_result['esignkyc_stage_verified'])
    //    return <Redirect to={this.props.aadhaar_result['navigateScreen']} />;

    //   const renderButton = buttonProps => {
    //     return (
    //     <button onClick={this.sendOTP} {...buttonProps} className="text-dark">
    //         {buttonProps.remainingTime !== 0
    //         ? `Kindly wait for ${buttonProps.remainingTime} seconds to resend otp`
    //         : "Resend"}
    //     </button>
    //     );
    // };

    const renderButton = buttonProps => {
      return (
        <div>
          <button onClick={this.sendOTP} {...buttonProps}
            class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
            {`Resend OTP`}
          </button>
          <div style={{ textAlign: 'left' }}>
            {buttonProps.remainingTime !== 0
              ? <span>Didn't receive the otp ? You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.</span>
              : ``}
          </div>
        </div>
      );
    };

    const renderTime = () => React.Fragment;
    return (
      <>
        <Header1 />
        <Sidebar />
        <div
          class="d-flex align-items-center"
          style={{
            flex: 1,
            marginTop: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.state.isloading &&
            <Spinner animation="border" variant="dark"
              style={{ position: "fixed", top: "50%", left: "50%" }} />
          }
        </div>


        <div className="content-body" style={{ marginTop: 100 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {!this.props.location.state ?
                  <div className="card">
                    <div className="auth-form card">
                      <div className="card-header">
                        <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                          <CardContent  >
                            <Stack direction="row">
                              <Typography flex={1} whiteSpace="pre-line" >
                                Sorry the information you are looking for could not be found

                                <p></p>
                                <Link className="page-back text-muted" to={'/orderList'}><span><i
                                  className="fa fa-angle-left"></i></span> Go to Order List</Link>

                              </Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="card">

                    {this.state.selected_order[
                      "mf_order_unique_reference_no"
                    ] && (
                        <div className="auth-form card">
                          <div className="card-header">
                            <div class="row">
                              <div class="col-4">
                                <h4 >
                                  {this.state.request_mode === "redeem" ?
                                    'Redeem Order' : 'Order Payment'
                                  }
                                </h4>
                              </div>
                              <div class="col-10">
                                {
                                  this.state.selected_order[
                                  "mf_order_scheme_name"
                                  ]
                                }<span style={{
                                  color: 'InfoText', backgroundColor: 'lightgray', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                }} >
                                  Order No :{" "}
                                  {
                                    this.state.selected_order[
                                    "mf_order_registration_no"
                                    ]
                                  }
                                </span>
                              </div>
                            </div>

                          </div>
                          {/* <div className="card-body">
                            <form action="#"> */}

                          {this.state.request_mode === "payment" && (
                            <div class="row">
                              <div class="col">
                                <h6 style={{ fontSize: 12 }}>
                                  Investment Amount: Rs.{" "}
                                  {
                                    this.state.selected_order[
                                    "order_information"
                                    ]["request"]["OrderVal"]
                                  }
                                </h6>
                              </div>
                              <div class="col">
                                {/* {
                                    this.state.selected_order['mf_order_scheme_specifications']['exitload']['value']
                                  } */}
                              </div>
                            </div>
                          )}

                          {this.state.request_mode === "redeem" && (
                            <div class="row" style={{ marginLeft: 7 }}>
                              <div class="col">
                                {/* <h6 style={{ fontSize: 12 }}>
                                  Invested Amount : Rs.{" "}
                                  {
                                    this.state.selected_order[
                                    "mf_order_investment_amount"
                                    ]
                                  }
                                </h6> */}
                                <h6 style={{ fontSize: 12 }}>
                                  Total Returns : Rs.{" "}
                                  <span style={{fontFamily:'sans-serif'}}>&#8377;{
                                    this.state.selected_order[
                                    "total_returns_amount"
                                    ]
                                  }
                                    </span>
                                </h6>
                              </div>
                              {
                                this.state.selected_order['mf_order_scheme_specifications']['exitload']['is_allowed'] &&
                                <div class="col">
                                  <span style={{
                                    color: 'InfoText', backgroundColor: 'lightgray', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                  }} >
                                    {
                                      `Fee of ` + this.state.selected_order['mf_order_scheme_specifications']['exitload']['value']
                                    }
                                  </span>
                                </div>

                              }
                              <hr />

                            </div>
                          )}
                          {/* </form>
                          </div> */}
                          {this.state.bank_information["beneficiary_bank_name"] && (
                            // <div className="card-body">
                            <div class="row" style={{ marginLeft: 7 }}>
                              <div class="col-4">
                                <p style={{ fontSize: 12 }}>
                                  BANK NAME : <b>{
                                    ' ' + this.state.bank_information[
                                    "beneficiary_bank_name"
                                    ]
                                  }</b>
                                </p>
                                <p style={{ fontSize: 12 }}>
                                  ACCOUNT HOLDER NAME :{" "}
                                  <b>{
                                    this.state.bank_information["beneficiary_account_holder_name"]
                                  }</b>
                                </p>
                                <h6 style={{ fontSize: 12 }}>
                                  ACCOUNT NUMBER :{" "}
                                  <b>{
                                    this.state.bank_information[
                                    "beneficiary_account_no"
                                    ]
                                  }</b>
                                </h6>
                                <h6 style={{ fontSize: 12 }}>
                                  ACCOUNT TYPE :{" "}
                                  <b>{
                                    this.state.bank_information[
                                    "beneficiary_account_type"
                                    ]
                                  }</b>
                                </h6>
                                <h6 style={{ fontSize: 12 }}>
                                  IFSC :{" "}
                                  <b>{
                                    this.state.bank_information[
                                    "beneficiary_ifsc"
                                    ]
                                  }</b>
                                </h6>
                                <h6 style={{ fontSize: 12 }}>
                                  Branch :{" "}
                                  <b>{
                                    this.state.bank_information[
                                    "beneficiary_bank_branch_name"
                                    ]
                                  }</b>
                                </h6>
                                <h6 style={{ fontSize: 12 }}>
                                  <p style={{ fontWeight: 'bold' }}>{
                                    this.state.bank_information[
                                    "beneficiary_bank_branch_address"
                                    ]
                                  }</p>
                                </h6>

                              </div>
                              <div class="col">
                                {this.state.request_mode === "investmore" && (
                                  <div className="card-body">
                                    {!(
                                      this.state.selected_order[
                                      "mf_order_is_activity_completed"
                                      ] || false
                                    ) && (
                                        <div>
                                          <div className="card-body">
                                            <form action="#">
                                              <div class="row">
                                                <div class="col">
                                                  <h6 style={{ fontSize: 12 }}>
                                                    Minimum Investment Amount : Rs.{" "}
                                                    {
                                                      this.state.selected_order[
                                                      "mf_order_minimum_investment_amount"
                                                      ]
                                                    }
                                                  </h6>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-6">
                                                  <h6 style={{ fontSize: 12 }}>
                                                    Enter the amount you wish to invest
                                                  </h6>
                                                  <input
                                                    type="text"
                                                    className="form-control text-center font-weight-bold"
                                                    value={this.state.invest_more_amount}
                                                    onChange={(event) =>
                                                      this.handleInvestAmount(
                                                        event,
                                                        "invest_more_amount"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div class="col-3">
                                                  <br />
                                                  <Button
                                                    className="btn btn-success btn-block"
                                                    variant="success"
                                                    onClick={() =>
                                                      this.onInvestOrder(this.props, this)
                                                    }
                                                    disabled={
                                                      this.state.invest_more_amount <
                                                      this.state.selected_order[
                                                      "mf_order_minimum_investment_amount"
                                                      ] ||
                                                      this.state.isloading
                                                    }
                                                  >
                                                    Confirm
                                                  </Button>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      )}

                                    {(this.state.selected_order[
                                      "mf_order_is_activity_completed"
                                    ] ||
                                      false) && (
                                        <div>
                                          {/* <div className="card-body">
                                              <form action="#"> */}
                                          <div class="row">
                                            <div
                                              dangerouslySetInnerHTML={this.createDefaultMarkup(
                                                this.state.selected_order[
                                                "mf_order_notification_message"
                                                ]
                                              )}
                                            />
                                          </div>
                                          <div class="row">
                                            <div class="col-3">
                                              <label class="mr-sm-2">Payment Mode</label>

                                              <Select
                                                value={this.state.pay_bank_mode_list.find(obj => obj.value === this.state.selected_order['selected_pay_bank_mode'])}
                                                onChange={(e) => {
                                                  this.setState({
                                                    selected_order: { ...this.state.selected_order, selected_pay_bank_mode: e.value }
                                                  });
                                                }}
                                                options={this.state.pay_bank_mode_list}
                                              />

                                            </div>
                                            {this.state.selected_order['selected_pay_bank_mode'] === 'UPI' &&

                                              <div class="col-4">
                                                <label class="mr-sm-2">Enter UPI ID (as per your linked bank account)</label>
                                                <input
                                                  type="text"
                                                  className="form-control text-center font-weight-bold"
                                                  value={this.state.selected_order['upi_id']}
                                                  onChange={(event) =>
                                                    this.setState({
                                                      selected_order: { ...this.state.selected_order, upi_id: event.target.value }
                                                    })
                                                  }
                                                  disabled={this.state.selected_order['selected_pay_bank_mode'].length < 3}
                                                />
                                              </div>

                                            }
                                            <div class="col-3">
                                              <Button
                                                className="btn btn-success btn-block"
                                                variant="success"
                                                onClick={() => {
                                                  this.gotoPaymentPage();
                                                }}
                                                disabled={
                                                  this.state.selected_order['selected_pay_bank_mode'].length < 3 ||
                                                  this.state.isloading
                                                }
                                              >
                                                Pay Rs.{" "}
                                                {
                                                  this.state.selected_order[
                                                  "mf_order_pay_amount"
                                                  ]
                                                }
                                              </Button>
                                            </div>
                                          </div>
                                          {/* </form>
                                            </div> */}
                                        </div>
                                      )}
                                  </div>
                                )}

                                {this.state.request_mode === "redeem" &&
                                  !this.state.selected_order[
                                  "mf_order_is_activity_completed"
                                  ] && (
                                    <form action="#">

                                      <div class="row">
                                        <div class="col-5">
                                          <label style={{ 'margin-top': '10px' }}>Enter Redeem Amount</label>
                                          <h6 style={{fontFamily:'sans-serif'}}>
                                            (Min.
                                            &#8377;{this.state.selected_order['mf_order_scheme_specifications']['redemption']['minimum_amount']}
                                            {` upto `} &#8377;{" "}
                                            {
                                              this.state.selected_order['mf_order_scheme_specifications']['redemption']['maximum_amount'] ||
                                              this.state.selected_order[
                                              "mf_order_redeemable_amount"
                                              ]
                                            })
                                          </h6>
                                        </div>
                                        <div class="col-4">
                                          <input
                                            type="text"
                                            className="form-control text-center font-weight-bold"
                                            value={this.state.redeemable_amount}
                                            onChange={(event) =>
                                              this.handleInvestAmount(
                                                event,
                                                "redeemable_amount"
                                              )
                                            }
                                            disabled={
                                              this.state.is_redeem_full_checked ||
                                              this.state.selected_order[
                                              "mf_order_is_activity_completed"
                                              ] ||
                                              this.state.is_redeem_button_clicked ||
                                              this.state.isloading
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-5">
                                          <br />
                                          <label>
                                            <Form.Group
                                              className="mb-3"
                                              controlId="formBasicCheckbox"
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                label="Redeem whole amount"
                                                onChange={this.handleConfirmCheck}
                                                defaultChecked={
                                                  this.state.is_redeem_full_checked
                                                }
                                                disabled={this.state.is_redeem_button_clicked}
                                              />
                                            </Form.Group>
                                          </label>
                                        </div>
                                        <div class="col-4" >
                                          <Button class="d-flex justify-content-between mt-3"
                                            className="btn btn-success btn-block"
                                            variant="success"
                                            onClick={() =>
                                              this.onRedeem2FactorOrder()
                                            }
                                            disabled={
                                              this.state.redeemable_amount.length === 0 || 
                                              isNaN(this.state.redeemable_amount) ||
                                              parseInt(this.state.redeemable_amount) < parseInt(this.state.selected_order['mf_order_scheme_specifications']['redemption']['minimum_amount']) ||
                                              parseInt(this.state.redeemable_amount) >= parseInt(this.state.selected_order['mf_order_scheme_specifications']['redemption']['maximum_amount'] ||
                                                this.state.selected_order['total_returns_amount']) ||
                                              this.state.is_redeem_button_clicked ||
                                              this.state.isloading /*|| 
                                              parseInt(this.state.redeemable_amount) > 
                                              parseInt(this.state.selected_order[
                                                "mf_order_redeemable_amount"
                                                ])*/
                                            }
                                          >
                                            Redeem
                                          </Button>
                                        </div>
                                      </div>
                                      {
                                        this.state.selected_order['mf_order_scheme_specifications']['exitload']['is_allowed'] &&  
                                        !isNaN(this.state.redeemable_amount) &&
                                        parseInt(this.state.redeemable_amount) > 0 &&
                                        <div class="row">
                                          {/* <div class="col-6"> */}
                                            <span style={{
                                              fontFamily:'sans-serif', width:300, color: 'InfoText', backgroundColor: '#a3bed1', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                            }} >
                                              &#8377;{
                                                ((parseInt(this.state.redeemable_amount) *
                                                  parseFloat(this.state.selected_order['mf_order_scheme_specifications']['exitload']['percent'])) / 100)
                                                + ` will be deducted as fee`
                                              }
                                            </span>

                                          {/* </div> */}
                                        </div>
                                      }
                                      {
                                        // this.state.selected_order['mf_order_scheme_specifications']['exitload']['is_allowed'] &&    
                                        !isNaN(this.state.redeemable_amount) &&
                                        parseInt(this.state.redeemable_amount) > 0 &&
                                        <div class="row">
                                          {/* <div class="col-6"> */}
                                            <span style={{
                                              fontFamily:'sans-serif', width:350, color: 'InfoText', backgroundColor: 'lightgreen', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                            }} >
                                              &#8377; {
                                                (parseInt(this.state.redeemable_amount) - (parseInt(this.state.redeemable_amount) *
                                                  parseFloat(this.state.selected_order['mf_order_scheme_specifications']['exitload']['percent'])) / 100)
                                                + ` will be credited to your bank account`
                                              }
                                            </span>

                                          {/* </div> */}
                                        </div>
                                      }

                                    </form>
                                  )}

                                {this.state.request_mode === "redeem" &&
                                  this.state.selected_order[
                                  "mf_order_is_activity_completed"
                                  ] && (
                                    <div className="card-body">
                                      <div>
                                        <div className="card-body">
                                          <form action="#">
                                            <div class="row">
                                              <div
                                                dangerouslySetInnerHTML={this.createDefaultMarkup(
                                                  this.state.selected_order[
                                                  "mf_order_notification_message"
                                                  ]
                                                )}
                                              />
                                            </div>
                                            <div class="row">
                                              <Button
                                                className="btn btn-success btn-block"
                                                variant="success"
                                                onClick={() => this.gotoOrderListPage()}
                                              >
                                                Ok, Done
                                              </Button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                              </div>
                            </div>
                            // </div>
                          )}

                          {this.state.request_mode === "redeem" &&
                            !this.state.selected_order["mf_order_is_activity_completed"] &&
                            this.state.is_redeem_button_clicked &&
                            <div className="card-body">
                              <div className="form-group">
                                <p className="text-center mb-5">For two factor security we have sent one time code to your registered email & mobile number as per RTA</p>
                                <div class="row">
                                  <div class="col-4">
                                    <label>Your Mobile OTP</label>
                                    <OTPInput
                                      value={this.state.mobile_otp}
                                      onChange={event => {
                                        this.setState({ mobile_otp: event })
                                      }}
                                      autoFocus
                                      OTPLength={6}
                                      otpType="number"
                                      disabled={false}

                                      secure
                                      isInputNum={true}
                                    />
                                    <br></br>
                                    <label>Your Email OTP</label>
                                    <OTPInput
                                      value={this.state.email_otp}
                                      onChange={event => {
                                        this.setState({ email_otp: event })
                                      }}
                                      autoFocus
                                      OTPLength={6}
                                      otpType="number"
                                      disabled={false}

                                      secure
                                      isInputNum={true}
                                    />
                                  </div>
                                  <div class="col-3">
                                    <br></br>
                                    <button type="button"
                                      class="btn btn-danger"
                                      onClick={() => {
                                        this.setState({ is_redeem_button_clicked: false })
                                      }}
                                    >Undo</button>
                                    <br></br>
                                    <br></br>
                                    <Button
                                      className="btn btn-success btn-block"
                                      variant="success"
                                      onClick={this.onRedeemOrder}
                                      disabled={
                                        this.state.mobile_otp < 6 ||
                                        this.state.email_otp < 6 ||
                                        this.state.isloading
                                      }
                                    >Verify & Confirm</Button>
                                  </div>

                                  <div class="col-5">
                                    <ResendOTP renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                      onResendClick={(e) => { this.onRedeem2FactorOrder() }}
                                    />
                                  </div>

                                </div>
                              </div>
                            </div>
                          }



                          {this.state.request_mode === "paynow" && (
                            <div className="card-body">
                              <div
                                class="row"
                              >  <div class="col-3">
                                  <label class="mr-sm-2">Payment Mode</label>

                                  <Select
                                    value={this.state.pay_bank_mode_list.find(obj => obj.value === this.state.selected_order['selected_pay_bank_mode'])}
                                    onChange={(e) => {
                                      this.setState({
                                        selected_order: { ...this.state.selected_order, selected_pay_bank_mode: e.value }
                                      });
                                    }}
                                    options={this.state.pay_bank_mode_list}
                                  />

                                </div>
                                {this.state.selected_order['selected_pay_bank_mode'] === 'UPI' &&
                                  <div class="col-4">
                                    <label class="mr-sm-2">Enter UPI ID (as per your linked bank account)</label>
                                    <input
                                      type="text"
                                      className="form-control text-center font-weight-bold"
                                      value={this.state.selected_order['upi_id']}
                                      onChange={(event) =>
                                        this.setState({
                                          selected_order: { ...this.state.selected_order, upi_id: event.target.value }
                                        })
                                      }
                                      disabled={this.state.selected_order['selected_pay_bank_mode'].length < 3}
                                    />
                                  </div>
                                }
                                <div class="col-3">
                                  <label class="mr-sm-2"></label>
                                  <Button
                                    className="btn btn-success btn-block"
                                    variant="success"
                                    onClick={() => {
                                      this.gotoPaymentPage();
                                    }}
                                    disabled={this.state.selected_order['selected_pay_bank_mode'].length < 3}
                                  >
                                    Pay Rs.{" "}
                                    {this.state.selected_order["mf_order_pay_amount"]}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                }

              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
// OrderEditCheckout.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result
  };
};

export default connect(mapStateToProps, {
  modifyOrder,
  additionalOrder,
  redeem2FactorOrder
})(OrderEditCheckout);
