import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Spinner,Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  verifyPan,
  verifyStage,
  loadStageInfo,
} from "../../../jsx/actions/verify";
import { Redirect } from "react-router-dom";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Popup from "../popup";
//import Spinner from "../../layout/spinner";

class PanVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_edit: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_confirm_chk_checked: false,
      is_verified_editable: false,
      selectedFile: null,
      src_selectedFile: null,
      pan_information: [],
      pan_attachment_list: [],
      show_popup: false,
      pop_messagetext: "",
      isloading: this.props.loading,
    };
  }

  componentDidMount() {
    this.onLoadStageInfo();
    this.setState({ isloading: false })
  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      pop_messagetext: "",
    });
  };

  changeHandler = (event) => {

    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      this.setState({
        selectedFile: event.target.files[0],
        src_selectedFile: URL.createObjectURL(event.target.files[0]),
      });
    } else {

      if(event.target.files.length > 0)
        this.setState({
          show_popup: true,
          pop_messagetext: "Upload file format should be of PNG, JPEG, JPG only",
        });

    }
  };

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  isEdit = () => {
    this.setState({
      is_edit: true,
      is_verify_enabled: true,
      // is_confirm_chk_enabled: false
    });
  };

  isCancelEdit = () => {
    this.setState({
      is_edit: false,
      is_verify_enabled: false,
    });
  };

  onLoadStageInfo = () => {
    
    this.setState({ isloading: true });

    this.props
      .loadStageInfo({ stage_name: "pan" })
      .then((response) => {
        
        this.setState({ isloading: false });
        if (this.props.pan_result["code"] !== "000") 
        {
          alert(this.props.pan_result["messageText"]);
        }
        else {
          if (this.props.pan_result["state_parameters"])
            this.setState(this.props.pan_result["state_parameters"]);
          else
            this.setState({
              is_stage_verified: this.props.pan_result["is_stage_verified"],
              is_verify_enabled: this.props.pan_result["is_verify_enabled"],
              is_confirm_chk_enabled:
                this.props.pan_result["is_confirm_chk_enabled"],
              is_verified_editable:
                this.props.pan_result["is_verified_editable"],
              pan_information: this.props.pan_result["id_information"],
              pan_attachment_list: this.props.pan_result["attachment_list"],
            });
        }

      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };

  onVerify = () => {

    this.setState({ isloading: true })
    let formData = new FormData();

    formData.append("upload_file", this.state.selectedFile);
    formData.append("id_type", "pan");
    formData.append("folder_name", "pan");
    formData.append("attachment_category", "pan");

    this.props
      .verifyPan(formData)
      .then((response) => {
        this.setState({ isloading: false })
        if (this.props.pan_result["code"] !== "000")

          alert(this.props.pan_result["messageText"]);
        else {
          // this.setState({
          //     is_verify_enabled: false,
          //     is_confirm_chk_enabled: true,
          //     pan_information: this.props.pan_result['id_information']
          // });
          if (this.props.pan_result["state_parameters"])
            this.setState(this.props.pan_result["state_parameters"]);
          else
            this.setState({
              is_stage_verified: this.props.pan_result["is_stage_verified"],
              is_verify_enabled: this.props.pan_result["is_verify_enabled"],
              is_confirm_chk_enabled:
                this.props.pan_result["is_confirm_chk_enabled"],
              is_verified_editable:
                this.props.pan_result["is_verified_editable"],
              pan_information: this.props.pan_result["id_information"],
              pan_attachment_list: this.props.pan_result["attachment_list"],
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onVerifyStage = () => {

    this.setState({ isloading: true })
    this.props.verifyStage({ verify_stage: "pan" }, "pan", (response) => {
      this.setState({ isloading: false })
      this.props.history.push({
        pathname: "/" + this.props.pan_result["navigateScreen"] + "/",
      });
      // this.setState({
      //     is_verify_enabled: false,
      //     is_confirm_chk_enabled: true,
      //     pan_information: this.props.pan_result['id_information']
      // });
    });
  };

  render() {
    //    if(this.props.pan_result && this.props.pan_result['pan_stage_verified'])
    //        return <Redirect to={this.props.pan_result['navigateScreen']} />;

    return (
      <>
        <Header1 />
        <Sidebar />
        <div className="authincation section-padding">
          <div className="content-body" style={{ marginTop: 100 }}>
            <div className="container">
              <div class="row">
                <div class="col-xl-12">
                  <div className="auth-form card">
                    <div className="card-body">

                      <h3 className="text-center">Verify PAN </h3>
                      {this.state.isloading &&
                        <Spinner animation="border" variant="secondary"
                          style={{ position: "fixed", top: "50%", left: "50%" }} />
                      }
                      

                      <form action="#">
                        {
                          //this.state.is_verified_editable &&
                          <div>
                            <Form>
                            
                              <Form.Group controlId="formFile" className="mb-3">
                                {(this.state.is_verify_enabled ||
                                  this.state.is_verified_editable) && (
                                    <div>
                                      
                                       <span style={{color:'green',fontSize:14, fontWeight:'bold'}}>Note : </span> Upload only front side of your pan card and
                                        format should be png or jpeg
                                       
                                      <hr></hr>
                                      <Form.Control
                                        type="file"
                                        onChange={this.changeHandler}
                                      />

                                    </div>
                                  )}

                                {(this.state.is_verify_enabled ||
                                  this.state.is_verified_editable) &&
                                  this.state["src_selectedFile"] && (
                                    <div className="text-center">
                                      <img
                                        src={this.state["src_selectedFile"]}
                                        className="photo"
                                      />
                                    </div>
                                  )}
                              </Form.Group>
                            </Form>
                            <div className="text-center">
                              {(this.state.is_verify_enabled ||
                                this.state.is_verified_editable) &&
                                this.state["src_selectedFile"] && (
                                  <Button
                                    className="btn btn-success btn-block"
                                    variant="success"
                                    onClick={this.onVerify}
                                    disabled={this.state.isloading}
                                  >
                                    Verify
                                  </Button>
                                )}
                            </div>
                          </div>
                        }

                        {/* <div className="form-group">
                                            <label>Enter your Date of Birth </label>
                                            <Form.Control type="date" name='date_of_birth'  />
                                        </div> */}

                        {this.state.pan_information.length > 0 && (
                          <p className="text-center mb-5">
                            Recently uploaded information
                          </p>
                        )}
                        {this.state.isloading &&
                          <Spinner animation="border" variant="dark"
                            style={{ position: "fixed", top: "50%", left: "50%" }} />
                        }
                        <div class="row">
                          <div class="col-5">
                            {this.state.pan_information.map((el, i) => (
                              <div key={i}>
                                <label className="text-primary">
                                  {el["attribute"]
                                    .replace(/_/gm, " ")
                                    .toUpperCase()}{" "}
                                  :{" "}<label className="text-dark">{el["text"]}</label>
                                </label>
                              </div>
                            ))}

                          </div>
                          <div class="col">
                            {
                          <div className="text-center">
                            {(this.state.pan_attachment_list || []).map(
                              (el, i) => (
                                <div key={i} className="form-group">
                                  <img src={el} className="photo"   />
                                </div>
                              )
                            )}
                          </div>
                        }
                            
                          </div>
                        </div>

                        {!this.state.is_stage_verified && (
                          <div>
                            {this.state.is_confirm_chk_enabled && (
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicCheckbox"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label="I agree and confirm to save my pan information."
                                  value={this.state.is_confirm_chk_checked}
                                  onChange={this.handleConfirmCheck}
                                  defaultChecked={
                                    this.state.is_confirm_chk_checked
                                  }
                                />
                              </Form.Group>
                            )}
                            {this.state.is_confirm_chk_checked && (
                              <div className="text-center">
                                <Button
                                  className="btn btn-success btn-block"
                                  variant="success"
                                  onClick={this.onVerifyStage}
                                  disabled={this.state.isloading}
                                >
                                  Confirm
                                </Button>
                              </div>
                            )}
                          </div>
                        )}

                        {this.state.is_stage_verified && (
                          <div className="text-center">
                            {/* <Button
                                                className="btn btn-success btn-block"
                                                variant="success"
                                                onClick={this.onNext}>Next</Button> */}
                            <Link
                              to={"./aadhaarVerification"}
                              type="submit"
                              className="btn btn-success pl-5 pr-5"
                            >
                              Next
                            </Link>
                          </div>
                        )}
{this.state.show_popup &&  (
                  <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                   <Modal.Header>
                      
                   </Modal.Header>
                   <Modal.Body>
                   
                   <p>{this.state["pop_messagetext"]}</p>
                                                       
                   </Modal.Body>
                   <Modal.Footer>
                       <Button variant="primary" onClick={() => this.closePopup()}>
                           Close
                       </Button>
                   </Modal.Footer>
               </Modal> 
               )}
                        {/* {this.state.show_popup && (
                          <Popup
                            content={
                              <>
                                <p>{this.state["pop_messagetext"]}</p>
                              </>
                            }
                            handleClose={() => this.closePopup()}
                          />
                        )} */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// PanVerification.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = (state) => ({
  pan_result: state.verify.pan_result,
});

export default connect(mapStateToProps, {
  verifyPan,
  verifyStage,
  loadStageInfo,
})(PanVerification);
