import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Spinner, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Header1 from "../../layout/header1";
import { v4 as uuidv4 } from "uuid";
import Webcam from "react-webcam";
import { verifyAccount, loadStageInfo } from "../../../jsx/actions/verify";
import Sidebar from "../../layout/sidebar";
import { Redirect } from "react-router-dom";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
  screenshotQuality: 1,
};

class ImageVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file_photograph: null,
      src_file_photograph: null,
      file_photograph_tiff: null,
      is_confirm_chk_checked: false,
      isloading: false,
    };
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    let file_photograph = this.dataURLtoFile(
      imageSrc,
      "photograph" /*uuidv4()*/
    );

    this.setState({
      file_photograph: file_photograph,
      src_file_photograph: imageSrc,
    });
  };
  Recapture = () => {
    this.setState({ src_file_photograph: null });
  };

  dataURLtoFile(dataurl, filename) {
    this.setState({ isloading: true });
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.setState({
      file_photograph_tiff: new File([u8arr], filename + "_photograph.tiff", {
        type: mime.split("/")[0] + "/tiff",
      }),
    });
    this.setState({ isloading: false });
    return new File([u8arr], filename + "." + mime.split("/")[1], {
      type: mime,
    });
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  verifyAccount = () => {

    this.setState({ isloading: true });
    let formData = new FormData();

    console.log(this.props);
    formData.append("upload_file", this.state.file_photograph);
    formData.append("upload_file", this.state.file_photograph_tiff);

    let parameters = this.props.values;

    let _nomineeUpload =
      !parameters["nominee_information"] ||
      !parameters["nominee_information"]["attachment_id"] ||
      parameters["nominee_information"]["attachment_id"].length < 4;

    for (var key of Object.keys(parameters)) {
      if (
        (!_nomineeUpload && key === "file_nominee") ||
        key === "file_photograph"
      ) {
        if (parameters[key]) formData.append("upload_file", parameters[key]);
      } else {
        if (typeof parameters[key] === "object")
          formData.append(key, JSON.stringify(parameters[key]));
        else formData.append(key, parameters[key]);
      }
    }

    formData.append("id_type", "personal");
    formData.append("folder_name", "personal");
    formData.append("attachment_category", "personal");
    formData.append("verify_stage", "personal");

    // this.props.verifyAccount(formData, (response) => {
      this.props.verifyAccount(formData)
      .then(response => {

        this.setState({ isloading: false });
        
        if (
          this.props.personal_result &&
          this.props.personal_result["personal_stage_verified"]
        ) {
          console.log('verified');
          // this.props.history.push(
          //   "/" + this.props.personal_result["navigateScreen"]
          // );
        }
    })
    .catch(err => {
        alert(err);
    });

  };

  render() {
    
    if (
      this.props.personal_result &&
      this.props.personal_result["personal_stage_verified"]
    )
      return <Redirect to={this.props.personal_result["navigateScreen"]} />;

    return (
      <>
        <Header1 />
        <Sidebar />

        <div
          className="authincation section-padding"
          style={{ marginTop: 120 }}
        >
          <div className="content-body">
            <div className="container">
              <div class="row">
                <div class="col-xl-12">
                  <div className="card-body">
                    {/* <h3 className="text-center mb-5" style={{ marginBottom: 0 }}>Verify Your Identity</h3> */}
                    <div>
                      <h3
                        className="text-center mb-5"
                        style={{ marginBottom: 0 }}
                      >
                        Verify Your Identity
                      </h3>
                    </div>

                    <div class="row " style={{ marginTop: -100 }}>
                      <Row>
                        <Col className="text-center">
                          {this.state.src_file_photograph ? (
                            <img src={this.state.src_file_photograph} style={{marginBottom:20}} />
                          ) : (
                            <Webcam
                              audio={false}
                              height={500}
                              ref={this.setRef}
                              screenshotFormat="image/jpeg"
                              width={500}
                              videoConstraints={videoConstraints}
                            />
                          )}{" "}
                        </Col>
                      </Row>
                    </div>

                    <div class="text-center mb-4">
                      <div
                        class="col"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={this.props.prevStep}
                          type="submit"
                          variant="secondary"
                          color="primary"
                        >
                          Previous
                        </Button>

                        <span style={{ marginRight: 50 }}></span>

                        {this.state.src_file_photograph &&
                        this.state.src_file_photograph.length > 77 ? (
                          <button
                            onClick={this.Recapture}
                            className="btn btn-success pl-5 pr-5"
                          >
                            Re-take
                          </button>
                        ) : (
                          <button
                            onClick={this.capture}
                            className="btn btn-success pl-5 pr-5"
                          >
                            Capture photo
                          </button>
                        )}
                        {this.state.isloading && (
                          <Spinner
                            animation="border"
                            variant="dark"
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                            }}
                          />
                        )}
                      </div>
                      <div class="row" style={{paddingTop:30,paddingBottom:30}}>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-7">
                        {this.state.src_file_photograph &&
                          this.state.src_file_photograph.length > 77 && (
                            <div>
                            <Row>
                              <Col className="mb-3">
                              
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox"
                                >
                                  <Form.Check size="lg"
                                    type="checkbox"
                                    
                                    label="I here by agree to generate my KYC information and submit the same to the exchange"
                                    value={this.state.is_confirm_chk_checked}
                                    onChange={this.handleConfirmCheck}
                                    defaultChecked={
                                      this.state.is_confirm_chk_checked
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              </Row>
                              <Row>
                              <Col>
                                {this.state.is_confirm_chk_checked && (
                                  <div className="text-center">
                                    <Button
                                      className="btn btn-success btn-block"
                                      variant="success"
                                      onClick={this.verifyAccount}
                                      disabled={this.state.isloading}
                                     
                                    >
                                      Confirm
                                    </Button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                        
                          </div>  )}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  personal_result: state.verify.personal_result,
});

export default connect(mapStateToProps, { verifyAccount, loadStageInfo })(
  ImageVerification
);
