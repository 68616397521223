import React from "react";
import { Link } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  loadOrderList,
  loadPaymentResponse,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  cancelSwpOrder
} from "../../actions/orders";
import { Redirect } from "react-router-dom";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
//import Spinner from "../../layout/spinner";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Table, Collapse, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class SipList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tab_orders: 0,
      tab_sip_order_child: 4,
      tab_xsip_order_child: 2,
      cardopen: false,
      list_sip_orders: [],
      list_xsip_orders: [],
      bank_information: {},

      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_frequency: ''
      },
      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),
      swp_exclude_date_list: [],

      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_pause_popup: false,
      selected_pause_order: {},
      show_popup: false,
    };
  }

  componentDidMount() {

    this.onLoadSipList(0);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadSipList = (order_type) => {

    this.setState({ isloading: true })

    order_type = order_type === 0 ? "xsip" : "sip";
    this.props
      .loadOrderList({
        order_type: order_type,
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);

        else {
          if (this.props.order_result["result"])

            this.setState({
              bank_information: this.props.order_result["bank_information"],
              ["list_" + order_type + "_orders"]: this.props.order_result["result"],
              isloading: false
            });
          else this.setState({
            ["list_" + order_type + "_orders"]: [],
            isloading: false
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  gotoCheckOutPage(request_mode, selected_order) {

    if (selected_order["mf_order_type"].toLowerCase() === "xsip") {
      this.props.history.push({
        pathname: "/sipEditCheckout/",
        state: {
          request_mode: request_mode,
          selected_order: Object.assign(selected_order, {
            bank_information: this.state.bank_information,
            request_mode: request_mode,
          }),
        },
      });
    } else {

      let _orderList = (selected_order["mf_order_action_list"] || []);

      if( _orderList.length > 0 ) 
      {        
        selected_order["order_information"] =
          selected_order['mf_order_action_list'][_orderList.length - 1];
      }

      selected_order["bank_information"] = this.state.bank_information;
      selected_order["request_mode"] = request_mode;

      console.log(selected_order);

      this.props.history.push({
        pathname: "/sipEditCheckout/",
        state: { selected_order: selected_order },
      });
    }
  }

  showChildInformation(order_type, order, request_type) {

    this.setState({ isloading: true });

    if (request_type === "view") {
      this.props
        .loadPaymentResponse({
          request_mode: "order_payment_status",
          order_type: order_type,
          order_number: order["mf_order_number"] || "",
          order_id: order["_id"],
          selected_order: order,
          check_mandate_status: true,
        })
        .then((response) => {
          this.setState({ isloading: false })
          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {
            let _result = this.props.order_result["result"];

            console.log(_result);

            if (_result) {

              let _list = this.state['list_' + order_type + '_orders'];
              let _new_list = [];

              _list.forEach(el => {

                if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {
                  _result['show_child'] = true;
                  _new_list.push(_result);
                }
                else
                  _new_list.push(el);
              });

              // _list.forEach((el) => {
              //   if (
              //     order["mf_order_registration_no"] ===
              //     el["mf_order_registration_no"]
              //   ) {

              //     el = _result;
              //     el["show_child"] = true;
              //   }
              // });

              this.setState({ ["list_" + order_type + "_orders"]: _new_list });

            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      let _list = this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (
          order["mf_order_registration_no"] === el["mf_order_registration_no"]
        )
          el["show_child"] = request_type === "view" ? true : false; // !el['show_child'];
      });
      this.setState({ isloading: false })
      this.setState({ ["list_" + order_type + "_orders"]: _list });
    }
  }

  showChildHistoryInformation(order_type, order, request_type) {
    let _list = this.state["list_" + order_type + "_orders"];

    _list.forEach((el) => {
      if (order["mf_order_registration_no"] === el["mf_order_registration_no"])
        el["show_child_history"] = request_type === "view" ? true : false;
    });

    this.setState({ ["list_" + order_type + "_orders"]: _list });
  }

  showSwpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result;

      _list.forEach((el) => {
        if (order["mf_order_registration_no"] === el["mf_order_registration_no"]
        ) {
          el["show_swp"] = (request_type === 'view'); //true;
        }
      });

      this.setState({
        ["list_" + order_type + "_orders"]: _list
      });

    }
  }

  closePopup = () => {
    this.setState({
      show_pause_popup: false
    })
  }

  onCancelOrder = (order_type, order) => {
    this.setState({ isloading: true })
    console.log(order);
    this.props
      .cancelSipOrder({
        order_type: order_type,
        client_info: order,
      })
      .then((response) => {
        this.setState({ isloading: false })
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          alert(this.props.order_result["messageText"]);
          if (this.props.order_result["result"])
            this.setState({
              bank_information: this.props.order_result["bank_information"],
              ["list_" + order_type + "_orders"]:
                this.props.order_result["result"],
            });
          else this.setState({ ["list_" + order_type + "_orders"]: [] });
        }

      })
      .catch((err) => {
        alert(err);
      });
  };

  onPauseOrder = (order_type, order, mode) => {

    if (mode === 'initiate') {

      let _selected_pause_order = order;
      _selected_pause_order['pause_installment_list'] = [];
      _selected_pause_order['pause_no_of_installments'] = '';
      let _list = [];
      if (order['mf_order_is_pause_allowed']) {

        for (let iRec = parseInt(order['mf_order_pause_minimum_installments']);
          iRec <= parseInt(order['mf_order_pause_maximum_installments']); iRec++) {
          _list.push({ value: iRec, label: iRec });
        }
        _selected_pause_order['pause_installment_list'] = _list;
      }

      this.setState({
        show_pause_popup: true, selected_pause_order: _selected_pause_order
      });

    }
    else {

      this.setState({ isloading: true });

      this.props
        .pauseSipOrder({
          order_type: order_type,
          client_info: order,
        })
        .then((response) => {
          this.setState({ isloading: false })
          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {
            // alert(this.props.order_result["messageText"]);
            if (this.props.order_result["result"])
              this.setState({
                bank_information: this.props.order_result["bank_information"],
                ["list_" + order_type + "_orders"]:
                  this.props.order_result["result"],
              });
            else
              this.setState({ ["list_" + order_type + "_orders"]: [] });

            this.props.history.push({
              pathname: "/fyInformation/",
              state: { display_html: this.props.order_result['display_html'] || '' },
            });
          }

        })
        .catch((err) => {
          alert(err);
        });

    }

  };

  handleChangeSelect = (selectedOption, order, input, category) => {

    if (input === 'swp_frequency') {

      if (category === 'swp') {

        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_frequency: selectedOption.value
          }
        });

      }

    }
    else if (input === 'swp_no_of_installments') {

      if (category === 'swp')
        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_no_of_installments: selectedOption.value
          }
        });

    }

  }

  OnCreateSwpOrder = (order_type, order) => {

    console.log(this.state.swp_order);

    this.props
      .createSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
              else
                _new_list.push(el);

            });

            this.setState({ ["list_" + order_type + "_orders"]: _new_list });

          }
          // else 
          //   this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  OnCancelSwpOrder = (order_type, order) => {

    console.log(this.state.swp_order);

    this.props
      .cancelSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.setState({ ["list_" + order_type + "_orders"]: _new_list });

          }
          // else 
          //   this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  routeChange = (type, item, mode) => {

    /*if((window.matchMedia && window.matchMedia("(max-width: 480px)").matches) ){
        this.props.history.push({ 
          pathname: "/chartsmobile", 
          state: { param_data: {
              scheme_isin: item['scheme_isin'],
              scheme_code: item['scheme_code'], 
              scheme_name: item['scheme_name'] 
            }
          }
        });
    }
    else */
    console.log(item)
    {

      const win = window.open("/sipConsolidatedStatement/" + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/' + item['mf_order_type'].toLowerCase(), "_blank");
      win.focus();

    }

  };

  render() {
    
    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="content-body" style={{ marginTop: 105 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 style={{marginLeft:20}}> XSIP Order list</h4>
                  </div>
                  <div className="card-body">
                    {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          zIndex: 899,
                        }}
                      />
                    )}


                    <div className="transaction-table">
                      <div className="table-responsive">
                        {this.state.list_xsip_orders.length < 1 && (
                          <Alert variant="dark text-center">
                            <Alert.Heading>
                              {this.state.isloading
                                ? "We are fetching your orders"
                                : "No orders found"}
                            </Alert.Heading>
                          </Alert>
                        )}

                        <table class="table mb-0 table-responsive-sm bordered ">
                          {/* {(this.state.list_xsip_orders || []).length > 1 && (
                            <thead>
                              <tr>
                                <th style={{ fontSize: 12 }}>#</th>
                                <th style={{ fontSize: 12 }}>Order Information</th>
                              </tr>
                            </thead>
                          )} */}

                          {(this.state.list_xsip_orders || []).map(
                            (item, index) => (
                              <tbody key={index}>

                                <tr>
                                  {/* <td style={{ fontSize: 12, color: "#000" }}>{index + 1}</td> */}

                                  <td >

                                    {item["mf_order_number"].length < 4 ? (
                                      <p>
                                        <span style={{
                                          color: 'InfoText',
                                          backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                          fontWeight: 500,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }}>
                                          Registration Number:{" "}
                                          {item["mf_order_registration_no"]}

                                        </span>
                                        <span style={{
                                          fontSize: 12, color: 'InfoText',
                                          backgroundColor: 'lightgreen',
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }} >
                                          {item["mf_order_installment_frequency"] || ``}
                                        </span>
                                      </p>
                                    ) : (
                                      <p>
                                        <span style={{
                                          color: 'InfoText',
                                          backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                          fontWeight: 500,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }}>
                                          Order Number: {item["mf_order_number"]}
                                        </span>
                                        {item["mf_order_folio_no"].length > 4 &&
                                          <span style={{
                                            color: 'InfoText',
                                            backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                            fontWeight: 500,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                          }}>
                                            Folio Number: {item["mf_order_folio_no"]}
                                          </span>
                                        }
                                        <span style={{
                                          fontSize: 12, color: 'InfoText',
                                          backgroundColor: 'lightgreen',
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }} >
                                          {item["mf_order_installment_frequency"] || ``}
                                        </span>
                                        {
                                          item['mf_order_swp_enabled'] &&
                                          <span style={{
                                            fontFamily:'sans-serif',
                                            color: 'InfoText',
                                            backgroundColor: '#ec9fa4', fontWeight: 500,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                          }} >
                                            SWP Enabled : {item["mf_order_swp_information"]['swp_frequency']} OF
                                            &#8377;{item["mf_order_swp_information"]['swp_amount']}
                                          </span>
                                        }
                                      </p>

                                    )}
                                    <p
                                      style={{
                                        fontSize: 13
                                      }}
                                    >
                                      {item["mf_order_scheme_name"] || ""}
                                    </p>

                                    <p
                                      style={{
                                        fontSize: 12
                                      }}
                                    >
                                      <span style={{ width: 100 }}>
                                        {item["mf_order_message"]}
                                      </span>
                                    </p>
                                    <p>

                                      {`   `}
                                      <ul className="list-horizontal">
                                      Invested <li>
                                          <span style={{
                                            fontFamily:'sans-serif',
                                            color: 'InfoText',
                                            backgroundColor: '#e6e3e3',
                                            fontWeight: 500,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                          }}>
                                            &#8377;{item['mf_order_overall_investment_amount']}
                                          </span>
                                        </li>
                                        <li>
                                        Returns <span style={{
                                          fontFamily:'sans-serif',
                                            color: 'InfoText',
                                            backgroundColor: '#f8f2f2',
                                            fontWeight: 500,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                            color: parseFloat(item['total_returns_amount'] || 0) - 
                                              parseFloat(item['mf_order_overall_investment_amount']) < 0
                                                ? "red"
                                                : (parseFloat(item['total_returns_amount'] || 0) - 
                                                  parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                          }}>
                                            &#8377;{item['total_returns_amount']}
                                          </span>
                                        </li>
                                        <li>
                                        Gain/Loss <span style={{
                                            fontFamily:'sans-serif',
                                            fontSize: 12,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                            backgroundColor: '#e6e3e3',
                                            color:
                                              parseFloat(item['returns_difference_amount'] || 0) < 0
                                                ? "red"
                                                : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green'
                                          }}>&#8377;{
                                              item['returns_difference_amount'] || 0
                                            }
                                          </span>

                                        </li>
                                        <li>
                                        Returns Percent<span style={{
                                            fontSize: 12,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                            // backgroundColor: '#e6e3e3',
                                            color:
                                              parseFloat(item['returns_difference_amount']) < 0
                                                ? "red"
                                                : parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green'
                                          }}>{item['total_returns_percent']}%</span>
                                        </li>
                                      </ul>


                                    </p>

                                  </td>
                                  
                                  {item['is_swp_allowed'] &&
                                    (item['mf_order_swp_enabled'] ?
                                      <td>
                                        <button
                                          className="btn btn-warning"
                                          style={{ fontSize: 12 }}
                                          onClick={() => {
                                            this.OnCancelSwpOrder("xsip", item);
                                          }}
                                        >
                                          Cancel SWP
                                        </button>
                                      </td>
                                      :
                                      <td>
                                        {item["show_swp"] && (
                                          <button
                                            className="btn btn-danger"
                                            variant="success"
                                            onClick={() => {
                                              this.showSwpInformation(
                                                "xsip",
                                                item,
                                                "hide"
                                              );
                                            }}
                                          >
                                            Hide
                                          </button>
                                        )}
                                        {!item["show_swp"] && (
                                          <button type="button"
                                            className="btn btn-primary"
                                            style={{ color: 'white', width: '120px' }}
                                            onClick={() => {
                                              this.showSwpInformation(
                                                "xsip",
                                                item,
                                                "view"
                                              );
                                            }}
                                          >
                                            Start SWP
                                          </button>
                                        )}
                                      </td>
                                    )
                                  }
                                  <td>
                                    {item["show_child"] && (
                                      <Button
                                        className="btn btn-danger"
                                        variant="success"
                                        onClick={() => {
                                          this.showChildInformation(
                                            "xsip",
                                            item,
                                            "hide"
                                          );
                                        }}
                                      >
                                        Hide
                                      </Button>
                                    )}
                                    {!item["show_child"] && (
                                      <button type="button"
                                        className="btn btn-success"
                                        style={{ color: 'black' }}
                                        onClick={() => {
                                          this.showChildInformation(
                                            "xsip",
                                            item,
                                            "view"
                                          );
                                        }}
                                      >
                                        View
                                      </button>
                                    )}
                                  </td>

                                </tr>
                                {item["show_child"] &&
                                  (item["display_installment_info_html"] || "")
                                    .length > 7 && (
                                    <tr>
                                      
                                      <td colSpan={11} style={{ padding: 0, fontSize: 12 }}>

                                        <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                          <CardContent  >
                                            <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                                              dangerouslySetInnerHTML={this.createDefaultMarkup(
                                                item[
                                                "display_installment_info_html"
                                                ]
                                              )}
                                            />
                                            <div>
                                              <button type="button"
                                                className="btn btn-warning"
                                                style={{ color: 'black' }}
                                                onClick={() => {
                                                  this.onPauseOrder(
                                                    "xsip",
                                                    item,
                                                    'initiate'
                                                  );
                                                }}
                                                disabled={!item["mf_order_is_cancel_order"]}
                                              >
                                                Pause Order
                                              </button>
                                              {'   '}
                                              <button type="button"
                                                className="btn btn-danger"
                                                style={{ color: 'black' }}
                                                onClick={() => {
                                                  this.onCancelOrder(
                                                    "xsip",
                                                    item
                                                  );
                                                }}
                                                disabled={!item["mf_order_is_cancel_order"]}
                                              >
                                                Cancel Order
                                              </button>
                                              {'   '}
                                              <button type="button"
                                                className="btn btn-success"
                                                style={{ color: 'black' }}
                                                onClick={() => {
                                                  this.routeChange(
                                                    "xsip",
                                                    item,
                                                    'initiate'
                                                  );
                                                }}
                                                disabled={!item["mf_order_is_cancel_order"]}
                                              >
                                                View Statement
                                              </button>
                                            </div>

                                          </CardContent>
                                        </Card>
                                      </td>
                                    </tr>
                                  )}

                                {item["show_swp"] && (
                                  <tr>
                                    <td colSpan={7}>
                                      <tr>
                                        <td>
                                          <div class="form-group">
                                            <p class="mr-sm-2 text-gray-dark">Choose Installments</p>

                                            <Select
                                              value={item['swp_installment_numbers_list'].find(obj => obj.value === this.state.swp_order['swp_no_of_installments'] || '')}
                                              onChange={(e) => this.handleChangeSelect(e, item, 'swp_no_of_installments', 'swp')}
                                              options={item['swp_installment_numbers_list']}
                                              isSearchable={false}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <p class="mr-sm-2 text-gray-dark">Choose Frequency</p>
                                            {item['swp_frequency_list'] &&
                                              <Select
                                                value={item['swp_frequency_list'].find(obj => obj.value === this.state.swp_order['swp_frequency'] || '')}
                                                onChange={(e) => this.handleChangeSelect(e, item, 'swp_frequency', 'swp')}
                                                options={item['swp_frequency_list']}
                                                isSearchable={false}
                                              />
                                            }
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <br />
                                            <br />
                                            <p class="mr-sm-2 text-gray-dark">Enter Amount</p>
                                            {/* <div class="input-group mb-3"> */}
                                            <input
                                              style={{ width: 200 }}
                                              type="text"
                                              name="swp_amount"
                                              class="form-control"
                                              placeholder=""
                                              maxLength="11"
                                              value={this.state.swp_order['swp_amount']}
                                              onKeyPress={event => {
                                                if (event.target.key === 'Enter') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={event => {
                                                const targetValue = event.target.value.replace(/\s/g, '');

                                                if (targetValue.length === 0)
                                                  this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });
                                                else {

                                                  if (targetValue.match(/^[0-9]+$/))
                                                    if (parseFloat(targetValue) <= parseFloat(item['maximum_swp_amount']))
                                                      this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });

                                                }

                                              }

                                              }
                                            />
                                            <p >
                                              <span style={{ fontFamily:'sans-serif', fontFamily:'sans-serif', color: `#733dd1` }}>
                                                Min. &#8377;{item['minimum_swp_amount']} {`  `}</span>
                                              <span style={{ fontFamily:'sans-serif', fontFamily:'sans-serif', color: `#0f967b` }}>Max. &#8377;{item['maximum_swp_amount']}
                                              </span>
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <p class="mr-sm-2 text-gray-dark"><br /></p>
                                            <p class="mr-sm-2 text-gray-dark">SWP Date</p>
                                            <DatePicker className="form-control"
                                              selected={this.state.swp_order.swp_date}
                                              onChangeRaw={(e) => e.preventDefault()}
                                              onChange={(date) => {
                                                this.setState({
                                                  swp_order: { ...this.state.swp_order, swp_date: date }
                                                })
                                              }
                                              }
                                              minDate={this.state.swp_minimum_date}
                                              maxDate={this.state.swp_maximum_date}
                                              shouldCloseOnSelect={true}
                                              excludeDates={item['swp_exclude_date_list']}
                                            />
                                            <p style={{ color: `#733dd1` }}>
                                              1st order will be placed on {new Date(this.state.swp_order['swp_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <br />
                                            <br />
                                            <button
                                              className="btn btn-warning"
                                              style={{ fontSize: 12 }}
                                              onClick={() => {
                                                this.OnCreateSwpOrder("xsip", item);
                                              }}
                                            >
                                              Confirm SWP
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </td>
                                  </tr>
                                )}

                                <tr>
                                  {item["show_child_history"] && (
                                    <td colSpan={5}>
                                      <Collapse in={item["show_child_history"]}>
                                        <div>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <Tabs
                                              value={
                                                this.state
                                                  .tab_single_order_child
                                              }
                                              onChange={(event, newValue) => {
                                                this.setState({
                                                  tab_single_order_child:
                                                    newValue,
                                                });
                                              }}
                                              aria-label="basic tabs example"
                                            >
                                              <Tab
                                                label="TRANSACTIONS"
                                                {...a11yProps(0)}
                                              />
                                              <Tab
                                                label="PAYMENT HISTORY"
                                                {...a11yProps(1)}
                                              />
                                            </Tabs>

                                            <TabPanel
                                              value={
                                                this.state
                                                  .tab_single_order_child
                                              }
                                              index={0}
                                            >
                                              <div class="buy-sell-widget">
                                                <div id="chart-timeline">
                                                  <Table>
                                                    {(
                                                      item["order_list"] || []
                                                    ).map((item, index) => (
                                                      <tbody key={index}>
                                                        <tr>
                                                          <td>
                                                            <h4 class="card-title">
                                                              <h6
                                                                style={{
                                                                  fontSize: 12,
                                                                }}
                                                              >
                                                                {
                                                                  item[
                                                                  "mf_order_message"
                                                                  ]
                                                                }
                                                              </h6>
                                                              <h6
                                                                style={{
                                                                  fontSize: 12,
                                                                }}
                                                              >
                                                                Initiated on{" "}
                                                                {
                                                                  item[
                                                                  "created_date"
                                                                  ]
                                                                }
                                                              </h6>
                                                            </h4>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    ))}
                                                  </Table>
                                                </div>
                                              </div>
                                            </TabPanel>
                                          </Box>
                                        </div>
                                      </Collapse>
                                    </td>
                                  )}
                                </tr>

                              </tbody>
                            )
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.show_pause_popup && this.state.selected_pause_order['order_id'] &&
          <Modal show={this.state.show_pause_popup && this.state.selected_pause_order['order_id']} onHide={() => this.closePopup()}>
            <Modal.Header>
              <h5>Pause Order</h5>
            </Modal.Header>
            <Modal.Body>

              <div className="auth-form card">
                {this.state.isloading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                    }} />)}
                <div className="card-body">
                  <div className="form-group">
                    <label>Choose your installments</label>
                    <Select
                      value={this.state.selected_pause_order['pause_installment_list'].find(object =>
                        object.value === this.state.selected_pause_order['pause_no_of_installments'] || '')
                      }
                      onChange={(e) => {
                        this.setState({
                          selected_pause_order:
                            { ...this.state.selected_pause_order, pause_no_of_installments: e.value }
                        })
                      }
                      }
                      options={this.state.selected_pause_order['pause_installment_list']}
                    />
                  </div>
                  {1 === 1 && (
                    <div className="text-center" style={{ marginTop: 10 }}>
                      <Button
                        className="btn btn-success btn-block"
                        variant="success"
                        onClick={() => this.onPauseOrder("xsip", this.state.selected_pause_order, 'confirm')}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </div>

              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => this.setState({ show_pause_popup: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </>
    );
  }
}

// SipList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadOrderList,
  loadPaymentResponse,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  cancelSwpOrder
})(SipList);
