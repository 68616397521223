import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { sendOtp, validateOtp } from '../../jsx/actions/auth';
import { Redirect } from 'react-router-dom';
import Popup from './popup';
import Background from '../../images/background/bg-14.jpg';
import OTPInput, { ResendOTP } from "otp-input-react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

//import Spinner from "../layout/spinner";
class Otp2 extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            otp: '',
            password: '',
            data: '',
            show_popup: false,
            popup_message: '',
            isloading: this.props.loading,
            route_parameters: null
        };

    }

    componentDidMount() {

        console.log(this.props.location.state);

        if (this.props.location && this.props.location.state && this.props.location.state.route_parameters) {

            this.setState({
                isloading: false,
                route_parameters: this.props.location.state.route_parameters
            });
            window.history.replaceState(null, '');

        }

    }

    closePopup = () => {
        this.setState({
            show_popup: false
        })
    }

    sendOTP = () => {

        this.setState({
            isloading: true,
            otp: ''
        });

        let body_parameters = this.state.route_parameters;

        this.props
            .sendOtp(body_parameters)
            .then((response) => {

                if (this.props.login_otp_result["code"] !== "000")
                    this.setState({
                        show_popup: true
                    });
                this.setState({ isloading: false })

            })
            .catch((err) => {
                alert(err);
            });

    };

    onSubmit = () => {

        const { otp } = this.state;
        this.setState({
            isloading: true
        });

        this.props.validateOtp({
            "login_otp": otp,
            "login_role": "customer"
        }).then(response => {

            this.setState({
                isloading: false
            });

            if (this.props.login_otp_result['code'] === '000')
                this.props.history.push({
                    pathname: '/' + this.props.login_otp_result['navigateScreen'] + '/',
                    state: { otp_result: this.props.login_otp_result }
                });
            else
                this.setState({
                    otp: '',
                    show_popup: true,
                    popup_message: this.props.login_otp_result['messageText']
                });

        })
            .catch(err => {
                alert(err)
            });


    };

    render() {

        // const renderButton = buttonProps => {
        //     return ( 
        //             <span>
        //             <div>
        //                 <button onClick={this.sendOTP} 
        //                     class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}
        //                     // disabled={buttonProps.remainingTime !== 0}
        //                 >
        //                     {`Resend OTP`}
        //                 </button>
        //             </div>
        //             <div style={{textAlign:'left'}}>
        //             {buttonProps.remainingTime !== 0
        //                 ? ` Didn't receive the otp ? You can resend otp in ${buttonProps.remainingTime} secs.`
        //                 : ``}
        //             </div>
        //             </span>
        //     );
        // };

        const renderButton = buttonProps => {
            return (
                <div>
                    <button onClick={this.sendOTP} {...buttonProps}
                        class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
                        {`Resend OTP`}
                    </button>
                    <div style={{ textAlign: 'left' }}>
                        {buttonProps.remainingTime !== 0
                            ? <span>Didn't receive the otp ? You can resend otp in
                                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.</span>
                            : ``}
                    </div>
                </div>
            );
        };
        const renderTime = () => React.Fragment;
        // if(this.props.login_otp_result && this.props.login_otp_result['code'] === '000') {
        //     return <Redirect to = {this.props.login_otp_result['navigateScreen']} />;
        // }

        return (

            <>

                {this.props.loading && <Spinner animation="border" variant="dark"
                    style={{ position: "fixed", top: "50%", left: "50%" }} />}
                <div style={{
                    background: `url(${Background})`,
                    backgroundRepeat: 'repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',

                }}>
                    <div className="authincation section-padding" >
                        <div className="container h-100">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-xl-5 col-md-6">
                                    <div className="mini-logo text-center my-1">
                                        <Link to={'/otp-2'}><img src={require('./../../images/logo.png')} alt="" width="200px" /></Link>
                                    </div>


                                    <div className="auth-form card">
                                        <div className="card-body">
                                            {this.state.isloading &&
                                                <Spinner animation="border" variant="dark"
                                                    style={{ position: "fixed", top: "50%", left: "50%" }} />
                                            }
                                            <Link className="page-back text-muted" to={'/signin'}><span><i
                                                className="fa fa-angle-left"></i></span> Back</Link>

                                            {!this.state.route_parameters &&
                                                <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                                    <CardContent  >
                                                        <Stack direction="row">
                                                            <Typography flex={1} whiteSpace="pre-line" >
                                                                Sorry the information you are looking for could not be found
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                            }


                                            {this.state.route_parameters &&

                                                <form action="#">
                                                    <h4 className="text-center">OTP Verification</h4>
                                                    <p className="text-center mb-5">You will receive an OTP on your registered mobile number, enter the same to continue</p>

                                                    <div className="form-group">
                                                        <label>Enter the OTP</label>
                                                        <br />
                                                        {/* <input type="text" className="form-control text-center font-weight-bold"
                                            value={this.state.otp}  onChange={event => this.setState({ otp: event.target.value })} /> */}

                                                        <OTPInput
                                                            value={this.state.otp}
                                                            onChange={event => {
                                                                this.setState({ otp: event })
                                                            }}
                                                            autoFocus
                                                            OTPLength={6}
                                                            otpType="number"
                                                            disabled={false}

                                                            secure
                                                            isInputNum={true}
                                                        />
                                                    </div>
                                                    {/* <ResendOTP renderTime={`<span>{remainingTime} seconds remaining</span>`} 
                                        handelResendClick={() => console.log("Resend clicked")} /> */}

                                                    <div className="text-left">
                                                        <ResendOTP renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                                            onResendClick={(e) => { this.sendOTP() }}
                                                        // handelResendClick={() => this.sendOTP()} 
                                                        />
                                                        <hr />
                                                    </div>

                                                    <div className="text-center">
                                                        <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            onClick={this.onSubmit}
                                                            disabled={this.state.otp.length < 6}
                                                        >Verify</Button>
                                                    </div>
                                                </form>
                                            }

                                        </div>
                                    </div>

                                    {
                                        this.state.show_popup &&

                                        <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                                            <Modal.Header>

                                            </Modal.Header>
                                            <Modal.Body>
                                                <p>  {this.state.popup_message}</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={() => this.closePopup()}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    }
                                </div>
                            </div>
                        </div>
                    </div></div>
            </>
        )
    }

}

Otp2.propTypes = {
    validateOtp: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    login_otp_result: state.auth.login_otp_result
});
export default connect(mapStateToProps, { sendOtp, validateOtp })(Otp2);
