export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_MOBILE_SUCCESS = 'LOGIN_MOBILE_SUCCESS';
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS';
export const EMAIL_OTP_SUCCESS = 'EMAIL_OTP_SUCCESS';
export const PAYMENT_INITIATE_SUCCESS = 'PAYMENT_INITIATE_SUCCESS';

export const LOGIN_OTP_FAIL = 'LOGIN_OTP_FAIL';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CHART_LIST ='CHART_LIST';
export const CHART_FAIL ='CHART_FAIL';
export const LIST_SCHEME = 'LIST_SCHEME';
export const LIST_FAIL = 'LIST_FAIL';

export const VERIFY_PAN_SUCCESS = 'VERIFY_PAN_SUCCESS';
export const VERIFY_AADHAAR_SUCCESS = 'VERIFY_AADHAAR_SUCCESS';
export const VERIFY_BANK_SUCCESS = 'VERIFY_BANK_SUCCESS';
export const VERIFY_PERSONAL_SUCCESS = 'VERIFY_PERSONAL_SUCCESS';
export const VERIFY_CLIENT_LIST_SUCCESS = 'VERIFY_CLIENT_LIST_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';

export const ORDER_LIST_LOAD = 'ORDER_LIST_LOAD';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
