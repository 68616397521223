import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import master from './master';
import verify from './verify';
import charts from './charts';
import order from './orders';


export default combineReducers({
  alert,
  auth,
  master,
  verify,
  charts,
  order
});
