import React from "react";
import { Link } from "react-router-dom";
import { Form, Alert, Modal, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select';
import {
  loadOrderList,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  cancelSwpOrder
} from "../../actions/orders";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
//import Spinner from "../../layout/spinner";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Table, Collapse } from "react-bootstrap";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab_orders: 0,
      tab_single_order_child: 0,
      cardopen: false,
      list_single_orders: [],
      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_frequency: ''
      },
      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),
      swp_exclude_date_list: [],

      bank_information: {},
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_popup: false,
      popup_headerText: '',
      popup_messageText: ''
    };
  }

  componentDidMount() {
    this.setState({ isloading: true });
    this.onLoadOrderList(0);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadOrderList = (order_type) => {

    order_type = order_type === 0 ? "single" : 0; //(order_type === 1 ? 'sip' : 'xsip')

    this.props
      .loadOrderList({
        order_type: order_type,
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          if (this.props.order_result["result"]) {
            this.setState({
              bank_information: this.props.order_result["bank_information"],
              ["list_" + order_type + "_orders"]:
                this.props.order_result["result"],
              isloading: false
            });

          }
          else
            this.setState({
              ["list_" + order_type + "_orders"]: [],
              isloading: false,
            });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  gotoCheckOutPage(request_mode, selected_order) {

    this.setState({ isloading: true });

    if ( (selected_order["mf_order_action_list"] || []).length > 0 ) 
    {
      
      selected_order["order_information"] =
        selected_order['mf_order_action_list'][selected_order["mf_order_action_list"].length - 1];
    }

    selected_order["bank_information"] = this.state.bank_information;
    selected_order["request_mode"] = request_mode;
    this.setState({ isloading: false });

    /*
    if(request_mode === 'paynow') {
        const win = window.open("/orderEditCheckout/" + request_mode.toLowerCase(), "_blank");
        win.focus();
    }
    else*/
    this.props.history.push({
      pathname: "/orderEditCheckout/",
      state: {
        request_mode: request_mode,
        selected_order: selected_order,
      },
    });


  }

  routeChange = (type, item, mode) => {

    /*if((window.matchMedia && window.matchMedia("(max-width: 480px)").matches) ){
        this.props.history.push({ 
          pathname: "/chartsmobile", 
          state: { param_data: {
              scheme_isin: item['scheme_isin'],
              scheme_code: item['scheme_code'], 
              scheme_name: item['scheme_name'] 
            }
          }
        });
    }
    else */
    console.log(item)
    {

      const win = window.open("/sipConsolidatedStatement/" + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/' + item['mf_order_type'].toLowerCase(), "_blank");
      win.focus();

    }

  };

  showChildInformation(order_type, order, request_type) {

    this.setState({ isloading: true });
    if (request_type === "view") {
      this.props
        .loadPaymentResponse({
          request_mode: "order_payment_status",
          order_type: "single",
          order_number: order["mf_order_number"] || "",
          order_id: order["_id"],
          selected_order: order,
        })
        .then((response) => {
          this.setState({ isloading: false });

          if (this.props.order_result) {

            if (this.props.order_result["code"] !== "000")
              alert(this.props.order_result["messageText"]);
            else {

              let _result = this.props.order_result["result"];
              if (_result) {

                let _list = this.state["list_" + order_type + "_orders"];

                console.log(_list);

                for (let rec in _list) {
                  if (
                    order["mf_order_number"] === _list[rec]["mf_order_number"]
                  ) {
                    _list[rec] = _result;
                    break;
                  }
                }

                _list.forEach((el) => {
                  if ( order["mf_order_number"] === el["mf_order_number"] ) 
                  {
                    el["show_child"] = true;
                  }
                });

                console.log(3);

                this.setState({ ["list_" + order_type + "_orders"]: _list });
                console.log(_list);
              }
            }

          }

        })
        .catch((err) => {
          alert(err);
        });
    } else {
      let _list = this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (
          order["mf_order_registration_no"] === el["mf_order_registration_no"]
        )
          el["show_child"] = request_type === "view" ? true : false; // !el['show_child'];
      });
      this.setState({ isloading: false });
      this.setState({ ["list_" + order_type + "_orders"]: _list });
    }
  }

  showSwpInformation(order_type, order, request_type) {

    let _result = this.props.order_result["result"];

    if (_result) {

      let _list = _result; //this.state["list_" + order_type + "_orders"];

      // for (let rec in _list) {
      //   if (
      //     order["mf_order_registration_no"] ===
      //     _list[rec]["mf_order_registration_no"]
      //   ) {
      //     _list[rec] = _result;
      //     break;
      //   }
      // }

      _list.forEach((el) => {
        if (order["mf_order_registration_no"] === el["mf_order_registration_no"]
        ) {
          el["show_swp"] = (request_type === 'view'); //true;
        }
      });

      this.setState({
        list_single_orders: _list || []
      });


    }
  }

  showChildHistoryInformation(order_type, order, request_type) {

    if (request_type === "view") {
      this.setState({ isloading: true });
    }

    let _list = this.state["list_" + order_type + "_orders"];

    _list.forEach((el) => {
      if (order["mf_order_registration_no"] === el["mf_order_registration_no"])
        el["show_child_history"] = request_type === "view" ? true : false;
    });

    this.setState({
      isloading: false,
      ["list_" + order_type + "_orders"]: _list,
    });
  }

  OnCancelOrder = (order_type, order) => {

    this.props
      .cancelOrder({
        order_type: order_type,
        client_info: order,
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["result"])
            this.setState({
              bank_information: this.props.order_result["bank_information"],
              ["list_" + order_type + "_orders"]:
                this.props.order_result["result"],
            });
          else this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeSelect = (selectedOption, order, input, category) => {

    if (input === 'swp_frequency') {

      if (category === 'swp') {

        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_frequency: selectedOption.value
          }
        });

      }

    }
    else if (input === 'swp_no_of_installments') {

      if (category === 'swp')
        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_no_of_installments: selectedOption.value
          }
        });

    }

  }

  OnCreateSwpOrder = (order_type, order) => {

    this.props
      .createSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
              else
                _new_list.push(el);

            });

            this.setState({ ["list_" + order_type + "_orders"]: _new_list });

          }
          // else 
          //   this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  OnCancelSwpOrder = (order_type, order) => {

    console.log(this.state.swp_order);

    this.props
      .cancelSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_single_order_child'] === _result['tab_single_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.setState({ ["list_" + order_type + "_orders"]: _new_list });

          }
          // else 
          //   this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  showTransactionHistory(item) {

    let _html = `<div>we will show you soon</div>`;

    this.setState({
      show_popup: true,
      popup_headerText: 'Transaction History',
      popup_messageText: item['modal_transaction_history_html'] || _html,
    })

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      popup_headerText: '',
      popup_messageText: ''
    });
  };

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  render() {
    
    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="content-body" style={{ marginTop: 105 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 style={{marginLeft:20}}>Lumpsum Order List</h4>
                  </div>
                  <div className="card-body">

                    {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          zIndex: 899,
                        }}
                      />
                    )}


                    {(this.state.list_single_orders || []).length < 1 && (
                      <Alert variant="dark text-center">
                        <Alert.Heading>
                          {this.state.isloading
                            ? "We are fetching your orders"
                            : "No orders found"}
                        </Alert.Heading>
                      </Alert>
                    )}

                    <table class="table">
                      {/* {(this.state.list_single_orders || []).length > 0 && (
                        <thead>
                          <tr>
                            <th style={{ fontSize: 12 }}>#</th>
                            <th style={{ fontSize: 12 }}>Order Information</th>

                          </tr>
                        </thead>
                      )} */}

                      {(this.state.list_single_orders || []).map(
                        (item, index) => (
                          <tbody key={index}>

                            <tr>
                              {/* <td style={{ width:7, fontSize: 12, color: "#000" }}>{index + 1}</td> */}

                              <td >

                                {(item["mf_order_number"] || '').length < 4 ? (

                                  <p>
                                    <span style={{
                                      color: 'InfoText',
                                      backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                      fontWeight: 500,
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                    }}>
                                      Registration Number:{" "}
                                      {item["mf_order_registration_no"]}

                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span style={{
                                      color: 'InfoText',
                                      backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                      fontWeight: 500,
                                      padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                    }}>
                                      Order Number: {item["mf_order_number"]}
                                    </span>
                                    {
                                      (item['mf_order_folio_no'] || '').length > 6 &&
                                      <span style={{
                                        color: 'InfoText',
                                        backgroundColor: item['mf_order_status'] === 'CXL' ? '#ec9fa4' : '#a6c5ed',
                                        fontWeight: 500,
                                        padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                      }}>
                                      Folio No:  {item['mf_order_folio_no']}
                                      </span>
                                    }
                                    {
                                      item['mf_order_swp_enabled'] &&

                                      <span style={{
                                        fontFamily:'sans-serif', color: 'InfoText',
                                        backgroundColor: '#ec9fa4', fontWeight: 500,
                                        padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                      }} >
                                        SWP Enabled : {item["mf_order_swp_information"]['swp_frequency']} OF
                                        &#8377;{item["mf_order_swp_information"]['swp_amount']}
                                      </span>
                                    }
                                    <Button
                                      className="text-success"
                                      variant="link"
                                      style={{ fontSize: 12 }}
                                      onClick={() => {
                                        this.showTransactionHistory(item)
                                      }}
                                    >
                                    show history
                                  </Button>
                                  </p>

                                )}
                                <p
                                  style={{
                                    fontSize: 13
                                  }}
                                >
                                  {item["mf_order_scheme_name"] || ""}
                                </p>

                                <p
                                  style={{
                                    fontSize: 12
                                  }}
                                >
                                  {" "}
                                </p>
                                {
                                  item['mf_order_message_is_showed'] &&
                                  <p
                                    style={{
                                      fontSize: 12
                                    }}
                                  >
                                    <span style={{ width: 100 }}>
                                      {item["mf_order_message"]}
                                    </span>
                                    {
                                      !item['mf_order_is_allotted'] &&

                                      <Button className="text-success"
                                        variant="link" onClick={() => {
                                          this.setState({
                                            show_popup: true,
                                            popup_headerText: item['modal_rta_validation_information']['header'],
                                            popup_messageText:
                                              item['modal_rta_validation_information']['body'],
                                          })
                                        }}>
                                        Why ? </Button>
                                    }

                                  </p>
                                }
                                <p>

                                  {`   `}
                                  <ul className="list-horizontal">
                                    <li>
                                      Invested<span style={{
                                        fontFamily:'sans-serif', color: 'InfoText',
                                        backgroundColor: '#e6c8c8',
                                        fontWeight: 500,
                                        padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                      }}>
                                        &#8377;{item['mf_order_overall_investment_amount']}
                                      </span>
                                    </li>
                                    <li>
                                      Returns<span style={{
                                        fontFamily:'sans-serif', 
                                        color: 'InfoText',
                                        backgroundColor: '#f8f2f2',
                                        fontWeight: 500,
                                        padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                        color: parseFloat(item['total_returns_amount'] || 0) -
                                          parseFloat(item['mf_order_overall_investment_amount']) < 0
                                          ? "#f57362"
                                          : (parseFloat(item['total_returns_amount'] || 0) -
                                            parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                      }}>
                                        &#8377;{item['total_returns_amount']}
                                      </span>
                                    </li>
                                    <li>
                                      Gain/Loss<span style={{
                                        fontFamily:'sans-serif',
                                        fontSize: 12,
                                        padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                        // backgroundColor: '#e6e3e3',
                                        color:
                                          parseFloat(item['returns_difference_amount'] || 0) < 0
                                            ? "#f57362"
                                            : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                                      }}>&#8377;{
                                          item['returns_difference_amount'] || 0
                                        }
                                      </span>

                                    </li>
                                    <li>
                                      Returns Percent<span style={{
                                        fontSize: 12,
                                        padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                        // backgroundColor: '#e6e3e3',
                                        color:
                                          parseFloat(item['returns_difference_amount']) < 0
                                            ? "#f57362"
                                            : parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green',
                                      }}>{item['total_returns_percent']}%</span>
                                    </li>
                                  </ul>


                                </p>
                              </td>
                              {/* <td style={{width:100}}>
                                <button
                                  className="btn btn-warning"
                                  style={{ fontSize: 12 }}
                                  onClick={() => {
                                    this.showTransactionHistory(item)
                                  }}
                                >
                                  History
                                </button>
                              </td> */}
                              
                              {item['is_swp_allowed'] && (item['mf_order_swp_enabled'] ?
                                <td>
                                  <button
                                    className="btn btn-warning"
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                      this.OnCancelSwpOrder("single", item);
                                    }}
                                  >
                                    Cancel SWP
                                  </button>
                                </td>
                                :
                                <td>
                                  {item["show_swp"] && (
                                    <button
                                      className="btn btn-danger"
                                      variant="success"
                                      onClick={() => {
                                        this.showSwpInformation(
                                          "single",
                                          item,
                                          "hide"
                                        );
                                      }}
                                    >
                                      Hide
                                    </button>
                                  )}
                                  {!item["show_swp"] && item['is_swp_allowed'] && (
                                    <button type="button"
                                      className="btn btn-primary"
                                      style={{ color: 'white', width: '120px' }}
                                      onClick={() => {
                                        this.showSwpInformation(
                                          "single",
                                          item,
                                          "view"
                                        );
                                      }}
                                    >
                                      Start SWP
                                    </button>
                                  )}
                                </td>
                              )
                              }
                              <td>
                                {item["show_child"] && (
                                  <Button
                                    className="btn btn-danger"
                                    variant="success"
                                    onClick={() => {
                                      this.showChildInformation(
                                        "single",
                                        item,
                                        "hide"
                                      );
                                    }}
                                  >
                                    Hide
                                  </Button>
                                )}
                                {!item["show_child"] && (
                                  <button type="button"
                                    className="btn btn-success"
                                    style={{ color: 'black' }}
                                    onClick={() => {
                                      this.showChildInformation(
                                        "single",
                                        item,
                                        "view"
                                      );
                                    }}
                                  >
                                    View
                                  </button>
                                )}
                              </td>

                            </tr>
                            {item["show_child"] &&
                              (item["display_installment_info_html"] || "")
                                .length > 7 && (
                                <tr>
                                  <td style={{ padding: 0, fontSize: 12 }}>
                                    <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                      <CardContent  >
                                        <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                                          dangerouslySetInnerHTML={this.createDefaultMarkup(
                                            item[
                                            "display_installment_info_html"
                                            ]
                                          )}
                                        />
                                        <div>
                                          {/* <Button
                                                variant="primary"
                                                size="sm"
                                                style={{ fontSize: 12 }}
                                                onClick={() => {
                                                  this.showChildHistoryInformation(
                                                    "single",
                                                    item,
                                                    item["show_child_history"] || false
                                                      ? "hide"
                                                      : "view"
                                                  );
                                                }}
                                              >
                                                {item["show_child_history"] || false
                                                  ? "Hide History"
                                                  : "View History"}
                                              </Button>  */}
                                          {` `}
                                          {item["mf_order_is_cancel_order"] && (
                                            <button
                                              className="btn btn-danger"
                                              style={{ fontSize: 12 }}
                                              onClick={() => {
                                                this.OnCancelOrder("single", item);
                                              }}
                                            >
                                              Cancel Order
                                            </button>
                                          )}
                                          {"    "}
                                          <Button
                                            disabled={
                                              item["mf_order_is_invest_more"] ===
                                              false
                                            }
                                            style={{ fontSize: 12 }}
                                            variant="primary"
                                            size="sm"
                                            onClick={() => {
                                              this.gotoCheckOutPage(
                                                "investmore",
                                                item
                                              );
                                            }}
                                          >
                                            Invest More
                                          </Button>
                                          {"    "}
                                          <Button
                                            disabled={
                                              item["mf_order_is_redeem"] === false
                                            }
                                            className="btn btn-warning"
                                            style={{ color: 'black' }}
                                            onClick={() => {
                                              this.gotoCheckOutPage("redeem", item);
                                            }}
                                          >
                                            Redeem
                                          </Button>
                                          {"    "}
                                          <Button
                                            disabled={
                                              item["mf_order_is_paynow"] === false
                                            }
                                            style={{ fontSize: 12 }}
                                            variant="primary"
                                            size="sm"
                                            onClick={() => {
                                              this.gotoCheckOutPage("paynow", item);
                                            }}
                                          >
                                            Pay Now
                                          </Button>
                                          {"    "}
                                          <button type="button"
                                            className="btn btn-success"
                                            style={{ color: 'black' }}
                                            onClick={() => {
                                              this.routeChange(
                                                "single",
                                                item,
                                                'initiate'
                                              );
                                            }}
                                            disabled={!item['is_view_statement_allowed']}
                                          >
                                            View Statement
                                          </button>

                                        </div>
                                      </CardContent>
                                    </Card>

                                  </td>
                                </tr>
                              )}

                            {item["show_swp"] && (
                              <tr>
                                <td colSpan={7}>
                                  <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                    <CardContent>
                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <p class="mr-sm-2 text-gray-dark">Choose Installments</p>

                                            <Select
                                              value={item['swp_installment_numbers_list'].find(obj => obj.value === this.state.swp_order['swp_no_of_installments'] || '')}
                                              onChange={(e) => this.handleChangeSelect(e, item, 'swp_no_of_installments', 'swp')}
                                              options={item['swp_installment_numbers_list']}
                                              isSearchable={false}
                                            />
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <p class="mr-sm-2 text-gray-dark">Choose Frequency</p>
                                            {item['swp_frequency_list'] &&
                                              <Select
                                                value={item['swp_frequency_list'].find(obj => obj.value === this.state.swp_order['swp_frequency'] || '')}
                                                onChange={(e) => this.handleChangeSelect(e, item, 'swp_frequency', 'swp')}
                                                options={item['swp_frequency_list']}
                                                isSearchable={false}
                                              />
                                            }
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <p class="mr-sm-2 text-gray-dark">Enter Amount</p>
                                            <input
                                              style={{ width: 200 }}
                                              type="text"
                                              name="swp_amount"
                                              class="form-control"
                                              placeholder=""
                                              maxLength="11"
                                              value={this.state.swp_order['swp_amount']}
                                              onKeyPress={event => {
                                                if (event.target.key === 'Enter') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={event => {
                                                const targetValue = event.target.value.replace(/\s/g, '');

                                                if (targetValue.length === 0)
                                                  this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });
                                                else {

                                                  if (targetValue.match(/^[0-9]+$/))
                                                    if (parseFloat(targetValue) <= parseFloat(item['maximum_swp_amount']))
                                                      this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });

                                                }

                                              }

                                              }
                                            />
                                            <ul className="list-horizontal">
                                              <li>
                                                <span style={{ fontFamily:'sans-serif', color: `#733dd1` }}>
                                                  Min. &#8377;{item['minimum_swp_amount']} {`  `}</span>
                                              </li>
                                              <li>
                                                <span style={{ color: `#0f967b` }}>Max. &#8377;{item['maximum_swp_amount']}
                                                </span>
                                              </li>
                                            </ul>

                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <p class="mr-sm-2 text-gray-dark">SWP Date</p>
                                            <DatePicker className="form-control"
                                              selected={this.state.swp_order.swp_date}
                                              onChangeRaw={(e) => e.preventDefault()}
                                              onChange={(date) => {
                                                this.setState({
                                                  swp_order: { ...this.state.swp_order, swp_date: date }
                                                })
                                              }
                                              }
                                              minDate={this.state.swp_minimum_date}
                                              maxDate={this.state.swp_maximum_date}
                                              shouldCloseOnSelect={true}
                                              excludeDates={item['swp_exclude_date_list']}
                                            />
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <br />
                                            <br />
                                            <button
                                              className="btn btn-warning"
                                              style={{ fontSize: 12 }}
                                              onClick={() => {
                                                this.OnCreateSwpOrder("single", item);
                                              }}
                                            >
                                              Confirm SWP
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">

                                        <p style={{ color: `#733dd1` }}>
                                          Your 1st order will be placed on {new Date(this.state.swp_order['swp_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                                        </p>
                                      </div>
                                    </CardContent>
                                  </Card>

                                </td>
                              </tr>
                            )}

                            <tr>
                              {item["show_child_history"] && (
                                <td colSpan={5}>
                                  <Collapse in={item["show_child_history"]}>
                                    <div>
                                      <Box
                                        sx={{
                                          borderBottom: 1,
                                          borderColor: "divider",
                                        }}
                                      >
                                        <Tabs
                                          value={
                                            this.state
                                              .tab_single_order_child
                                          }
                                          onChange={(event, newValue) => {
                                            this.setState({
                                              tab_single_order_child:
                                                newValue,
                                            });
                                          }}
                                          aria-label="basic tabs example"
                                        >
                                          <Tab
                                            label="TRANSACTIONS"
                                            {...a11yProps(0)}
                                          />
                                          <Tab
                                            label="PAYMENT HISTORY"
                                            {...a11yProps(1)}
                                          />
                                        </Tabs>

                                        <TabPanel
                                          value={
                                            this.state
                                              .tab_single_order_child
                                          }
                                          index={0}
                                        >
                                          <div class="buy-sell-widget">
                                            <div id="chart-timeline">
                                              <Table>
                                                {(
                                                  item["order_list"] || []
                                                ).map((item, index) => (
                                                  <tbody key={index}>
                                                    <tr>
                                                      <td>
                                                        <h4 class="card-title">
                                                          <h6
                                                            style={{
                                                              fontSize: 12,
                                                            }}
                                                          >
                                                            {
                                                              item[
                                                              "mf_order_message"
                                                              ]
                                                            }
                                                          </h6>
                                                          <h6
                                                            style={{
                                                              fontSize: 12,
                                                            }}
                                                          >
                                                            Initiated on{" "}
                                                            {
                                                              item[
                                                              "created_date"
                                                              ]
                                                            }
                                                          </h6>
                                                        </h4>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                ))}
                                              </Table>
                                            </div>
                                          </div>
                                        </TabPanel>
                                      </Box>
                                    </div>
                                  </Collapse>
                                </td>
                              )}
                            </tr>

                            {/* {item["show_child"] && (
                              <tr>
                                {item["mf_order_notification_text"] &&
                                  item["mf_order_notification_text"]
                                    .length > 7 && (
                                    <td colSpan={5}>
                                      (
                                      <tr>
                                        <td colSpan={5}>
                                          <div
                                            className="text-dark"
                                            style={{ fontSize: 13 }}
                                            dangerouslySetInnerHTML={this.createDefaultMarkup(
                                              item[
                                              "mf_order_notification_text"
                                              ]
                                            )}
                                          />
                                        </td>
                                      </tr>
                                      )
                                    </td>
                                  )}
                                <td colSpan={5}>
                                  {item["mf_order_notification_message"] &&
                                    item["mf_order_notification_message"]
                                      .length > 7 && (
                                      <tr>
                                        <td colSpan={5}>
                                          <div
                                            className="text-dark"
                                            style={{ fontSize: 13 }}
                                            dangerouslySetInnerHTML={this.createDefaultMarkup(
                                              item[
                                              "mf_order_notification_message"
                                              ]
                                            )}
                                          />
                                        </td>
                                      </tr>
                                    )}
                                </td>
                              </tr>
                            )} */}
                          </tbody>
                        )
                      )}

                      {this.state
                        .show_popup && (
                          <Modal
                            show={
                              this.state
                                .show_popup
                            }
                            onHide={() =>
                              this.closePopup()
                            }
                          >
                            <Modal.Header>{this.state.popup_headerText}</Modal.Header>
                            <Modal.Body>
                              <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                                dangerouslySetInnerHTML={this.createDefaultMarkup(
                                  this.state.popup_messageText
                                )}
                              />

                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="primary"
                                onClick={() =>
                                  this.closePopup()
                                }
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

}

// OrderList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { order_result } = order;

  return {
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadOrderList,
  loadPaymentResponse,
  cancelOrder,
  createSwpOrder,
  cancelSwpOrder
})(OrderList);
