
import React from "react";
import { connect } from "react-redux";
import Header2 from "../layout/header2";
import Header1 from "../layout/header1";
import Sidebar from "../layout/sidebar";
import PageTitle from "../element/page-title";
import { ListGroup, Row, Col, Container, Spinner } from "react-bootstrap";
// import Spinner from "../layout/spinner";
import { Lines } from "react-preloaders";
// import BtcChart from '../charts/btc';
// import LtcChart from '../charts/ltc';
// import XrpChart from '../charts/xrp';
import AreaChart from "../charts/area";

// import RadialChart from '../charts/radial';
import Select, { components } from "react-select";
import { listSchemes } from "../../jsx/actions/master";
import Pagination from "@mui/material/Pagination";
import { Button, Badge, Collapse, Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import Emitter from '../utils/emitter';

const filter_conditional_parameters = ['growth', 'minimum_investment', 'investment_method'];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterList: JSON.parse(localStorage.getItem("masterList") || JSON.stringify({})),
      error: null,
      isLoaded: false,
      mutualFundList: [],
      pageIndex: 1,
      openCollapse: false,
      pageIndexAvailability: true,
      filterOption: {
        scheme_amc: /*selectedOptionAmc*/[],
        category: [],
        risk: [],
        sub_category: [],
        growth: [],
        minimum_investment: [],
        investment_method: [],
      },
      sub_category_list: [],
      selectedOptionAmc: [],
      selectedOptionCategory: [],
      selectedOptionRisk: [],
      selectedOptionSubCategory: [],
      selectedOptionGrowth: [],
      selectedOptionMinInvestment: [],
      selectedOptionInvestmentMethod: [],
      options: [],
      newValue: 0,
      scheme_list: [],
      perPage: 30,
      page: 1,
      pages: 0,
      loading: false
    };
  }

  componentDidMount() {

    this.setState({
      masterList: JSON.parse(localStorage.getItem("masterList") || JSON.stringify({}))
    });

    if (localStorage.getItem("profileData")) {
      Emitter.emit('HEADER1', JSON.parse(localStorage.getItem("profileData")));
      Emitter.emit('APPJS', 'VANAKKAM');
      this.loadSchemeList();
    }
    else
      this.props.history.push({
        pathname: '/signin'
      });

    // console.log(localStorage.getItem("token"));

  }

  handleChangeAmc = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, scheme_amc: selectedOption },
        selectedOptionAmc: selectedOption,
        page: 0
      },
      () => this.filterGridList(/*selectedOption, 'scheme_amc'*/)

    );
  };

  handleChangeCategory = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, category: selectedOption },
        selectedOptionCategory: selectedOption,
        // sub_category_list: sub_category_list 
      },
      () => this.filterGridList(/*selectedOption, 'category'*/)

    );

  };

  handleChangeSubCategory = (selectedOption) => {

    this.setState({ selectedOptionSubCategory: selectedOption });
    this.filterGridList(/*selectedOption, 'sub_category'*/);

  };

  handleChangeRisk = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, risk: selectedOption },
        selectedOptionRisk: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'risk'*/)

    );

  };

  handleChangeGrowth = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, growth: selectedOption },
        selectedOptionGrowth: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'growth'*/)

    );

    // this.filterGrowthoption(selectedOption);
    // this.setState({ selectedOptionGrowth: selectedOption });

  };

  handleChangeMinInvestment = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, minimum_investment: selectedOption },
        selectedOptionMinInvestment: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'minimum_investment'*/)

    );

  };

  handleChangeInvestmentMode = (selectedOption) => {

    this.setState(
      {
        filterOption: { ...this.state.filterOption, investment_method: selectedOption },
        selectedOptionInvestmentMode: selectedOption
      },
      () => this.filterGridList(/*selectedOption, 'investment_method'*/)

    );

  };

  routeChange = (item) => {

    if ((window.matchMedia && window.matchMedia("(max-width: 480px)").matches)) {
      this.props.history.push({
        pathname: "/chartsmobile",
        state: {
          param_data: {
            scheme_isin: item['scheme_isin'],
            scheme_code: item['scheme_code'],
            scheme_name: item['scheme_name']
          }
        }
      });
    }
    else {

      const win = window.open("/charts/" + item['scheme_isin'] + '/' + item['scheme_code'] + '/' + item['scheme_name'], "_blank");
      win.focus();

    }

  };

  filterGridList = (amclistFilter) => {

    let _filtered_list = this.props.result["list_scheme"] || []; //this.state.scheme_list || [];
    let _filterOption = amclistFilter ? amclistFilter : this.state.filterOption;

    if (amclistFilter) {

      this.setState({
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: _filterOption['scheme_amc'],
          category: _filterOption['category'],
          risk: _filterOption['risk'],
          growth: _filterOption['growth'],
          minimum_investment: _filterOption['minimum_investment'],
          investment_method: _filterOption['investment_method']
        },
        selectedOptionGrowth: _filterOption['growth'],
        selectedOptionMinInvestment: _filterOption['minimum_investment'],
        selectedOptionInvestmentMode: _filterOption['investment_method']
        /*
        selectedOptionAmc: _filterOption['scheme_amc'],
        selectedOptionCategory: _filterOption['category'],
        selectedOptionRisk: _filterOption['risk'],
        selectedOptionSubCategory: _filterOption['sub_category']
        */
      });

    }

    let _selected_list = [];

    Object.keys(_filterOption).forEach(key => {

      /*if(key === 'risk' ||  key === 'category') {
        if(_filterOption[key]['value']) {
          _selected_list = _filterOption[key]['value'] === 'all' ? [] : [_filterOption[key]] ;
        }
        else
          _selected_list = [];
      }
      else*/
      _selected_list = _filterOption[key];

      if (_selected_list.length > 0) {

        if (filter_conditional_parameters
          .filter(__el => { return __el === key; }).length > 0) {

          console.log(key);
          // console.log(_selected_list);

          switch (key) {

            case 'growth':

              let _filter = _filterOption[key];
              let _arr_values = [];
              let boolTrue = false;

              let __filtered_list = [];

              _filtered_list.forEach(el => {

                boolTrue = false;

                _filter.forEach(el_ => {

                  if (!boolTrue) {

                    _arr_values = el_.value.split('-');

                    boolTrue = (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                      parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                    if (!boolTrue)
                      boolTrue = (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) &&
                        parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1]));

                  }

                  if (boolTrue)
                    __filtered_list.push(el);

                });

                // return boolTrue;

                /*
                return _filter.filter(el_ =>  {
                  _arr_values = el_.value.split('-');
                  return (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1])) 
                    || 
                    (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]))
                    ||
                    (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1])) ;
                });*/ //.length > 0;

              });

              _filtered_list = __filtered_list;

              // _filtered_list = [...new Set(_filtered_list)];

              break;
            case 'minimum_investment':
              _filtered_list = _filtered_list.filter(el => {

                return _filterOption[key].filter(el_ => {
                  return parseFloat(el['scheme_minimum_investment_amount'] || 0) >= parseFloat(el_.value) &&
                    parseFloat(el['scheme_minimum_investment_amount'] || 0) <= parseFloat(el_.value) + 10;
                }).length > 0;

              });
              break;
            case 'investment_method':

              let _condition = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (_condition.length > 1)
                  return (el[_condition[0]] === 'Y' || el[_condition[1]] === 'Y');
                else
                  return el[_condition[0]] === 'N';

              });
              break;

            case 'category':

              let condition_category = _filterOption[key].map(el => {
                return el['value'];
              });

              _filtered_list = _filtered_list.filter(el => {

                if (condition_category.length > 1)
                  return (el[condition_category[0]]);


              });
              break;

            default:
              break;
          }

        }
        else {

          //  console.log('no switch =>' + key);

          let _loc = '';
          let _condition = _selected_list; /*.map(el => {
               return el.value.toLowerCase();
             });*/

          _filtered_list = _filtered_list.filter(el => {

            // return _condition.indexOf((el[key] || '').toLowerCase());
            _loc = (el[key] || '').toLowerCase();
            return _condition.filter(el_ => {
              return _loc === el_['value'].toLowerCase();
            }).length > 0;

          });

        }
      }

    });

    sessionStorage.setItem('amclistFilter', JSON.stringify(_filterOption));

    this.setState({
      scheme_list: _filtered_list,
      pages: Math.round/*floor*/(_filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterGrowthoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? (
          item.three_year_percent.toString() ||
          item.one_month_percent.toString() ||
          item.one_year_percent.toString()
        ).match(text)
        : true;
    });

    filtered_list = [...new Set(filtered_list)];

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  filterMininvestmentoption = (selectedOption) => {

    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? item.minimum_investment_amount.toString().match(text)
        : true;
    });

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round/*floor*/(filtered_list.length /
        this.props.result['per_page_count'] || this.state.perPage)
    });

  };

  loadSchemeList = () => {

    this.setState({ loading: true })
    this.props
      .listSchemes({
        file_index: this.state.pageIndex,
      })
      .then((response) => {

        const { perPage } = this.state;

        if (this.props.result["code"] === "000") {

          this.setState({
            scheme_list: this.props.result["list_scheme"] || [],
            pages:
              Math.round/*floor*/(
                (this.props.result["list_scheme"] || []).length /
                this.props.result['per_page_count'] || perPage
              ),
            loading: false,
            perPage: this.props.result['per_page_count'] || this.state.perPage
          });

          this.filterGridList(
            sessionStorage.getItem('amclistFilter') ? JSON.parse(sessionStorage.getItem('amclistFilter')) : null);

          if (sessionStorage.getItem('amclistFilter'))
            sessionStorage.removeItem('amclistFilter');

        } else {
          alert(this.props.result["messageText"]);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePageClick = (event) => {

    let page = event.selected;
    this.setState({ page });
    window.scrollTo(0, 0);

  };

  onNextPrevious = (count) => {
    // this.setState({pageIndex: this.state.pageIndex + count});
  };


  getBase64Image = (imgUrl) => {

    var img = new Image();

    // onload fires when the image is fully loadded, and has width and height

    img.onload = function () {

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png"),
        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      return (dataURL); // the base64 string

    };

    // set attributes and src 
    img.setAttribute('crossOrigin', 'anonymous'); //
    img.src = imgUrl;

  }

  render() {

    /*
    const SingleValue = (props) => {
      
      const { label } = props.getValue()[0];
    
      return (
        <components.SingleValue {...props}>
          <span>&#8377;</span> <span>{label.replace('100', `&#8377;`)}</span>
        </components.SingleValue>
      );
    };

    const Option = (props) => {
      const { label } = props.data;
    
      return (
        <components.Option {...props}>
          <span>&#8377;</span> <span >{label}</span>
        </components.Option>
      );
    };
    */

    const {
      filterOption,
      selectedOptionGrowth,
      selectedOptionMinInvestment,
      selectedOptionInvestmentMode
    } = this.state;
    const { page, perPage, pages, scheme_list } = this.state;

    let _list_scheme = scheme_list.length > perPage ?
      scheme_list.slice(page * perPage, (page + 1) * perPage) : scheme_list;
    // let _list_scheme = scheme_list.slice( (page - 1) * perPage, page * perPage);

    return (
      <>
        <Header1 />
        <Sidebar />
        <PageTitle />

        <div className="content-body">
          <div className="container">
            <div class="row">
              <div class="col-xl-12" style={{ marginTop: 40 }}>
                {/* style={{ overflowY: "scroll" }} */}
                <div style={{ marginTop: 70 }}>

                  {!this.state.loading &&
                    <div>
                      <Container class="card-header">
                        <Row fixed="top" >
                          <Col><div class="form-group">
                            <h5 class="text-gray-dark" style={{ fontSize: 14 }}>
                              AMC List
                            </h5>
                            <div>
                              <Select
                                isMulti
                                value={filterOption['scheme_amc']}
                                onChange={this.handleChangeAmc}
                                options={this.state.masterList["amc_list"]}
                              />
                            </div>
                          </div>
                          </Col>
                          <Col> <div class="form-group">
                            <h5 class="text-gray-dark" style={{ fontSize: 14 }}>
                              Category
                            </h5>

                            <Select
                              isMulti
                              value={filterOption['category']}
                              onChange={this.handleChangeCategory}
                              options={this.state.masterList["amc_category"]}
                            />
                          </div></Col>
                          <Col><div class="form-group">
                            <h5 class="text-gray-dark" style={{ fontSize: 14 }}>
                              Risk
                            </h5>

                            <Select
                              isMulti
                              value={filterOption['risk']}
                              onChange={this.handleChangeRisk}
                              options={this.state.masterList["amc_risk"]}

                            />
                          </div></Col>
                          <Col> <div class="form-group">
                            <h5 class="text-gray-dark" style={{ fontSize: 14 }}>
                              Growth
                            </h5>
                            <div>
                              <Select
                                isMulti
                                value={selectedOptionGrowth}
                                onChange={this.handleChangeGrowth}
                                options={this.state.masterList["growth_percentage"]}

                              />
                            </div>
                          </div>
                          </Col>
                          <Col>  <div class="form-group">
                            <h5 class="text-gray-dark" style={{ fontSize: 13 }}>
                              Minimum Amount
                            </h5>

                            <Select
                              
                              isMulti
                              value={filterOption['minimum_investment']}
                              onChange={this.handleChangeMinInvestment}
                              options={this.state.masterList["minimum_investment"]}
                            // components={{ SingleValue, Option }}

                            />
                          </div></Col>
                          <Col> <div class="form-group" style={{ marginBottom: 10 }}>
                            <h5 class="text-gray-dark" style={{ fontSize: 13 }}>
                              Mode Of Investment
                            </h5>

                            <Select
                              isMulti
                              value={filterOption['investment_method']}
                              onChange={this.handleChangeInvestmentMode}
                              options={this.state.masterList["investment_method"]}

                            />
                          </div></Col>

                        </Row>
                        <div class="form-group" style={{ marginBottom: 10 }}></div>
                      </Container>
                    </div>
                  }
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                            {_list_scheme && scheme_list.length >= perPage &&
                              <ReactPaginate
                                breakLabel="..."
                                previousLabel={"← Previous"}
                                nextLabel={"Next →"}
                                pageCount={pages}
                                onPageChange={this.handlePageClick}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                activeClassName={'active'}
                              />
                            }
                          </div>

                <div class="row" >
                  <div class="col">
                    {/* <div
                      class="card"

                    > */}

                      {/* <div class="card-body"> */}
                        <div class="transaction-widget">
                          
                          {_list_scheme.length < 1 &&
                            <Alert variant="dark text-center" style={{ marginBottom: 150 }}>
                              <Alert.Heading>{this.state.loading ? 'We are fetching the schemes' : 'No results found'}</Alert.Heading>

                            </Alert>}
                          {this.state.loading && <Spinner animation="border" variant="dark" style={{ position: "fixed", top: "50%", left: "50%" }} />}
                          {_list_scheme.map((item) => (
                            <ListGroup key={item["_id"]}>
                              <ListGroup.Item
                                style={{ marginBottom: 5 }}
                                action
                                variant="light"
                                className="wallet-address text-dark"
                                onClick={() => this.routeChange(item)}
                              >
                                <Row >
                                  <Col xs={9} md={7}>

                                    <p class="text-gray-dark">
                                      <img className="fundlogo" src={item['logo_url']} />  {
                                        item["scheme_name"]
                                      }
                                    </p>
                                    <div className="ms-2 me-auto">
                                      <div className="font-size-list-f1 text-secondary">
                                        {/* {item['category']} */}
                                        {/* { ' | '}  */}
                                        <span style={{ fontSize: 12, color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                                          {item['scheme_category']}
                                        </span>
                                        <span style={{
                                          fontSize: 12,
                                          color: 'InfoText',
                                          backgroundColor: item["risk"].toLowerCase().indexOf('low') > -1 ? 'lightgreen' :
                                            (item["risk"].toLowerCase().indexOf('high') > -1 ? '#d96f76' : '#81a6d6'), padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }} >
                                          {item["risk"]}
                                        </span>
                                        {/* {
                                          item['scheme_purchase_allowed'] === 'Y' && 
                                          <span style={{ fontSize: 12, color: 'InfoText', backgroundColor:'#e0e0e0', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                            Lumpsum
                                          </span>
                                        }
                                        {
                                          item['scheme_sip_allowed'] === 'Y' && 
                                          <span style={{ fontSize: 12, color: 'InfoText', backgroundColor:'#e0e0e0', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                            SIP
                                          </span>                                          
                                        } */}
                                        <span>
                                          <span
                                            style={{ fontSize: 12 }}
                                            class="text-dark"
                                          >

                                            {`  `}
                                            {item["one_month_percent"] ?
                                              <span style={{ fontSize: 13, color: (item["one_month_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                                {item["one_month_percent"]}%
                                              </span> : ''}
                                          </span>
                                          {`  `}<span
                                            style={{ fontSize: 12 }}
                                          >
                                            1M
                                          </span>
                                        </span>

                                      </div>
                                    </div>
                                    <div>
                                    </div>
                                  </Col>
                                  {/* {item["one_month_percent"] && (
                                    <Col xs={3} md={1}>
                                      {" "}
                                      <div class="text-right">
                                        <h5
                                          class="text-gray-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          1M
                                        </h5>

                                        <span
                                          style={{ fontSize: 12 }}
                                          class="text-dark"
                                        >

                                          {" "}
                                          {item["one_month_percent"] ?
                                            <p style={{ fontSize: 13, color: (item["one_month_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                              {item["one_month_percent"]}%
                                            </p> : ''}
                                        </span>
                                      </div>
                                    </Col>
                                  )} */}
                                  {item["one_year_percent"] && !isNaN(parseInt(item["one_year_percent"])) &&
                                    <Col xs={7} md={2}>
                                      <div style={{ width: 450 }} class="row">
                                        <div class="col">
                                          <div style={{ borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                            Lumpsum
                                          </div>
                                          <div class="row">
                                            {item["one_year_percent"] && !isNaN(parseInt(item["one_year_percent"])) && <div style={{width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                              <span style={{ fontSize: 13, color: (item["one_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                {item["one_year_percent"]}%</span>

                                            </div>
                                            }
                                            {item["three_year_percent"] && !isNaN(parseInt(item["three_year_percent"])) && <div style={{width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                              <span style={{ fontSize: 13, color: (item["three_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                {item["three_year_percent"]}%</span>

                                            </div>
                                            }
                                            {item["five_year_percent"] && !isNaN(parseInt(item["five_year_percent"])) && <div style={{width: '30px', textAlign: 'center' }} class="col">
                                              <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                              <span style={{ fontSize: 13, color: (item["five_year_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                {item["five_year_percent"]}%</span>

                                            </div>
                                            }
                                          </div>
                                        </div>
                                        <div class="col-1" style={{ borderLeft: '1px solid', marginTop: 24, height: 34, position: 'absolute', left: '79.5%' }}></div>
                                        <div class="col">
                                          <div style={{ fontFamily:'sans-serif', borderBottom: '1px solid', borderBottomColor: 'lightgray', textAlign: 'center' }}>
                                            {/* SIP (Eg. &#8377;1000 monthly) */}
                                            SIP (Absolute %)
                                          </div>
                                          <div class="row">
                                            {item['sip_returns_data'] && item['sip_returns_data']["1y_percent"] && !isNaN(parseInt(item['sip_returns_data']["1y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>1Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["1y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["1y_percent"]}%</span>
                                              </div>
                                            }
                                            {item['sip_returns_data'] && item['sip_returns_data']["3y_percent"] && !isNaN(parseInt(item['sip_returns_data']["3y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>3Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["3y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["3y_percent"]}%</span>
                                              </div>
                                            }
                                            {item['sip_returns_data'] && item['sip_returns_data']["5y_percent"] && !isNaN(parseInt(item['sip_returns_data']["5y_percent"])) &&
                                              <div style={{width: '30px', textAlign: 'center' }} class="col">
                                                <div style={{ fontSize: 12, color: '#7d7777' }}>5Y</div>
                                                <span style={{ fontSize: 13, color: (item['sip_returns_data']["5y_percent"].substring(0, 1) === '-' || '') ? '#f57362' : '#03b45e' }}>
                                                  {item['sip_returns_data']["5y_percent"]}%</span>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  }
                                  {/* {item["one_year_percent"] && (
                                    <Col xs={3} md={1}>
                                      {" "}
                                      <div class="text-right">
                                        <h5
                                          class="text-gray-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          1Y
                                        </h5>

                                        <span
                                          class="text-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          {" "}
                                          {item["one_year_percent"] ?
                                            <p style={{ fontSize: 13, color: (item["one_year_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                              {item["one_year_percent"]}%</p> : ''}
                                        </span>
                                      </div>
                                    </Col>
                                  )}
                                  {item["three_year_percent"] && (
                                    <Col xs={3} md={1}>
                                      {" "}
                                      <div class="text-right">
                                        <h5
                                          class="text-gray-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          3Y
                                        </h5>
                                        <span
                                          class="text-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          {" "}
                                          {item["three_year_percent"] ?
                                            <p style={{ fontSize: 13, color: (item["three_year_percent"].substring(0, 1) === '-' || '') ? 'red' : '#03b45e' }}>
                                              {item["three_year_percent"]}%</p> : ''}

                                        </span>
                                      </div>
                                    </Col>
                                  )}
                                  {item["five_year_percent"] && (
                                    <Col xs={3} md={1}>
                                      {" "}
                                      <div class="text-right">
                                        <h5
                                          class="text-gray-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          5Y
                                        </h5>

                                        <span
                                          class="text-dark"
                                          style={{ fontSize: 12 }}
                                        >
                                          {" "}
                                          {item["five_year_percent"] ?
                                            <p style={{ fontSize: 14, color: (item && item["five_year_percent"].substring(0, 1) === '-') ? 'red' : '#03b45e' }}>
                                              {item["five_year_percent"]}%</p> : ""}
                                        </span>
                                      </div>
                                    </Col>
                                  )} */}
                                </Row>
                              </ListGroup.Item>
                            </ListGroup>
                          ))}

                        </div>{" "}
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-xl-12">
              <div class="row"></div>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  result: state.master.result,
  loading: state.master.loading,
});
export default connect(mapStateToProps, { listSchemes })(Dashboard);

